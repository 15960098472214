import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAllClinicsService } from '../../services/clinicsService';
import { useToasts } from 'react-toast-notifications';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const columnHeaders = ['S. No.', 'Name', 'Created', 'Action'];

const userData = {
  userName: 'Admin',
};
const ClinicsListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [clinicsData, setClinicsData] = useState([]);

  const handleActionTrigger = (selectedItem) => {
    const videoId = selectedItem.id;
    history.push(`/clinics/edit/${videoId}`, { page_number: currentPage });
  };

  const fetchClinicsData = (params) => {
    setLoading(true);

    getAllClinicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setClinicsData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };
  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchClinicsData({ page: pageNumber });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
      };
      fetchClinicsData(params);
      props.location.state.page_current = null;
    } else {
      const params = { page: currentPage };
      fetchClinicsData(params);
    }
  }, []); // eslint-disable-line

  const handleAddAuthorCta = () => {
    history.push('/clinics/create', { page_number: currentPage });
  };

  return (
    <div className='clinics-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Clinics' />
        <div className='clinics-wrapper-content'>
          <div className='clinics-wrapper-header d-flex align-items-center justify-content-end'>
            <div className='create-cta' onClick={handleAddAuthorCta}>
              Add New Clinic
            </div>
          </div>
          {clinicsData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={clinicsData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchClinicsData({ page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchClinicsData({ page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title='No Clinics found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default ClinicsListing;
