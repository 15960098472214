export const parseCouponCodeTable = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination?.current_page;

  if (inputData) {
    inputData?.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 10 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.id = arrayItem?.id;
      renderingObject.coupon_code = arrayItem?.code;
      renderingObject.type = arrayItem?.discountType;
      renderingObject.amount = arrayItem?.discount;
      renderingObject.status = arrayItem?.status;

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
