import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  getAllUsersService,
  getCompanyUserStats,
  exportCustomersService,
} from '../../services/userService';
import { useToasts } from 'react-toast-notifications';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';
import Input from '../../components/Input';
import PrimaryButton from '../../components/PrimaryButton';
import Search from '../../components/Search';
// import { contentData } from './dataManager';
import moment from 'moment';
import './styles.scss';
import sessionStorage from 'redux-persist/es/storage/session';
const jsonexport = require('jsonexport/dist');

const UsersListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersData, setUsersData] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  // const [masterFlag, setMasterFlag] = useState(false);
  const [masterPassword, setmasterPassword] = useState('');
  // const [companyId, setCompanyId] = useState('');
  const [search, setSearch] = useState('');
  const [currCompany, setCurrCompany] = useState({});
  const [queryParams, setQueryParams] = useState({
    company_id: '',
    page: 1,
    sortBy: 'DATE',
    keyword: '',
  });

  const columnHeaders = [
    'S. No.',
    'Name',
    'Email',
    'Phone No',
    'Status',
    'Is a member ?',
    'Created',
    'Last Active',
    'Action',
  ];

  useEffect(() => {
    const companyId = props?.match?.params?.companyId;
    // setQueryParams({ ...queryParams, company_id: companyId });
    // fetchUsersData({ ...queryParams, company_id: companyId,page: props.location?.state?.page_current });
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      setQueryParams({ ...queryParams, company_id: companyId });
      fetchUsersData({
        ...queryParams,
        company_id: companyId,
        page: props.location?.state?.page_current,
      });
      props.location.state.page_current = null;
    } else {
      setQueryParams({ ...queryParams, company_id: companyId });
      fetchUsersData({ ...queryParams, company_id: companyId });
    }

    fetchCompanyUserStats();
    const currCompany = props.location?.state?.currCompany;
    setCurrCompany(currCompany);
  }, []);

  const fetchCompanyUserStats = () => {
    const params = { company_id: props?.match?.params?.companyId };
    getCompanyUserStats(params)
      .then((res) => {
        if (res.code === 200) {
          const responseData = res.data;
          setTotalUsers(responseData?.total);
          setActiveUsers(responseData?.active);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selectedItem) => {
    history.push(`/customers/edit/${selectedItem.id}`, {
      page_number: currentPage,
    });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'masterPassword') {
      setmasterPassword(fieldValue);
    } else if (fieldName === 'searchUser') {
      setSearch(fieldValue);
    }
  };

  const fetchUsersData = (params) => {
    setLoading(true);

    getAllUsersService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setUsersData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const exportUsers = (params) => {
    setLoading(true);

    exportCustomersService(params)
      .then((response) => {
        setLoading(false);
        // console.log(response);
        function download(filename, text) {
          var element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
          );
          element.setAttribute('download', filename);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
        if (response.code === 200) {
          let data = response.data.map(
            ({ fullName, name, membershipDetails, ...keepAttrs }) => keepAttrs
          );
          data = data.map((x) => {
            x.createdAt = moment(x.createdAt).format('DD/MM/YYYY hh:mm A');
            x.dob = x.dob ? moment(x.dob).format('DD/MM/YYYY') : 'NA';
            x.membershipStartDate = x.membershipStartDate
              ? moment(x.membershipStartDate).format('DD/MM/YYYY')
              : 'NA';
            x.membershipEndDate = x.membershipEndDate
              ? moment(x.membershipEndDate).format('DD/MM/YYYY')
              : 'NA';
            x.lastActive = x.lastActive
              ? moment(x.lastActive).format('DD/MM/YYYY hh:mm A')
              : 'NA';
            x.isPremiumMember = x.isPremiumMember === 1 ? 'YES' : 'NO';
            x.mobile = x.countryCode + '' + x.mobile;
            x.aocs = x?.aocs.map((x) => x.name).join(',');
            delete x.countryCode;
            return x;
          });
          jsonexport(data, function (err, csv) {
            if (err) return console.error(err);
            download(currCompany?.name + '.csv', csv);
            // console.log(csv);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    const q = JSON.parse(JSON.stringify(queryParams));
    q.page = pageNumber;
    fetchUsersData(q);
  };

  // useEffect(async()=>{
  //   const keyFlag= await sessionStorage.getItem('masterKey');
  //   if(keyFlag){
  //     setMasterFlag(true);
  //   }
  // },[])

  //On Enter Key Press
  const handlePressKey = (e) => {
    const value = e.target.value;
    if (e.key === 'Enter') {
      fetchUsersData({ ...queryParams, keyword: search });
    }
  };

  //SEARCH SUBMIT
  const searchSubmit = () => {
    fetchUsersData({ ...queryParams, keyword: search });
  };

  return (
    <div className='users-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader
          title={
            currCompany?.name
              ? `USERS: ${currCompany?.name}`
              : 'Default Company'
          }
        />
        {/* {masterFlag ? */}
        <div className='users-wrapper-content'>
          <div className='users-wrapper-header row align-items-center justify-content-between users-wraper-header'>
            <div className='d-flex flex-row align-items-center justify-content-between'>
              <p className='m-0 mr-5'>Total Users:&nbsp;&nbsp;{totalUsers}</p>
              <p className='m-0 mr-5'>Active Users:&nbsp;&nbsp;{activeUsers}</p>
              <p className='m-0 mr-5'>
                Inactive Users:&nbsp;&nbsp;{totalUsers - activeUsers}
              </p>
            </div>
            {/* <PrimaryButton
              // handleClick={exportUsers(queryParams)}
              name='Download CSV'
              value='Download CSV'
            /> */}
            <button
              className='create-cta'
              // className='sigup-btn sigup-btn-mobile'
              onClick={() => {
                exportUsers(queryParams);
              }}
            >
              Download CSV
            </button>

            <Search
              name={'searchUser'}
              handleOnchange={handleFieldUpdate}
              value={search}
              searchSubmit={searchSubmit}
              placeholder='Search for a User'
              handlePressKey={handlePressKey}
            />
            {/* </div> */}
            {/* <Link to={'/customers/create'} className='create-cta'>
                Add New User
              </Link> */}
            {/* </div> */}
          </div>
          {usersData && usersData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              // sendLink={true}
              columnContent={usersData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              // handleSendLinkAction={handleSendLinkActionTrigger}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchUsersData({ ...queryParams, page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchUsersData({ ...queryParams, page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title='No User found' />
          )}
        </div>
        {/* : 
        <div className='wrapper-content-body'>

            <Input
              name={'masterPassword'}
              type={'password'}
              value={masterPassword}
              placeholder={'Enter master Password'}
              handleChange={handleFieldUpdate}
            />

            <PrimaryButton name='Submit' className='button-submit' value='Submit' handleClick={handleCheckMasterPassword} />
          </div>
        } */}
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default UsersListing;
