import moment from "moment";

export const parseTableData = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 20 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.title = arrayItem.title;
      renderingObject.schedule = moment(arrayItem.dateTime)
        .utc()
        .format("DD/MM/YYYY HH:mm A");
      renderingObject.duration = arrayItem.duration;
      renderingObject.featured = arrayItem.isFeatured ? `YES` : `NO`;
      renderingObject.status = `${arrayItem.status}`.toUpperCase();
      renderingObject.Aoc = arrayItem.aoc?.name || "NA";
      renderingObject.company = arrayItem.company?.name;

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
