import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import { addNewAreaOfContentService } from '../../services/areaOfContentService';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const CreateAreaOfContent = (props) => {
  let history = useHistory();

  const { addToast } = useToasts();

  const userData = {
    userName: 'Admin',
  };

  const [areaOfContentName, setAreaOfContentName] = useState('');
  const [areaOfContentColor, setAreaOfContentColor] = useState('#6bbea6');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  //Paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  // validate
  const validateFormData = () => {
    let isFormValid = true;

    if (!file || !image) {
      addToast(
        'Area of Content photo is a required field. Please upload a photo.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    } else if (!areaOfContentName) {
      addToast('Area of Content name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleAddAreaOfContent = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      uploadImage()
        .then((response) => {
          addNewAreaOfContentService({
            name: areaOfContentName,
            image_id: response.data.id,
            color: areaOfContentColor,
          })
            .then((res) => {
              if (res.code === 200 || res.code === 201) {
                addToast('Area of Content added successfully.', {
                  appearance: 'success',
                  autoDismiss: true,
                });

                history.push('/area-of-content', { page_current: currentPage });
              }

              setAreaOfContentName('');
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              const errorData = err.response?.data?.errors;
              if (errorData && Object.keys(errorData).length > 0) {
                for (let key in errorData) {
                  for (let e of errorData[key]) {
                    addToast(e, {
                      appearance: 'error',
                      autoDismiss: true,
                    });
                    setLoading(false);
                  }
                }
              } else if (err.response?.data?.code === 404) {
                addToast("Data Can't be retrieved", {
                  appearance: 'error',
                  autoDismiss: true,
                });
                history.push('/area-of-content', { page_current: 1 });
              } else {
                addToast(err.response?.data?.message, {
                  appearance: 'error',
                  autoDismiss: true,
                });

                setLoading(false);
              }
            });
        })
        .catch((err) => {
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
          throw err;
        });
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);

          return res;
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast('some error occurred', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw err;
      });
  };

  const onImageChange = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setImage(URL.createObjectURL(file));

      setFile(file);
    }
  };

  return (
    <div className='areaofcontent-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Area Of Content' />

        <div className='areaofcontent-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  handleChange={(name, targetValue, event) => {
                    onImageChange(name, targetValue, event);
                  }}
                  type='file'
                  name='areaOfContentPhoto'
                  label='Upload Photo (1:1 Circular Corners)'
                  required='yes'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={areaOfContentName}
                  name='areaOfContentName'
                  label='Area Of Content Name'
                  placeholder='Enter Text'
                  required='yes'
                  onChange={(e) => {
                    setAreaOfContentName(e.target.value);
                  }}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='color'
                  className='color-input'
                  value={areaOfContentColor}
                  name='areaOfContentColor'
                  label='Area Of Content Color'
                  placeholder='Enter Text'
                  required='yes'
                  onChange={(e) => {
                    setAreaOfContentColor(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  handleClick={() => {
                    handleAddAreaOfContent();
                  }}
                  value='Save'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateAreaOfContent;
