import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { parseDropdownData } from "./parser";
import { useToasts } from "react-toast-notifications";
import { addNewUserService } from "../../services/userService";
import { getAllCompaniesService } from "../../services/companyService";
import { getAllAreaOfContentService } from "../../services/areaOfContentService";
// import { uploadImageService } from '../../services/uploadService';

import Input from "../../components/Input";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import TextArea from "../../components/TextArea";
import SubHeader from "../../components/SubHeader";
import PrimaryButton from "../../components/PrimaryButton";
import DropdownSelect from "../../components/DropdownSelect";

import "./styles.scss";

const statusOptions = [
  {
    name: "Active",
    value: "ACTIVE",
  },
  {
    name: "Inactive",
    value: "INACTIVE",
  },
];

const AOCOptions = [
  {
    name: "as",
    value: "AS",
  },
  {
    name: "qw",
    value: "QW",
  },
  {
    name: "qqq",
    value: "QQQ",
  },
  {
    name: "AAA",
    value: "TTTT",
  },
  {
    name: "zzz",
    value: "ZZZ",
  },
  {
    name: "ttt",
    value: "TTT",
  },
];

const CompanyOptions = [
  {
    name: "Microsoft",
    value: "MICROSOFT",
  },
  {
    name: "Google",
    value: "GOOGLE",
  },
  {
    name: "Instagram",
    value: "INSTAGRAM",
  },
  {
    name: "Squareboat",
    value: "SQUAREBOAT",
  },
  {
    name: "LinkedIn",
    value: "LINKDEIN",
  },
  {
    name: "Facebook",
    value: "FACEBOOK",
  },
];

const CreateUser = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [aocOptions, setAocOptions] = useState("");
  const [areaOfContentId, setAreaOfContentId] = useState("");
  const [companyOptions, setCompanyOptions] = useState("");
  const [companyId, setCompanyId] = useState("");

  const [memberFlag, setMemberFlag] = useState("");
  const [emailVerified, setEmailVerified] = useState("");

  const userData = {
    userName: "Admin",
  };
  const MemberFlagOptions = [
    {
      name: "True",
      value: true,
    },
    {
      name: "False",
      value: false,
    },
  ];
  const EmailVerifiedOption = [
    {
      name: "True",
      value: true,
    },
    {
      name: "False",
      value: false,
    },
  ];

  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };
  const handleEmailVerifiedSelection = (selectedValue) => {
    if (selectedValue) {
      setEmailVerified(selectedValue);
    }
  };

  const fetchCompaniesData = (params) => {
    setLoading(true);

    getAllCompaniesService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setCompanyOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast("An error occurred while trying to Get Companies.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchAocData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAocOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast("An error occurred while trying to Get AOC's.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleCompanySelection = (eventValue) => {
    if (eventValue) {
      setCompanyId(eventValue);
    }
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setUserStatus(eventValue);
    }
  };

  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAreaOfContentId(eventValue);
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === "firstName") {
      setFirstName(fieldValue);
    } else if (fieldName === "lastName") {
      setLastName(fieldValue);
    } else if (fieldName === "userEmail") {
      setUserEmail(fieldValue);
    } else if (fieldName === "userPhoneNumber") {
      setUserPhoneNumber(fieldValue);
    }
  };

  const validateFormData = () => {
    let isFormValid = true;

    const nameRegex = /^[A-Za-z]+$/;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    const numberRegex = /^[4-9][0-9]{9}$/g;

    if (!firstName) {
      addToast("First name is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!lastName) {
      addToast("Last Name is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });
      isFormValid = false;
    } else if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      addToast("User name must not contain numbers.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!userEmail) {
      addToast("User email is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });
      isFormValid = false;
    } else if (!emailRegex.test(userEmail)) {
      addToast("User email should be a valid email.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (userPhoneNumber && !numberRegex.test(userPhoneNumber)) {
      addToast("User Phone Number should be a valid number.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!areaOfContentId) {
      addToast("User Status is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });
      isFormValid = false;
    } else if (!userStatus) {
      addToast("User Status is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  const handleCreateNewUser = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      const dataPayload = {
        role_id: 4,
        first_name: firstName,
        last_name: lastName,
        email: userEmail,
        status: userStatus,
        phone_number: userPhoneNumber,
        company_id: companyId,
        area_of_content_id: areaOfContentId,
      };
      addNewUserService(dataPayload)
        .then((response) => {
          if (response) {
            if (response.code === 200) {
              addToast("Topic added successfully.", {
                appearance: "success",
                autoDismiss: true,
              });
              history.push("/customers");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          const errorData = err.response?.data?.errors;
          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              for (let e of errorData[key]) {
                addToast(e, {
                  appearance: "error",
                  autoDismiss: true,
                });
                setLoading(false);
              }
            }
          } else if (err.response?.data?.code === 404) {
            addToast("No Api.", {
              appearance: "error",
              autoDismiss: true,
            });
            history.push("/customers");
          } else {
            setLoading(false);
            addToast(err.response?.data?.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  };


  useEffect(() => {
    const companyParams = { page: 1, limit: 100, role: "users" };
    const aocParams = { page: 1, limit: 100 };

    fetchCompaniesData(companyParams);
    fetchAocData(aocParams);
  }, []); // eslint-disable-line

  return (
    <div className="createauthor-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <div className="flexible-container">
        <SubHeader title="Add User" />

        <div className="createauthor-wrapper-content">
          <div className="form-section">
            <>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <Input
                    name="firstName"
                    value={firstName}
                    placeholder="Enter Text"
                    handleChange={handleFieldUpdate}
                    label="First Name"
                    required="yes"
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <Input
                    name="lastName"
                    value={lastName}
                    placeholder="Enter Text"
                    handleChange={handleFieldUpdate}
                    label="Last Name"
                    required="yes"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <Input
                    type="email"
                    name="userEmail"
                    value={userEmail}
                    placeholder="Enter Text"
                    handleChange={handleFieldUpdate}
                    label="User Email"
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <DropdownSelect
                    value={memberFlag}
                    options={MemberFlagOptions}
                    label={"Is A Member ?"}
                    placeholder="Choose Member Status"
                    handleChange={handleMemberFlagSelection}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <Input
                    type="tel"
                    name="userPhoneNumber"
                    value={userPhoneNumber}
                    placeholder="Enter Number"
                    handleChange={handleFieldUpdate}
                    label="User Phone Number"
                    // required='yes'
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <DropdownSelect
                    value={emailVerified}
                    options={EmailVerifiedOption}
                    label={"Is Email Verified ?"}
                    placeholder="Choose Option"
                    handleChange={handleEmailVerifiedSelection}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <DropdownSelect
                    placeholder="Select AOC"
                    label={"Select a AOC"}
                    value={areaOfContentId}
                    options={AOCOptions}
                    // options={AocOptions}
                    handleChange={handleAocSelection}
                    required="yes"
                  />
                </div>

                <div className="col-12 col-sm-6 col-md-4">
                  <DropdownSelect
                    placeholder="Select a Company"
                    label={"Select a Company"}
                    value={companyId}
                    options={CompanyOptions}
                    // options={companyOptions}
                    handleChange={handleCompanySelection}
                    required="yes"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <DropdownSelect
                    value={userStatus}
                    options={statusOptions}
                    label={"User Status"}
                    placeholder="Choose a Status"
                    required="yes"
                    handleChange={handleStatusSelection}
                  />
                </div>
              </div>
            </>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <PrimaryButton
                  value="Save"
                  handleClick={() => {
                    handleCreateNewUser();
                  }}
                />
              </div>

              {/* <div className='col-12 col-sm-6 text-right'>
                <PrimaryButton 
                  value='Send Link' 
                  classname={userStatus === 'ACTIVE' ? '':'disabled'}
                  handleClick={() => {
                    handleSendLink();
                  }}
                  disableValue = {userStatus === 'ACTIVE' ? false:true}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateUser;
