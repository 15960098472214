import './styles.scss';

const VerticalTable = (props) => {
  const content = props.columnContent ? Object.values(props.columnContent) : [];
  return (
    <div className="vertical-table-wrapper">
      <table className="vertical-table">
        <tbody>
          {props.columnHeaders.map((item, index) => {
            return (
              <tr key={index}>
                <td className='title'>{item}</td>
                <td>{content?.[index]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VerticalTable;
