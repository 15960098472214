import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllPromoCodes = (params) => {
  return axiosInstance.get(BackendConstants.promoCode, {
    params: { ...params, limit: 10 },
  });
};

export const addNewCoupon = (data) => {
  return axiosInstance.post(BackendConstants.promoCode, { ...data });
};

export const getSingleCouponDetails = (id, params = {}) => {
  return axiosInstance.get(`${BackendConstants.promoCode}/${id}`, {
    params: params,
  });
};

export const updateCouponService = ({ id, data }) => {
  return axiosInstance.patch(`${BackendConstants.promoCode}/${id}`, {
    ...data,
  });
};
