import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { parseDropdownData } from './parser';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { useToasts } from 'react-toast-notifications';
import { addNewCompaniesService } from '../../services/companyService';
import { uploadImageService } from '../../services/uploadService';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { cloneDeep } from 'lodash';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';
import MultiDropdownSelect from '../../components/MultiDropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const userData = {
  userName: 'Admin',
};

const listingOptions = [
  {
    name: 'Active',
    value: 'ACTIVE',
  },
  {
    name: 'Inactive',
    value: 'INACTIVE',
  },
];

const dashboardVisibleStatus = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

const autoPremiumStatus = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

const CreateCompanies = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyStatus, setCompanyStatus] = useState('');
  const [subDomain, setSubDomain] = useState('');
  const [aocOptions, setAocOptions] = useState([]);
  const [premium, setPremium] = useState('');
  const [coupon, setCoupon] = useState('');
  const [dashboardVisible, setDashboardVisible] = useState('');
  const [selectedAocOptions, updateSelectedAocOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          // const parsedDropdownData = parseDropdownData(response?.data?.results);
          // setAocOptions(parsedDropdownData);
          setAocOptions(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
  }, []); // eslint-disable-line

  //Image
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));
    }
  };

  //Upload Image
  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'companyName') {
      setCompanyName(fieldValue);
    } else if (fieldName === 'companySubdomain') {
      setSubDomain(fieldValue);
    } else if (fieldName === 'couponCode') {
      setCoupon(fieldValue);
    }
  };

  // const handleAocSelection = (obj) => {
  //   console.log(obj);
  //   const index= selectedAocOptions.findIndex(el=>el===obj.value);
  //   console.log(index,'index',selectedAocOptions);
  //   const tempArr= cloneDeep(selectedAocOptions)
  //   if(index===-1){
  //     tempArr.push(obj.value)
  //   }else{
  //     tempArr.splice(index,1)
  //   }
  //   updateSelectedAocOptions(tempArr)
  // };

  const handleAocSelection = (obj) => {
    const index = selectedAocOptions.findIndex((el) => el === obj.id);

    const tempArr = cloneDeep(selectedAocOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedAocOptions(tempArr);
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setCompanyStatus(eventValue);
    }
  };

  const handleDashboardVisible = (eventValue) => {
    if (eventValue) {
      setDashboardVisible(eventValue);
    }
  };

  const handleAutoPremium = (eventValue) => {
    if (eventValue) {
      setPremium(eventValue);
    }
  };

  const validateFormSubmission = () => {
    let isFormValid = true;

    if (!file || !image) {
      addToast('Company photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!companyName) {
      addToast('Company Name is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!subDomain) {
      addToast('Sub Domain is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!coupon) {
      addToast('Unique code should not be empty.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    // else if (!companyStatus || companyStatus === '') {
    //   addToast('Company status is a required selection.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });

    //   isFormValid = false;
    // }
    // else if (!selectedAocOptions.length) {
    //   addToast('Area Of Content is a required selection.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });

    //   isFormValid = false;
    // }
    return isFormValid;
  };

  const handleSubmitCompanyData = (dataPayload) => {
    addNewCompaniesService(dataPayload)
      .then((responseData) => {
        if (responseData.code === 200 || responseData.code === 201) {
          addToast('Company created successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          history.push('/companies', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/companies');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

          setLoading(false);
        }
      });
  };

  const handleCreateNewCompany = () => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);

      uploadImage()
        .then((imageResponseData) => {
          if (imageResponseData) {
            if (imageResponseData.code === 200) {
              const dataPayload = {
                image_id: imageResponseData.data?.id,
                name: companyName,
                // status: companyStatus,
                sub_domain: subDomain,
                aocs: selectedAocOptions,
                is_dashboard_visible: dashboardVisible,
                auto_premium: premium,
                membership_code: coupon,
              };

              handleSubmitCompanyData(dataPayload);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='company-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Company' />

        <div className='company-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='companyPhoto'
                  handleChange={handleImageSelection}
                  required='yes'
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='companyName'
                  value={companyName}
                  label={'Company Name'}
                  placeholder='Title of the post'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='companySubdomain'
                  value={subDomain}
                  label={'Sub Domain'}
                  placeholder='Sub Domain of the company'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6'>
                <Input
                  name='couponCode'
                  value={coupon}
                  label={'Unique Code'}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={dashboardVisible}
                  options={dashboardVisibleStatus}
                  label={'Dashboard Visible'}
                  placeholder='Choose status'
                  handleChange={handleDashboardVisible}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={premium}
                  options={autoPremiumStatus}
                  label={'Are all users members by default?'}
                  placeholder='Choose status'
                  handleChange={handleAutoPremium}
                />
              </div>
            </div>

            {/* <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={companyStatus}
                  options={listingOptions}
                  label={'Company Listing Status'}
                  placeholder='Choose status'
                  handleChange={handleStatusSelection}
                  required='yes'
                /> 
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={dashboardVisible}
                  options={dashboardVisibleStatus}
                  label={'Dashboard Visible'}
                  placeholder='Choose status'
                  handleChange={handleDashboardVisible}
                /> 
              </div>
            </div> */}

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={aocOptions}
                  label={'Choose Area Of Contents'}
                  placeholder='Choose contents'
                  handleChange={handleAocSelection}
                  // required='yes'
                  selectedList={selectedAocOptions}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleCreateNewCompany}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateCompanies;
