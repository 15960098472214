import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllCompaniesService = async (params) => {
    return await axiosInstance.get(BackendConstants.company,{params:params});
};

export const addNewCompaniesService = (data) => {
    return axiosInstance.post(BackendConstants.company, data);
};
  
export const getSingleCompaniesDetailsService = (companyIdentifier,params) => {
    return axiosInstance.get(BackendConstants.companyById.replace(':id', companyIdentifier),{params:params});
};

export const updateCompaniesDetailsService = (companyIdentifier, data) => {
    return axiosInstance.patch(BackendConstants.companyById.replace(':id', companyIdentifier), data);
  };

export const updateCompanyStatusService = (companyIdentifier,data) => {
    return axiosInstance.patch(BackendConstants.changeCompanyStatusById.replace(':id', companyIdentifier),data);
};