import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import { addNewSpecializationService } from '../../services/specializationsService';
import { Editor } from 'react-draft-wysiwyg';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const CreateSpecialization = (props) => {
  let history = useHistory();

  const { addToast } = useToasts();

  const userData = {
    userName: 'Admin',
  };

  const [specializationName, setSpecializationName] = useState('');
  const [specializationColor, setSpecializationColor] = useState('#6bbea6');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const [description, setDescriptionEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const handleDescriptionEditorChanges = (updatedEditorState) => {
    setDescriptionEditorState(updatedEditorState);
  };

  const editorToolbarConfig = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji'],
  };

  const styleMap = {
    UNDERLINE: {
      textDecoration: 'underline !important',
      borderBottom: '1px solid black',
    },
    LINK: {
      color: '#007bff !important',
      // textDecoration: 'underline !important',
      // borderBottom: '1px solid black',
    },
  };

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  // validate
  const validateFormData = () => {
    let isFormValid = true;

    if (!file || !image) {
      addToast(
        'Specialization photo is a required field. Please upload a photo.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    } else if (!specializationName) {
      addToast('Specialization name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };
  const handleAddSpecialization = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      uploadImage()
        .then((response) => {
          addNewSpecializationService({
            name: specializationName,
            image_id: response.data.id,
            description: draftToHtml(
              convertToRaw(description.getCurrentContent())
            ),
            color: specializationColor,
          })
            .then((res) => {
              if (res.code === 200) {
                addToast('Specialization added successfully.', {
                  appearance: 'success',
                  autoDismiss: true,
                });

                history.push('/specializations', { page_current: currentPage });
              }

              setSpecializationName('');
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              const errorData = err.response?.data?.errors;
              if (errorData && Object.keys(errorData).length > 0) {
                for (let key in errorData) {
                  for (let e of errorData[key]) {
                    addToast(e, {
                      appearance: 'error',
                      autoDismiss: true,
                    });
                    // setLoading(false);
                  }
                }
              } else {
                addToast(err.response?.data?.message, {
                  appearance: 'error',
                  autoDismiss: true,
                });

                setLoading(false);
              }
            });
        })
        .catch((err) => {
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
          // throw err;
        });
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);

          return res;
        }
      })
      .catch((err) => {
        setLoading(false);
        // addToast('some error occurred', {
        //   appearance: 'error',
        //   autoDismiss: true,
        // });

        throw err;
      });
  };

  const onImageChange = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setImage(URL.createObjectURL(file));

      setFile(file);
    }
  };

  return (
    <div className='createspecialization-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Specialization' />

        <div className='createspecialization-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  handleChange={(name, targetValue, event) => {
                    onImageChange(name, targetValue, event);
                  }}
                  type='file'
                  name='specializationPhoto'
                  label='Upload Photo (1:1 Circular Corners)'
                  required='yes'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
              <div className='col-12 col-sm-6'>
                <div className='col-12 editor-class'>
                  <div className='col-12 editor-label'>
                    Description
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      *{' '}
                    </span>
                    (Shown on clinic page)
                  </div>
                  <Editor
                    editorState={description}
                    toolbar={editorToolbarConfig}
                    wrapperClassName='input-editor'
                    editorClassName='input-editor-field'
                    onEditorStateChange={handleDescriptionEditorChanges}
                    customStyleMap={styleMap}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={specializationName}
                  name='specializationName'
                  label='Specialization Name'
                  placeholder='Enter Text'
                  required='yes'
                  onChange={(e) => {
                    setSpecializationName(e.target.value);
                  }}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='color'
                  className='color-input'
                  value={specializationColor}
                  name='specializationColor'
                  label='Specialization Color'
                  placeholder='Enter Text'
                  required='yes'
                  onChange={(e) => {
                    setSpecializationColor(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  handleClick={() => {
                    handleAddSpecialization();
                  }}
                  value='Save'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateSpecialization;
