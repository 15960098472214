import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import { cloneDeep } from 'lodash';
import TextArea from '../../components/TextArea';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import MultiDropdownSelect from '../../components/MultiDropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';
import { getAllClinicsService } from '../../services/clinicsService';
import { addNewSymptomsService } from '../../services/symptomsService';

const userData = {
  userName: 'Admin',
};

const CreateSymptom = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [symptomName, setSymptomName] = useState('');
  const [description, setDescription] = useState('');
  const [symptomStatus, setSymptomStatus] = useState('');
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedClinicOptions, updateSelectedClinicOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  const fetchClinicData = (params) => {
    setLoading(true);

    getAllClinicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          // const parsedDropdownData = parseDropdownData(response?.data?.results);
          // setClinicOptions(parsedDropdownData);
          setClinicOptions(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const clinicParams = { page: 1, limit: 100 };

    fetchClinicData(clinicParams);
  }, []); // eslint-disable-line

  //Image
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));
    }
  };

  //Upload Image
  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file, 256, 256, 80)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'symptomName') {
      setSymptomName(fieldValue);
    }
    if (fieldName === 'description') {
      setDescription(fieldValue);
    }
  };

  const handleClinicSelection = (obj) => {
    const index = selectedClinicOptions.findIndex((el) => el === obj.id);

    const tempArr = cloneDeep(selectedClinicOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedClinicOptions(tempArr);
  };

  const validateFormSubmission = () => {
    let isFormValid = true;

    if (!file || !image) {
      addToast('Symptom photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!symptomName) {
      addToast('Symptom Name is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleSubmitSymptomData = (dataPayload) => {
    addNewSymptomsService(dataPayload)
      .then((responseData) => {
        if (responseData.code === 200 || responseData.code === 201) {
          addToast('Symptom created successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          history.push('/symptoms', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/symptoms');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

          setLoading(false);
        }
      });
  };

  const handleCreateNewSymptom = () => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);

      uploadImage()
        .then((imageResponseData) => {
          if (imageResponseData) {
            if (imageResponseData.code === 200) {
              const dataPayload = {
                image_id: imageResponseData.data?.id,
                name: symptomName,
                description: description,
                clinics: selectedClinicOptions,
              };
              // console.log(dataPayload);
              handleSubmitSymptomData(dataPayload);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='symptom-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Symptom' />

        <div className='symptom-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='symptomPhoto'
                  handleChange={handleImageSelection}
                  required='yes'
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='symptomName'
                  value={symptomName}
                  label={'Symptom Name'}
                  placeholder='Symptom Name'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='description'
                  value={description}
                  placeholder='Description'
                  handleChange={handleFieldUpdate}
                  label='Description'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={clinicOptions}
                  label={'Choose Clinics'}
                  placeholder='Choose contents'
                  handleChange={handleClinicSelection}
                  // required='yes'
                  selectedList={selectedClinicOptions}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleCreateNewSymptom}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateSymptom;
