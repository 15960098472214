import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { useToasts } from 'react-toast-notifications';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const AreaOfContentListing = (props) => {
  const history = useHistory();
    const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [contentData, setContentData] = useState([]);

  const columnHeaders = [
    'S. No.',
    'Name',
    'Created',
    'Action',
  ];

  const userData = {
    userName: 'Admin',
  };

  const fetchContentData = (params) => {
    
        setLoading(true);
    
        getAllAreaOfContentService(params)
          .then((response) => {
            setLoading(false);
    
            if (response.code === 200) {
              const parsedTableData = parseTableData(response?.data?.results, response.data?.meta?.pagination);
    
              setContentData(parsedTableData);
    
              setTotalPage(response?.data?.meta?.pagination?.total_pages);
            }
          })
          .catch((err) => {
            setLoading(false);
            const errorData = err.response?.data?.errors
                      if (errorData && Object.keys(errorData).length > 0) {
                        for (let key in errorData) {
                        for (let e of errorData[key]) {
                          addToast(e, {
                              appearance: 'error',
                              autoDismiss: true,
                            });
                            setLoading(false);
                        }
                      }
            }else {
              setLoading(false);
            addToast(err.response?.data?.message, {
              appearance: 'error',
              autoDismiss: true,
            });
            }
          });
      };

    const handlePageNumber=(pageNumber)=>{
        setCurrentPage(pageNumber);
        fetchContentData({ page: pageNumber});
    }

  const handleActionTrigger = (selectedItem) => {
    const areaOfContentId = selectedItem.id;
    history.push(`/area-of-content/edit/${areaOfContentId}`,{page_number : currentPage});
  };

  const handleAddAocCta = () =>{
    history.push('/area-of-content/create',{page_number : currentPage})
  }

    useEffect(() => {
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current, role: 'author', sortBy: 'DATE' };
      fetchContentData(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage, role: 'author', sortBy: 'DATE' };
      fetchContentData(params);
    }
    }, []);// eslint-disable-line
    
  return (
    <div className='physicians-wrapper'>
      <Header userData={userData} />

      <Sidebar />
      
      <div className='flexible-container'>
        <SubHeader title='Area Of Content' />

        <div className='authors-wrapper-content'>
          <div className='authors-wrapper-header d-flex align-items-center justify-content-end'>
            <div  className='create-cta' onClick={handleAddAocCta}>
              Add New Area Of Content
            </div>
          </div>
          {contentData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={contentData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchContentData({page: currentPage + 1});
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchContentData({page: currentPage - 1});
              }}
            />
          ) : (
            <EmptyState title='No Area Of Content found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default AreaOfContentListing;

