import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getAllQuestionsService } from '../../services/questionService';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const columnHeaders = ['S. No.', 'Question','Author Name','Aoc', 'Status','Date Posted', 'Action'];

  // const contentData = [
  //   [
  //     '1',
  //     'Question1 ',
  //     'Calcium,Thyroid,Fertility',
  //     '2',
  //     '8th December, 2020 4:30 PM',
  //   ],
  //   [
  //     '2',
  //     'Hell Work Why ???',
  //     'Calcium,Thyroid,Fertility',
  //     '3',
  //     '8th December, 2020 4:30 PM',
  //   ],
  // ];
  const userData = {
    userName: 'Admin',
  };
  
const QuestionListing = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsData, setQuestionData] = useState([]);

  const handleActionTrigger = (selectedItem) => {
    const selectedItemId = selectedItem.id;
    history.push(`/questions/edit/${selectedItemId}`,{page_number : currentPage});
  };

  const fetchQuestionsData = (params) => {
    setLoading(true);

    getAllQuestionsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(response?.data?.results, response.data?.meta?.pagination);
          setQuestionData(parsedTableData);
          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);

        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handlePageNumber =(pageNumber) =>{
    setCurrentPage(pageNumber);
    fetchQuestionsData({ page: pageNumber });
  }

  useEffect(() => {
    
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current, include:'aoc'};
      fetchQuestionsData(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage, include:'aoc'};
      fetchQuestionsData(params);
    }
  }, []); // eslint-disable-line

  const handleAddQuestionCta = () =>{
    history.push('/questions/create',{page_number : currentPage})
  }

  return (
    <div className='questions-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Question Of The Month' />

        <div className='questions-wrapper-content'>
          <div className='questions-wrapper-header d-flex align-items-center justify-content-end'>
            <div className='create-cta' onClick={handleAddQuestionCta}>
              Add New Question
            </div>
          </div>

          {questionsData.length ? (
          <Table
            pagination={true}
            actionAvailable={true}
            columnContent={questionsData}
            paginationPage={currentPage}
            columnHeaders={columnHeaders}
            paginationTotalPage={totalPage}
            handleAction={handleActionTrigger}
            handlePageNumber={handlePageNumber}
            nextPage={() => {
              setCurrentPage(currentPage + 1);
              fetchQuestionsData({ page: currentPage + 1 });
            }}
            prevPage={() => {
              setCurrentPage(currentPage - 1);
              fetchQuestionsData({ page: currentPage - 1 });
            }}
          />
          ) : (
            <EmptyState title='No Questions found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default QuestionListing;
