import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import { addNewTopicService } from '../../services/topicsService';
import { useToasts } from 'react-toast-notifications';

import Spinner from '../../components/Spinner';
import './styles.scss';

const CreateTopic = (props) => {
  let history = useHistory();
  const { addToast } = useToasts();
  const userData = {
    userName: 'Admin',
  };
  const [topicName, setTopicName] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [topicColor, setTopicColor] = useState('');

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);
  //validate

  const validateFormData = () => {
    let isFormValid = true;

    if (!topicName) {
      addToast('Topic name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleAddTopic = () => {
    const isFormValid = validateFormData();
    if (isFormValid) {
      setLoading(true);
      addNewTopicService({ name: topicName, color: topicColor })
        .then((res) => {
          if (res.code === 200) {
            addToast('Topic added successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });
            history.push('/topics', { page_current: currentPage });
          }

          setTopicName('');
          setLoading(false);
        })
        .catch((err) => {
          const errorData = err.response?.data?.errors;
          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              for (let e of errorData[key]) {
                addToast(e, {
                  appearance: 'error',
                  autoDismiss: true,
                });
                setLoading(false);
              }
            }
          }
          setLoading(false);
          // addToast(err.response?.data?.message, {
          //   appearance: 'error',
          //   autoDismiss: true,
          // });
          // setLoading(false);
        });
    }
  };

  return (
    <div className='createtopic-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Topic' />
        <div className='createtopic-wrapper-content'>
          {loading && <Spinner />}
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={topicName}
                  label='Topic Name'
                  name='topicName'
                  onChange={(e) => {
                    setTopicName(e.target.value);
                  }}
                  placeholder='Enter Text'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='color'
                  className='color-input'
                  value={topicColor}
                  name='topicColor'
                  label='Topic Color'
                  required='yes'
                  onChange={(e) => {
                    setTopicColor(e.target.value);
                  }}
                  placeholder='Enter Text'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  handleClick={() => {
                    handleAddTopic();
                  }}
                  value='Save'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTopic;
