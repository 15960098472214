import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllAuthorsService = (params) => {
  return axiosInstance.get(BackendConstants.authors, { params: params });
};

export const getAllDoctorsForAuthorService = (params) => {
  return axiosInstance.get(BackendConstants.authors, { params: params });
};

export const mapDoctorToAuthorService = (doctorIdentifier) => {
  return axiosInstance.patch(BackendConstants.authorByDoctor.replace(':id', doctorIdentifier));
};

export const addNewAuthorService = (data) => {
  return axiosInstance.post(BackendConstants.authors, data);
};

export const getSingleAuthorDetailsService = (authorIdentifier) => {
  return axiosInstance.get(BackendConstants.authorById.replace(':id', authorIdentifier));
};

export const updateAuthorDetailsService = (authorIdentifier, data) => {
  return axiosInstance.patch(BackendConstants.authorById.replace(':id', authorIdentifier), data);
};
