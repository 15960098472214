import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { deleteVideoService, getSingleVideoDetailsService, updateVideoDetailsService } from '../../services/videosService';
import { parseDropdownData } from './parser';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';
import SecondaryButton from '../../components/SecondaryButton';

import './styles.scss';

const EditVideo = (props) => {
  const history = useHistory();

  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [speaker, setSpeaker] = useState('');
  const [aocId, setAocId] = useState('');
  const [aocOptions, setAOCOptions] = useState('');
  const [videoIdentifier, setVideoIdentifier] = useState('');
  const [currentPage,setCurrentPage] = useState(null);

  const userData = {
    userName: 'Admin',
  };


  //paging
  useEffect(()=>{
    setCurrentPage(props.location.state.page_number);
  },[])

  useEffect(()=>{
    const videoId = props?.match?.params?.videoIdentifier;
    setVideoIdentifier(videoId);
    
  },[]);

   // Acting ComponentDidUpdate
   useEffect(() => {
    if (videoIdentifier && videoIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line
      const params={include:'aoc'};

      fetchPrefillData(params);
    }
  }, [videoIdentifier]);

  //fetch videos data
  const fetchPrefillData = (params) =>{
    setLoading(true);

    getSingleVideoDetailsService(videoIdentifier,params)
      .then((response)=>{
          setLoading(false);

          if(response.code ===200){
              const { data } = response;
              setVideoTitle(data?.title);
              setVideoUrl(data?.url);
              setSpeaker(data?.leaders);
              setAocId(data?.aoc?.id);
          }
      })
      .catch((err)=>{
          addToast(err.response?.data?.message, {
              appearance: 'error',
              autoDismiss: true,
            });
    
          setLoading(false);
      })
}

  //AOC Data
  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAOCOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1,limit:100 };

    fetchAOCData(aocParams);
  }, []);// eslint-disable-line


  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAocId(eventValue);
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {

    if (fieldName === 'videoTitle') {
      setVideoTitle(fieldValue);
    } else if (fieldName === 'videoSpeaker') {
      setSpeaker(fieldValue);
    } else if (fieldName === 'videoUrl') {
      setVideoUrl(fieldValue);
    } 
  };

  const validateFormData = () => {
    let isFormValid = true;

    if (!videoTitle) {
      addToast('Title is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!videoUrl) {
      addToast('URL is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!aocId || aocId === '') {
        addToast('Area Of Content is a required selection.', {
          appearance: 'error',
          autoDismiss: true,
        });
  
        isFormValid = false;
      }

    return isFormValid;
  };

  const handleEditTopic = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      const url = videoUrl.split('=');
      const urlVal =url.length === 1 ? url[0] : `https://www.youtube.com/embed/${url[1]}`;
      
      const dataPayload = {
        title: videoTitle,
        url: urlVal,
        leaders: speaker,
        aoc_id: aocId,
      };

      submitVideoDetails(dataPayload)

    } else {
      setLoading(false);
    }
  };

  const submitVideoDetails =(dataPayload) =>{
    updateVideoDetailsService(videoIdentifier,dataPayload)
        .then((responseData) => {
          setLoading(false);

          if (responseData) {
            if (responseData.code === 200) {
              addToast('Video has been created successfully.', {
                appearance: 'success',
                autoDismiss: true,
              });

              history.push('/videos',{page_current: currentPage});
            }
          }
        })
        .catch((err) => {
          setLoading(false);

          const errorData = err.response?.data?.errors;

          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              for (let e of errorData[key]) {
                addToast(e, {
                  appearance: 'error',
                  autoDismiss: true,
                });

                setLoading(false);
              }
            }
          } else if(err.response?.data?.code === 404){
                  
            addToast('No Api.', {
              appearance: 'error',
              autoDismiss: true,
            });
            history.push('/videos');
          } else {
            setLoading(false);

            addToast(err.response?.data?.message, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        });
    }

    //Delete Questions
    const handleDeleteVideo = () => {
        if (videoIdentifier) {
          deleteVideoService(videoIdentifier)
            .then((res) => {
              setLoading(false);
    
              addToast('Video has been deleted successfully.', {
                appearance: 'success',
                autoDismiss: true,
              });
    
              // TODO: Handle Response Using Response Code
              history.push('/videos',{page_current: currentPage});
            })
            .catch((err) => {
              setLoading(false);
    
              addToast(err.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
              });
            });
        }
      };

  return (
    <div className='video-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit Videos' />

        <div className='video-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='videoTitle'
                  value={videoTitle}
                  placeholder='Title'
                  handleChange={handleFieldUpdate}
                  label='Title'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='videoUrl'
                  value={videoUrl}
                  placeholder='Video URL / Link'
                  handleChange={handleFieldUpdate}
                  label='Video URL'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
            <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                value={aocId}
                options={aocOptions}
                label={'AOC'}
                placeholder='Choose AOC'
                handleChange={handleAocSelection}
                required='yes'
                /> 
            </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='videoSpeaker'
                  value={speaker}
                  placeholder='Speakers'
                  handleChange={handleFieldUpdate}
                  label='Speaker'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton value='Save' handleClick={handleEditTopic} />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value='Delete Video'
                  className='danger-button'
                  handleClick={handleDeleteVideo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
  
};

export default EditVideo;
