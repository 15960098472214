import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllBlogsService = (params) => {
  return axiosInstance.get(BackendConstants.blogs, { params: params });
};

export const addNewBlogService = (data) => {
  return axiosInstance.post(BackendConstants.blogs, data);
};

export const getBlogByIdService = (blogIdentifier, params) => {
  return axiosInstance.get(BackendConstants.blogById.replace(':id', blogIdentifier), { params: params });
};

export const updateBlogService = (blogIdentifier, data) => {
  return axiosInstance.patch(BackendConstants.blogById.replace(':id', blogIdentifier), data);
};

export const imageInEditor = (params) => {
  return axiosInstance.get(BackendConstants.editorImage(params),{});
};