import React, { memo, useEffect, useState } from 'react';
import './styles.scss';
import {
    updateAppointmentsStatus,
} from '../../services/appointmentServices';
import { Modal } from 'react-bootstrap';

const AppointmentStatusDropdownSelect = ({
    consultationStatus,
    label,
    appointmentIdentifier,
    className,
    required,
}) => {

    const [selectedOption, setSelectedOption] = useState(consultationStatus);
    const [showConfirm, setShowConfirm] = useState(false);
    const options = [
        {
            name: 'Select value',
            value: 0,
        },
        {
            name: 'COMPLETED',
            value: 1,
        },
        {
            name: 'NO SHOW',
            value: 2,
        },
    ];

    const handleConsultationStatus = () => {
        let payload;

        if (selectedOption == 1) {
            payload = {
                id: appointmentIdentifier,
                status: 1,
            };
        } else {
            //reverse the status
            payload = {
                id: appointmentIdentifier,
                status: 2,
            };
        }
        setSelectedOption(selectedOption)
        updateAppointmentsStatus(payload)
    };


    useEffect(() => {
        setSelectedOption(consultationStatus)
    }, [consultationStatus])

    return (
        <div className='input-wrapper'>
            {label && (
                <label className='input-label'>
                    {label}
                    {required === 'yes' ? <span className='text-danger'>*</span> : ''}
                </label>
            )}
            <Modal
                show={showConfirm}
                onHide={() => showConfirm(false)}
                size='lg'
                className='appointment-status-confirm-modal'
            >
                <Modal.Body>
                    <div className='appointment-status-confirm-modal-body'>
                        <p>
                            Are you sure you would like to mark the appointment as {' '}'
                            {selectedOption == 1 ? 'COMPLETED' : 'NO SHOW'}'?
                        </p>
                        <button
                            onClick={() => {
                                setShowConfirm(false);
                                setSelectedOption(options[0].value)

                            }}
                        >
                            No
                        </button>
                        <button onClick={() => {
                            setShowConfirm(false)
                            handleConsultationStatus()
                        }}>Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <select
                value={selectedOption}
                onChange={e => {
                    setShowConfirm(true)
                    setSelectedOption(e.target.value)
                }}
                disabled={selectedOption == 1 || selectedOption == 2}
                className={className ? `input-field  ${className}` : `input-field`}
            >
                {options.map(o => (
                    <option selected={+selectedOption === +o.value} key={o.value} value={o.value} className='input-dropdown-option'>{o.name}</option>
                ))}
            </select>
        </div>
    );


};

export default AppointmentStatusDropdownSelect
