import './styles.scss';

const SecondaryButton = props => {
  return (
    <button
      type={props.type}
      name={props.name}
      onClick={() => props.handleClick()}
      disabled={props.disabled}
      className={
        props.className
          ? `button-secondary ${props.className}`
          : `button-secondary`
      }
    >
      {props.value}
    </button>
  );
};

export default SecondaryButton;
