import { parseTableData } from "./parser";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAllCompaniesService } from "../../services/companyService";

import Table from "../../components/Table";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import SubHeader from "../../components/SubHeader";
import { useToasts } from "react-toast-notifications";
import EmptyState from "../../components/EmptyState";
import { useSelector } from "react-redux";

import "./styles.scss";

const CompaniesListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [companiesData, setCompaniesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);

  const auth = useSelector((state) => state.auth);

  const columnHeaders = [
    "S. No.",
    "Name",
    "SubDomain",
    "Area Of Content",
    "Status",
    "Created",
    "Action",
  ];

  const [userData, setUserData] = useState({
    userName: "Admin",
    value: 1,
  });

  useEffect(() => {
    if (auth?.authData?.role !== "admin") {
      let user = {
        userName: "Super Admin",
        value: 0,
      };
      setUserData({ ...user });
    }
  }, [auth]);

  const handleActionTrigger = (selectedCompany) => {
    const companyId = selectedCompany.id;
    history.push(`/companies/edit/${companyId}`,{page_number : currentPage});
  };

  const handleViewActionTrigger = (selectedCompany) => {
    const companyId= selectedCompany.id
    history.push(`/customers/${companyId}`,{currCompany:selectedCompany,page_number : currentPage});
  };

  const handleSendLinkAction = (selectedCompany) =>{
    let text =
      "https://" +
      selectedCompany?.subdomain +
      "." +
      `${process.env.REACT_APP_SIGNUP_URL}` +
      "?" +
      "membershipCode=" +
      selectedCompany?.code;
      var dummy = document.createElement("textarea");

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      addToast(`SignUp Link Copied!`, {
        appearance: "success",
        autoDismiss: true,
      });
  }

  const getAllCompanies = (params) => {
    setLoading(true);

    getAllCompaniesService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setCompaniesData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllCompanies({ page: pageNumber, getDefault:true });
  };
  useEffect(() => {
    
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current, getDefault:true };
      getAllCompanies(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage, getDefault:true };
      getAllCompanies(params);
    }
  }, []); // eslint-disable-line

  const handleAddCompanyCta = () =>{
    history.push("/companies/create",{page_number : currentPage})
  }

  return (
    <div className="companies-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <div className="flexible-container">
        <SubHeader title="Companies" />

        <div className="companies-wrapper-content">
          <div className="companies-wrapper-header d-flex align-items-center justify-content-end">
            <div className="create-cta" onClick={handleAddCompanyCta}>
              Create New Company
            </div>
          </div>

          {companiesData.length ? (
            <Table
              pagination={true}
              // columnContent={blogs}
              columnContent={companiesData}
              actionAvailable={true}
              sendLink={true}
              usersAvailable={userData.value === 0 ? true : false}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handleViewAction={handleViewActionTrigger}
              handleSendLinkAction = {handleSendLinkAction}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
                getAllCompanies({ page: currentPage + 1, getDefault:true });
              }}
              prevPage={() => {
                setCurrentPage((oldCurrentPage) => oldCurrentPage - 1);
                getAllCompanies({ page: currentPage - 1, getDefault:true });
              }}
            />
          ) : (
            <EmptyState title="No Companies found" />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CompaniesListing;
