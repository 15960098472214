import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getAllSupportGroupsService } from '../../services/supportGroupsService';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const SupportGroupsListing = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [supportGroupsData, setSupportGroupsData] = useState([]);

  const columnHeaders = ['S. No.', 'Title', 'Schedule', 'Duration', 'Featured', 'Status','AOC', 'Action'];

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selectedItem) => {
    history.push(`/support-groups/edit/${selectedItem.id}`,{page_number : currentPage});
  };

  const fetchSupportGroups = (params) => {
    setLoading(true);

    getAllSupportGroupsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(response?.data?.results,  response.data?.meta?.pagination);

          setSupportGroupsData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);

        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handlePageNumber = (pageNumber) =>{
    setCurrentPage(pageNumber);
    fetchSupportGroups({ role: 'author', page: pageNumber });
  }

  useEffect(() => {

    
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current};
      fetchSupportGroups(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage};
      fetchSupportGroups(params);
    }
  }, []); // eslint-disable-line

  const handleAddSupportgroupCta = () => {
    history.push('/support-groups/create',{page_number : currentPage})
  }

  return (
    <div className='authors-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Support Groups' />

        <div className='authors-wrapper-content'>
          <div className='authors-wrapper-header d-flex align-items-center justify-content-end'>
            <div className='create-cta' onClick={handleAddSupportgroupCta}>
              Add New Support Group
            </div>
          </div>

          {supportGroupsData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={supportGroupsData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchSupportGroups({ role: 'author', page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchSupportGroups({ role: 'author', page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title='No support groups found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default SupportGroupsListing;
