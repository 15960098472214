import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useToasts } from 'react-toast-notifications';

import EmptyState from '../../../components/EmptyState';

import Spinner from '../../../components/Spinner';
import Table from '../../../components/Table';
import { parsePcosTable } from '../parser';
import { getAllCheckoutLogs } from '../../../services/paymentsService';
import Search from '../../../components/Search';

import '../style.scss';
import VerticalTable from '../../../components/VerticalTable';

const columnHeaders = [
  'S. No.',
  "Name",
  'Phone Number',
  'Email',
  'Booking Date',
  'Details'
];

const columnHeadersFullPcos = [
  "Name",
  'Phone Number',
  'Email',
  'Booking Date',
  'Pincode',
  'Coupon Code',
  'Original Amount',
  'Paid Amount',
  'Server Payment ID',
  'Razorpay Payment ID'
];

// const dummyData = [
//   {
//     sno: 1,
//     name: 'Saksham',
//     email: 'saksham@gmail.com',
//     phone: '9998887776',
//     coupon_code: 'DEAL10',
//     type: 'CONSULTATION',
//     amount: '1000',
//     date: '13/07/21',
//   },
// ];

const PCOS = (props) => {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [pcosData, setPcosData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [fullPcos, setFullPcos] = useState([]);

  const handleClose = () => setFullPcos(null);

  const fetchPcosData = async (params) => {
    setLoading(true);

    getAllCheckoutLogs({
      ...params,
      type: 'PCOS'
    })
      .then((res) => {
        if (res?.code === 200) {
          const {
            data: { results = [], meta: { pagination = {} } = {} } = {},
          } = res || {};

          const { total_pages } = pagination;
          // console.log(results);
          const parsedTableData = parsePcosTable(
            results,
            res.data?.meta?.pagination,
            setFullPcos
          );

          setPcosData(parsedTableData);

          setTotalPage(total_pages);
        }

        setLoading(false);
      })
      .catch((err) => {
        const { response: { data: { errors = {}, message } = {} } = {} } = err;

        if (errors && Object.keys(errors).length > 0) {
          for (let key in errors) {
            for (let e of errors[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          }
        } else {
          addToast(message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        setLoading(false);
      });
  };

  console.log(fullPcos)

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log(pageNumber);
    fetchPcosData({ page: pageNumber });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'search') {
      setSearch(fieldValue);
    }
  };

  const handlePressKey = (e) => {
    // const value = e.target.value;
    if (e.key === 'Enter') {
      fetchPcosData({ keyword: search });
    }
  };

  //SEARCH SUBMIT
  const searchSubmit = () => {
    fetchPcosData({ keyword: search });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
      };
      fetchPcosData(params);
      props.location.state.page_current = null;
    } else {
      const params = { page: currentPage };
      fetchPcosData(params);
    }
  }, []);

  return (
    <>
      <Modal show={Boolean(fullPcos?.name)} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerticalTable
            columnContent={fullPcos}
            columnHeaders={columnHeadersFullPcos}
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
      <div className='coupon-wrapper-content'>
        {pcosData.length ? (
          <>
            <Search
              name={'search'}
              handleOnchange={handleFieldUpdate}
              value={search}
              searchSubmit={searchSubmit}
              placeholder='Search'
              handlePressKey={handlePressKey}
            />
            <br />
            <Table
              pagination={true}
              columnContent={pcosData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchPcosData({ page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchPcosData({ page: currentPage - 1 });
              }}
            />
          </>
        ) : (
          <EmptyState title='No Appointments' />
        )}
      </div>

    {loading && <Spinner />}
    </>
  );
};

export default PCOS;
