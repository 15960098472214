import axios from 'axios';
import { useToasts } from "react-toast-notifications";

// Import Store from Global Store if Authorization Header is Required

import { store } from '../store';

// Begin Axios Base Instance
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  timeout: 30000,
});

axiosInstance.interceptors.request.use((request) => {
  return requestInterceptor(request);
});

const requestInterceptor = async (request) => {
  // console.log('Intercepted Client Request', request);

  const authToken = store.getState().auth.authToken;

  if (authToken) {
    request.headers.common['Authorization'] = `Bearer ${authToken}`;
  }
  return request;
};

// Begin Axios Response Interceptors
axiosInstance.interceptors.response.use(
  (response) => {
    return responseInterceptor(response, null);
  },
  (error) => {
    return responseInterceptor(null, error);
  }
);

const responseInterceptor = async (response, error) => {
  if (response) {
    // console.log('Intercepted Server Response', response);

    return response.data;
  } else if (error) {

    // console.log('Intercepted Server Response Error', error);

    if (error.response) {

      if (error.response.status === 401) {
        console.log(window.location);
        if(window.location.pathname !=='/'){
          localStorage.removeItem('persist:root');
          window.location.assign('/');
        } else{
          return Promise.reject(error);
        }
          
      } 
      // else if (error.response.status === 403) {
      //   // Authorization Issue - Try to Refresh the User Token
      // }
    }

    return Promise.reject(error);
  }
};
