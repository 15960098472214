import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import {
  getTopicByIdService,
  updateTopicService,
  deleteTopicService,
} from '../../services/topicsService';
import { useToasts } from 'react-toast-notifications';
import Spinner from '../../components/Spinner';
import './styles.scss';

const EditTopic = (props) => {
  let history = useHistory();
  const { addToast } = useToasts();

  const [topicName, setTopicName] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [topicColor, setTopicColor] = useState('');

  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  useEffect(() => {
    const topicId = props?.match?.params?.topicIdentifier;
    setLoading(true);
    getTopicByIdService(topicId).then((res) => {
      if (res.code === 200) {
        setLoading(false);
        setTopicName(res.data.name);
        setTopicColor(res?.data?.color);
      }
    });
  }, []); // eslint-disable-line

  const handleEditTopic = () => {
    setLoading(true);
    const topicId = props?.match?.params?.topicIdentifier;
    const data = { name: topicName, color: topicColor };
    updateTopicService(topicId, data)
      .then((res) => {
        if (res.code === 200) {
          addToast('Topic updated successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });
          history.push('/topics', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
        }
        // setLoading(false);
      });
  };

  //Delete Topic
  const handleDeleteTopic = (idIdentifier) => {
    let topicId = idIdentifier;
    if (topicId) {
      deleteTopicService(topicId)
        .then((response) => {
          addToast('Topic has been deleted successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // TODO: Handle Response Using Response Code
          history.push('/topics', { page_current: currentPage });
        })
        .catch((error) => {
          setLoading(false);

          addToast(error.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  return (
    <div className='edittopic-wrapper'>
      <Header />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit Topic' />
        {loading && <Spinner />}

        <div className='edittopic-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={topicName}
                  name='editTopicName'
                  label='Topic Name'
                  onChange={(e) => {
                    setTopicName(e.target.value);
                  }}
                  placeholder='Enter text'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='color'
                  className='color-input'
                  value={topicColor}
                  name='topicColor'
                  label='Topic Color'
                  required='yes'
                  onChange={(e) => {
                    setTopicColor(e.target.value);
                  }}
                  placeholder='Enter Text'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={() => {
                    handleEditTopic(props?.match?.params?.topicIdentifier);
                  }}
                />
                {/* <SecondaryButton value='Delete' className='danger-button' /> */}
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value='Delete Topic'
                  className='danger-button'
                  handleClick={() =>
                    handleDeleteTopic(props?.match?.params?.topicIdentifier)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTopic;
