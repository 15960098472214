import BlogImage from '../../assets/images/blog.svg';
import AuthorImage from '../../assets/images/author.svg';
import PhysicianImage from '../../assets/images/physician.svg';
import SpecializationImage from '../../assets/images/specialization.svg';
import TopicImage from '../../assets/images/topic.svg';
import WebinarImage from '../../assets/images/webinar.svg';
import SupportImage from '../../assets/images/support.svg';
// import UserImage from '../../assets/images/profileUser.svg';
import companyImage from '../../assets/images/business.svg';
import aocImage from '../../assets/images/research.svg';
import video from '../../assets/images/video.svg';
import symptom from '../../assets/images/symptoms.svg';
import testimonial from '../../assets/images/testimonial.svg';
import coupon from '../../assets/images/coupons.png';
import payments from '../../assets/images/payments.png';
import messages from '../../assets/images/messages-icon.png';
import programs from '../../assets/images/programs.png';
import clinic from '../../assets/images/clinic.svg';
import question from '../../assets/images/question.svg';
import memberCard from '../../assets/images/memberCard.svg';
import ProtectedLink from '../../hocs/ProtectedLink'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './styles.scss';

const Sidebar = (props) => {
  const auth = useSelector((state) => state.auth);
  const userData = auth?.authData;
  const userRole = auth?.authData?.role ?? -1;

  return (
    <aside
      id='test'
      className='sidebar-primary d-flex justify-content-start flex-column'
    >
      <ProtectedLink
        to={'/appointments'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={messages} alt='Messages' className='icon-size' />
        &nbsp; &nbsp;Appointments
      </ProtectedLink>
      <ProtectedLink
        to={'/blogs'}
        userRole={userRole}
        className=' sidebar-primary-link blogClass'
        activeClassName='sidebar-primary-active'
      >
        <img src={BlogImage} alt='Blogs' className='icon-size' />
        &nbsp; &nbsp;Blogs
      </ProtectedLink>
      <ProtectedLink
        to={'/authors'}
        userRole={userRole}
        className='sidebar-primary-link authorClass'
        activeClassName='sidebar-primary-active'
      >
        <img src={AuthorImage} alt='Authors' className='icon-size' />
        &nbsp; &nbsp;Authors
      </ProtectedLink>
      <ProtectedLink
        to={'/physicians'}
        userRole={userRole}
        className='sidebar-primary-link physicianClass'
        activeClassName='sidebar-primary-active'
      >
        <img src={PhysicianImage} alt='Physicians' className='icon-size' />
        &nbsp; &nbsp;Physicians
      </ProtectedLink>
      <ProtectedLink
        to={'/topics'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={TopicImage} alt='Topics' className='icon-size' />
        &nbsp; &nbsp;Topics
      </ProtectedLink>
      <ProtectedLink
        to={'/specializations'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img
          src={SpecializationImage}
          alt='Specializations'
          className='icon-size'
        />
        &nbsp; &nbsp;Specializations
      </ProtectedLink>
      <ProtectedLink
        to={'/webinars'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={WebinarImage} alt='Webinars' className='icon-size' />
        &nbsp; &nbsp;Webinars
      </ProtectedLink>

      <ProtectedLink
        to={'/support-groups'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={SupportImage} alt='Support Groups' className='icon-size' />
        &nbsp; &nbsp;Support Groups
      </ProtectedLink>
      <ProtectedLink
        to={'/area-of-content'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={aocImage} alt='Area Of  Content' className='icon-size' />
        &nbsp; &nbsp;Area of Content
      </ProtectedLink>
      <ProtectedLink
        to={'/companies'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={companyImage} alt='Area Of  Content' className='icon-size' />
        &nbsp; &nbsp;Companies
      </ProtectedLink>
      <ProtectedLink
        userRole={userRole}
        to={'/questions'}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={question} alt='Area Of  Content' className='icon-size' />
        &nbsp; &nbsp;Questions
      </ProtectedLink>
      <ProtectedLink
        to={'/videos'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={video} alt='Videos' className='icon-size' />
        &nbsp; &nbsp;Videos
      </ProtectedLink>
      {userData && userData.value === 0 && (
        <ProtectedLink
          to={'/membership'}
          userRole={userRole}
          className='sidebar-primary-link'
          activeClassName='sidebar-primary-active'
        >
          <img src={memberCard} alt='Members' className='icon-size' />
          &nbsp; &nbsp;Membership
        </ProtectedLink>
      )}
      <ProtectedLink
        to={'/clinics'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={clinic} alt='Clinics' className='icon-size' />
        &nbsp; &nbsp;Clinics
      </ProtectedLink>
      <ProtectedLink
        to={'/symptoms'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={symptom} alt='Symptoms' className='icon-size' />
        &nbsp; &nbsp;Symptoms
      </ProtectedLink>
      <ProtectedLink
        to={'/testimonials'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={testimonial} alt='Testimonials' className='icon-size' />
        &nbsp; &nbsp;Testimonials
      </ProtectedLink>

      <ProtectedLink
        to={'/coupon-codes'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={coupon} alt='Coupon' className='icon-size' />
        &nbsp; &nbsp;Coupon Codes
      </ProtectedLink>

      <ProtectedLink
        to={'/payments'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={payments} alt='Payments' className='icon-size' />
        &nbsp; &nbsp;Payments
      </ProtectedLink>

      <ProtectedLink
        to={'/messages'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={messages} alt='Messages' className='icon-size' />
        &nbsp; &nbsp;Messages
      </ProtectedLink>

      <ProtectedLink
        to={'/programs'}
        userRole={userRole}
        className='sidebar-primary-link'
        activeClassName='sidebar-primary-active'
      >
        <img src={programs} alt='Programs' className='icon-size' />
        &nbsp; &nbsp;Programs
      </ProtectedLink>


    </aside>
  );
};

export default Sidebar;
