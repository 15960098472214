import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...props }) => {
  const authToken = useSelector((state) => state.auth.authToken);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Route
      exact
      render={(renderProps) => (authToken ? <Component {...renderProps} /> : <Redirect to={'/'} />)}
      {...props}
    />
  );
};

export default ProtectedRoute;
