import './styles.scss';

const SubHeader = (props) => {
  return (
    <section className='subheader-primary d-flex align-items-center justify-content-center flex-column'>
      <h1 className='subheader-primary-title'>{props.title || 'Welcome'}</h1>
      {props.subtitle && <h4 className={`subheader-primary-subtitle ${props.className}`}>{props.subtitle}</h4>}
    </section>
  );
};

export default SubHeader;
