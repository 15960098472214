import ReducerConstants from '../common/reducerConstants';

export const loginAction = (dataPayload) => {
  return {
    type: ReducerConstants.authLogin,
    payload: dataPayload,
  };
};

export const logoutAction = () => {
  return {
    type: ReducerConstants.authLogout,
  };
};
