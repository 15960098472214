import React from 'react';

import SearchImg from '../../assets/images/search.svg';
import './styles.scss';

const Search = ({
  name,
  value,
  handleOnchange,
  searchSubmit,
  placeholder,
  handlePressKey,
}) => {
  return (
    <div className='search-section'>
      <input
        type='text'
        name={name}
        className='mr-3 input'
        placeholder={placeholder}
        value={value}
        onChange={(event) => handleOnchange(name, event.target.value, event)}
        onKeyPress={handlePressKey}
      />
      <button
        className='create-cta'
        // className='sigup-btn sigup-btn-mobile'
        onClick={() => {
          searchSubmit();
        }}
      >
        <img src={SearchImg}  width='20' />

      </button>
    </div>
  );
};

export default Search;
