const BackendConstants = {
  // Authentication
  login: '/auth/login',

  // Topics
  topics: '/topics',
  topicById: '/topics/:id',
  deleteTopicId: '/topics/:id',

  // Specializations
  specializations: '/specializations',
  specializationById: '/specializations/:id',
  deleteSpecializationById: '/specializations/:id',
  changeSpecializationStatusById: '/specializations/:id/status',

  // Uploads
  uploads: '/uploads',

  // Authors
  authors: '/users',
  authorByDoctor: '/users/:id/map-author',
  authorById: '/users/:id',

  // Blogs
  blogs: '/blogs',
  blogById: '/blogs/:id',
  editorImage: (id) => `/uploads/get?id=${id}&is_public=true`,

  //Doctors
  doctors: '/users',
  doctorById: '/users/:id',
  deletePhysicianById: '/users/:id',

  // Support Groups
  supportGroups: '/support-groups',
  supportGroupsById: '/support-groups/:id',

  //Webinars
  webinars: '/webinars',
  webinarById: '/webinars/:id',
  deleteWebinarById: '/webinars/:id',

  //Area Of Content
  //Companies
  company: '/companies',
  companyById: '/companies/:id',
  changeCompanyStatusById: '/companies/:id/status',

  //AOC
  areaOfContent: '/aocs',
  areaOfContentById: '/aocs/:id',
  deleteAreaOfContentById: '/aocs/:id',

  //Questions
  questions: '/questions',
  questionById: '/questions/:id',
  deleteQuestionById: '/questions/:id',
  //USERS
  users: '/customers',
  userById: '/customers/:id',
  deleteUserById: '/customers/:id',
  userStats: '/customers/stats',
  changeUserStatusById: 'customers/:id/status',
  exportUsers: '/customers/export',
  //VIDEOS
  videos: '/videos',
  videoById: '/videos/:id',
  deleteVideoById: '/videos/:id',

  //MEMBERSHIP
  membership: '/memberships',

  //CLINICS
  clinics: '/clinics',
  clinicById: 'clinics/:id',
  addOrDeleteClinicById: '/aocs/:id/is-clinic',

  //SYMPTOMS
  symptoms: '/symptoms',
  symptomById: '/symptoms/:id',
  changeSymptomStatusById: '/symptoms/:id/status',

  //TESTIMONIALS
  testimonials: '/testimonials',
  testimonialById: '/testimonials/:id',
  changeTestimonialStatusById: '/testimonials/:id/status',

  //PROMO CODES
  promoCode: '/promo-codes',

  //PAYMENTS
  checkoutLogs: 'payments/checkout-logs',
  refreshPaymentsOnOrder: 'orders/refreshPayments/:id',
  initiateRefund: 'orders/initiateRefund/:id',

  //Messages
  messagesLogs: '/typeform/sent-messages',

  //Slots
  availableSlots: '/available-slot',
  AvailableDoctorId: '/available-slot/:id?day=',
  updateSlotOfDoctorByAdmin: '/available-slot/:id',
  deleteSlotOfDoctorByAdmin: '/available-slot/:id',
  postSlots: '/slots/multiple',
  getSlots: '/slots/multiple/:id',
  getUnavailability: '/slots/unavailability?id=:id&type=:type',
  postUnavailability: '/slots/unavailability',

  //Appointments
  appointments: '/appointments',
  appointmentById: '/appointments/:id',
  rescheduleAppointment: '/appointments/:id',
  slotsByDate: '/slots/:id?date=',
  optinUsers: '/typeform/optin-users',
  updateStatus: '/appointments/status/:id',
  exportAppointmentData: '/reports/:resource',
  latestAvailableDate: '/slots/avalabilty/:id',
};

export default BackendConstants;
