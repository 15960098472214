import { useState, useEffect } from 'react';
import { parseDropdownData } from './parser';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { addNewQuestionService } from '../../services/questionService';
import { uploadImageService } from '../../services/uploadService';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { cloneDeep } from 'lodash';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import DropdownSelect from '../../components/DropdownSelect';
import PrimaryButton from '../../components/PrimaryButton';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const userData = {
  userName: 'Admin',
};

const statusOptions = [
  {
    name: 'Published',
    value: 'PUBLISHED',
  },
  {
    name: 'Archived',
    value: 'ARCHIVED',
  },
];

const CreateQuestions = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [aocOptions, setAocOptions] = useState();
  const [aocId, setAocId] = useState();
  const [authorName, setAuthorName] = useState('');
  const [authorExperience, setAuthorExperience] = useState('');
  const [authorSpecialization, setAuthorSpecialization] = useState('');
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(null);

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAocOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
  }, []); // eslint-disable-line

  //Image
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));
    }
  };

  //Upload Image
  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'question') {
      setQuestion(fieldValue);
    } else if (fieldName === 'answer') {
      setAnswer(fieldValue);
    } else if (fieldName === 'authorName') {
      setAuthorName(fieldValue);
    } else if (fieldName === 'authorExperience') {
      setAuthorExperience(fieldValue);
    } else if (fieldName === 'authorSpecialization') {
      setAuthorSpecialization(fieldValue);
    }
  };

  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAocId(eventValue);
    }
  };

  //Status Selection
  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setStatus(eventValue);
    }
  };

  const validateFormSubmission = () => {
    let isFormValid = true;
    const numberRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;

    if (!file || !image) {
      addToast('Author photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!question) {
      addToast('Question is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!answer) {
      addToast('Answer is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorName) {
      addToast('Author Name is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!aocId || aocId === '') {
      addToast('Area Of Content is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorExperience) {
      addToast('Authors Experience is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!numberRegex.test(authorExperience)) {
      addToast('Please add valid experience .', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorSpecialization) {
      addToast('Authors Specialization is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!status || status === '') {
      addToast('Status is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    return isFormValid;
  };

  const handleSubmitQuestionData = (dataPayload) => {
    const params = { include: 'aoc' };
    addNewQuestionService(dataPayload, params)
      .then((responseData) => {
        if (responseData.code === 200 || responseData.code === 201) {
          addToast('Questions created successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          history.push('/questions', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/questions');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

          setLoading(false);
        }
      });
  };

  const handleCreateQuestion = () => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);

      uploadImage()
        .then((imageResponseData) => {
          if (imageResponseData) {
            if (imageResponseData.code === 200) {
              const dataPayload = {
                image_id: imageResponseData.data?.id,
                question: question,
                answer: answer,
                author_name: authorName,
                author_specialization: authorSpecialization,
                author_experience_years: authorExperience,
                status: status,
                aoc_id: aocId,
              };

              handleSubmitQuestionData(dataPayload);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='question-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Question' />

        <div className='question-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='question'
                  value={question}
                  label={'Question'}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='answer'
                  label={'Answer'}
                  value={answer}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='authorName'
                  value={authorName}
                  label={'Author Name'}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6'>
                <Input
                  name='authorSpecialization'
                  value={authorSpecialization}
                  label={'Specialization'}
                  placeholder='Enter Text'
                  required='yes'
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='authorExperience'
                  value={authorExperience}
                  label={'Author Experience'}
                  placeholder='Enter Text'
                  required='yes'
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  handleChange={handleImageSelection}
                  required='yes'
                  label='Upload Photo of the author'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  options={aocOptions}
                  label={'AOC'}
                  placeholder='Choose AOC'
                  handleChange={handleAocSelection}
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  options={statusOptions}
                  label={'Status'}
                  placeholder='Choose Status'
                  handleChange={handleStatusSelection}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleCreateQuestion}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateQuestions;
