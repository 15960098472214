import moment from 'moment';
import React, { useEffect, useState, memo } from 'react';
import {
  getUnavailabilityByDoctor,
  saveUnavailabilityByDoctor,
} from '../../services/availableSlotService';
import DateTimeRangePicker from './DateTimeRangeSelector';

const DateTimeRangeSelector = ({ physician, type }) => {
  const [unavailabilities, setUnavailabilities] = useState([]);
  const getUnavailability = async () => {
    var res;
    if (type === 'doctor' && physician) {
      res = await getUnavailabilityByDoctor({
        id: physician,
        type,
      });
    } else if (type === 'general' && !physician) {
      res = await getUnavailabilityByDoctor({
        type,
      });
    }
    setUnavailabilities([...res?.data]);
  };
  const current = moment().utc(true);

  const addUnavailability = () => {
    let state = unavailabilities;
    state.push({
      from: current.format('YYYY-MM-DD HH:mm'),
      to: current.format('YYYY-MM-DD HH:mm'),
    });
    setUnavailabilities([...state]);
  };
  const saveNewUnavailabilty = async () => {
    var res;
    if (type === 'doctor' && physician) {
      res = await saveUnavailabilityByDoctor({
        id: physician,
        unavailability: unavailabilities,
        type,
      });
    } else if (type === 'general' && !physician) {
      res = await saveUnavailabilityByDoctor({
        unavailability: unavailabilities,
        type,
      });
    }
    setUnavailabilities([...res?.data]);
  };

  const handleOnChange = ({ i, to, from }) => {
    let state = unavailabilities;
    state[i].to = to;
    state[i].from = from;
    setUnavailabilities([...state]);
  };

  const removeUnavailability = (i) => {
    const state = unavailabilities.filter((e, index) => i !== index);
    setUnavailabilities([...state]);
  };
  useEffect(async () => {
    if (type === 'doctor' && physician) {
      await getUnavailability();
    } else if (type === 'general' && !physician) {
      await getUnavailability();
    }
  }, [physician, type]);
  console.table(unavailabilities);
  return (
    <div>
      <DateTimeRangePicker
        unavailabilities={unavailabilities}
        addUnavailability={addUnavailability}
        handleOnChange={handleOnChange}
        removeUnavailability={removeUnavailability}
      />
      <button onClick={saveNewUnavailabilty}>Save</button>
    </div>
  );
};

export default memo(DateTimeRangeSelector);
