import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';
import { type } from 'jquery';

export const addNewAvailableSlotService = (data) => {
  return axiosInstance.post(BackendConstants.availableSlots, data);
};

export const addMultipleNewAvailableSlotService = (data) => {
  return axiosInstance.post(BackendConstants.postSlots, data);
};

export const getMultipleNewAvailableSlotService = (data) => {
  const url = `${BackendConstants.getSlots.replace(':id', data.doctorId)}`;
  return axiosInstance.get(url);
};

export const getDaysSlotsByDoctor = (data) => {
  const url = `${BackendConstants.AvailableDoctorId.replace(
    ':id',
    data.doctor_id,
  )}${data.day}`;
  return axiosInstance.get(url);
};

export const getUnavailabilityByDoctor = (data) => {
  const url = `${BackendConstants.getUnavailability
    .replace(':id', data.id)
    .replace(':type', data.type)}`;
  return axiosInstance.get(url);
};

export const saveUnavailabilityByDoctor = (data) => {
  const url = `${BackendConstants.postUnavailability}`;
  return axiosInstance.post(url, { ...data });
};

export const updateSlotOfDoctorByAdmin = (data) => {
  return axiosInstance.patch(
    BackendConstants.updateSlotOfDoctorByAdmin.replace(':id', data.timingid),
    data,
  );
};

export const deleteSlotOfDoctorByAdmin = (data) => {
  return axiosInstance.delete(
    BackendConstants.deleteSlotOfDoctorByAdmin.replace(':id', data),
  );
};
