import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import { addNewWebinarService } from '../../services/webinarsService';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { getAllCompaniesService } from '../../services/companyService';
import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DateTimeInput from '../../components/DateTimeInput';
import DropdownSelect from '../../components/DropdownSelect';
import moment from 'moment';
import { parseDropdownData } from './parser';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const CreateWebinar = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [webinarTitle, setWebinarTitle] = useState('');
  const [webinarUrl, setWebinarUrl] = useState('');
  const [webinarSummary, setWebinarSummary] = useState('');
  const [webinarDate, setWebinarDate] = useState(new Date());
  const [webinarDuration, setWebinarDuration] = useState('');
  const [webinarLeaders, setWebinarLeaders] = useState('');
  const [webinarButtonText, setWebinarButtonText] = useState('');
  const [webinarIsFeatured, setWebinarIsFeatured] = useState('');
  const [webinarStatus, setWebinarStatus] = useState('');
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [aocOptions, setAOCOptions] = useState([]);
  const [areaOfContentId, setAreaOfContentId] = useState(null);
  const [memberFlag, setMemberFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  const userData = {
    userName: 'Admin',
  };

  const featuredOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  const webinarStatusOptions = [
    {
      name: 'Upcoming',
      value: 'UPCOMING',
    },
    {
      name: 'Archived',
      value: 'ARCHIVED',
    },
  ];

  const MemberFlagOptions = [
    {
      name: 'True',
      value: true,
    },
    {
      name: 'False',
      value: false,
    },
  ];

  //Paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  //AOC Data
  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAOCOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  //Companies Data
  const fetchCompanyData = (params) => {
    setLoading(true);
    getAllCompaniesService()
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setCompanyOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
    fetchCompanyData();
  }, []); // eslint-disable-line

  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };

  const handleFeaturedSelection = (eventValue) => {
    if (eventValue) {
      setWebinarIsFeatured(eventValue);
    }
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setWebinarStatus(eventValue);
    }
  };

  const handleScheduleChange = (momentValue) => {
    if (momentValue) {
      setWebinarDate(momentValue);
    }
  };
  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAreaOfContentId(eventValue);
    }
  };
  const handleCompanySelection = (eventValue) => {
    if (eventValue) {
      setCompanyId(eventValue);
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'webinarTitle') {
      setWebinarTitle(fieldValue);
    } else if (fieldName === 'webinarLeader') {
      setWebinarLeaders(fieldValue);
    } else if (fieldName === 'webinarDuration') {
      setWebinarDuration(fieldValue);
    } else if (fieldName === 'webinarURL') {
      setWebinarUrl(fieldValue);
    } else if (fieldName === 'webinarButtonText') {
      setWebinarButtonText(fieldValue);
    } else if (fieldName === 'webinarSummary') {
      setWebinarSummary(fieldValue);
    }
  };

  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));
    }
  };

  const validateFormData = () => {
    let isFormValid = true;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    if (!file || !image) {
      addToast('Webinar photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarTitle) {
      addToast('Title is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarUrl) {
      addToast('URL is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarSummary) {
      addToast('Summary is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (webinarSummary.length > 300) {
      addToast('Summary cant exceed more than 300 characters.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarDate) {
      addToast('Date is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarDuration) {
      addToast('Duration is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarLeaders) {
      addToast('Leaders is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarButtonText) {
      addToast('Button Text is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarIsFeatured || webinarIsFeatured === '') {
      addToast('Featured status is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarStatus || webinarStatus === '') {
      addToast('Status is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (
      !moment(
        moment(webinarDate).format(dateFormat),
        dateFormat,
        true
      ).isValid()
    ) {
      addToast('Enter a Valid Schedule', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleSubmitWebinarData = (dataPayload) => {
    addNewWebinarService(dataPayload)
      .then((responseData) => {
        setLoading(false);

        if (responseData) {
          if (responseData.code === 200) {
            addToast('Webinar has been created successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            history.push('/webinars', { page_current: currentPage });
          }
        }
      })
      .catch((err) => {
        setLoading(false);

        const errorData = err.response?.data?.errors;

        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });

              setLoading(false);
            }
          }
        } else {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const handleCreateWebinar = () => {
    const isFormValid = validateFormData();
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    if (isFormValid) {
      setLoading(true);
      uploadImage()
        .then((responseDataImage) => {
          if (responseDataImage) {
            if (responseDataImage.code === 200) {
              const dataPayload = {
                url: webinarUrl,
                title: webinarTitle,
                status: webinarStatus,
                date_time: moment(webinarDate).format('YYYY-MM-DD HH:mm:ss'),
                summary: webinarSummary,
                leaders: webinarLeaders,
                duration: webinarDuration,
                is_featured: webinarIsFeatured,
                button_text: webinarButtonText,
                image_id: responseDataImage.data?.id,
                aoc_id: areaOfContentId,
                company_id: companyId,
                //have to send aoc and company
              };

              handleSubmitWebinarData(dataPayload);
            }
          }
        })
        .catch((uploadErrorData) => {
          setLoading(false);
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='createwebinar-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Webinar' />

        <div className='createwebinar-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  handleChange={handleImageSelection}
                  required='yes'
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarTitle'
                  value={webinarTitle}
                  placeholder='Title'
                  handleChange={handleFieldUpdate}
                  label='Title'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarLeader'
                  value={webinarLeaders}
                  placeholder='Leaders'
                  handleChange={handleFieldUpdate}
                  label='Leaders'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DateTimeInput
                  label='Schedule'
                  required='yes'
                  onChange={handleScheduleChange}
                  value={webinarDate}
                  name='webinarDate'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarDuration'
                  value={webinarDuration}
                  placeholder='Duration'
                  handleChange={handleFieldUpdate}
                  label='Duration'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarURL'
                  value={webinarUrl}
                  placeholder='Webinar URL / Link'
                  handleChange={handleFieldUpdate}
                  label='Webinar URL'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarButtonText'
                  value={webinarButtonText}
                  placeholder='Button Text to Display'
                  handleChange={handleFieldUpdate}
                  label='Button Text'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={webinarIsFeatured}
                  options={featuredOptions}
                  label={'Featured on Homepage'}
                  placeholder='Choose an Option'
                  required='yes'
                  handleChange={handleFeaturedSelection}
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={webinarStatus}
                  options={webinarStatusOptions}
                  label={'Choose a Status'}
                  placeholder='Choose a Status'
                  required='yes'
                  handleChange={handleStatusSelection}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select AOC'
                  label={'Select a AOC'}
                  value={areaOfContentId}
                  options={aocOptions}
                  handleChange={handleAocSelection}
                  // required="yes"
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select a Company'
                  label={'Select a Company'}
                  value={companyId}
                  options={companyOptions}
                  // options={companyOptions}
                  handleChange={handleCompanySelection}
                  // required='yes'
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  options={MemberFlagOptions}
                  label={"Member Only ?"}
                  placeholder="Choose"
                  handleChange={handleMemberFlagSelection}
                />
              </div>
            </div> */}

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='webinarSummary'
                  value={webinarSummary}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  label='Summary'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton value='Save' handleClick={handleCreateWebinar} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateWebinar;
