import React, { memo } from 'react';
import { Table } from 'react-bootstrap';
import {
  initiateRefund,
  syncAllPayments,
} from '../../services/paymentsService';
import SecondaryButton from '../SecondaryButton';

const OrderPaymentsTable = ({ payments, orderId, status }) => {
  const handleRefresh = async () => {
    await syncAllPayments({ id: orderId });
    window.location.reload(false);
  };
  const handleRefund = async () => {
    await initiateRefund({ id: orderId });
    await initiateRefund({ id: orderId });
  };
  return (
    <div>
      <SecondaryButton
        value="Refresh Payments"
        handleClick={async () => await handleRefresh()}
        className={'button-primary'}
      />
      {status === 'INACTIVE' && (
        <SecondaryButton
          value="Initiate Refund"
          handleClick={async () => await handleRefund()}
          className={'button-primary'}
        />
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th> amount</th>
            <th>serverPaymentid</th>
            <th>paymentTimestamp</th>
            <th>razorpayOrderId</th>
            <th>razorpayPaymentId</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {payments &&
            payments.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.amount}</td>
                  <td>{item.id}</td>
                  <td>{item.paymentTimestamp}</td>
                  <td>{item.razorpayOrderId}</td>
                  <td>{item.razorpayPaymentId}</td>
                  <td>{item.status}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default memo(OrderPaymentsTable);
