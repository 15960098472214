import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  getSlotsByDate,
  rescheduleAppointment,
} from '../../services/appointmentServices';
import Input from '../../components/Input';
import { useToasts } from 'react-toast-notifications';
import SecondaryButton from '../../components/SecondaryButton';
import { Spinner } from 'react-bootstrap';

const Schedule = ({
  setReschedule = () => {},
  appointmentId = '',
  data = {},
  doctorId,
  handleOnChange = () => {},
}) => {
  const { date } = data || {};
  const [time, setTime] = useState();
  const [soretedTime, SetSortedTime] = useState([]);
  const [loader, setLoader] = useState(false);
  const [reasonForRescheduling, setReasonForRescheduling] = useState('');
  const { addToast } = useToasts();

  //get all slots for a day
  useEffect(async () => {
    if (data.date) {
      setLoader(true);
      const state = await getSlotsByDate({ date: data.date, doctorId });
      SetSortedTime(state.data);
    }
    setTime();
    setLoader(false);
  }, [data.date, doctorId]);

  const selectTime = (timings) => {
    console.log(timings, data.date);
    setTime(timings);
    handleOnChange({ name: 'time', value: timings });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(data.date, time, appointmentId);
    const dataPayload = {
      start_time: data.date + ' ' + time.start,
      end_time: data.date + ' ' + time.end,
      message: reasonForRescheduling,
    };
    const res = await rescheduleAppointment(appointmentId, dataPayload);
    if (res.success == true) {
      window.location.reload();
      addToast('Appointments status has been rescheduled.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast('Appointments could not be rescheduled.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const currentDate = moment().utc().format('YYYY-MM-DD');

  return (
    <>
      <form className="schedule" onSubmit={onSubmit}>
        <h1>Select a Date & Time</h1>

        <div className="row">
          <div className="col-12 col-lg-4 col-md-6">
            <div className="date-input">
              <label className="label">
                Date
                <span>*</span>
              </label>
              <input
                className="input"
                type="date"
                min={currentDate}
                required
                name="date"
                value={date}
                onChange={({ target }) => handleOnChange(target)}
              />
            </div>
          </div>

          <div className="col-12 mt-4">
            <div className="time-slots">
              <label className="label">
                Time
                <span>*</span>
              </label>
              <div className="time-slots-list">
                {loader ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  <>
                    {soretedTime?.length > 0 ? (
                      soretedTime?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`time-slots-list-item ${
                              data?.time === item ? 'selected' : ''
                            }`}
                            onClick={() => selectTime(item)}
                          >
                            <p>
                              {item.start} - {item.end}
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <div>NO SLOT AVAILABLE</div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="btn-container">
              <Input
                name="reasonForRescheduling"
                value={reasonForRescheduling}
                label={'Reason For Rescheduling'}
                placeholder="Reason For Rescheduling"
                handleChange={(o, v) => setReasonForRescheduling(v)}
                required="yes"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <SecondaryButton
                  value="Cancel"
                  handleClick={() => setReschedule(false)}
                  className={'button-primary'}
                />

                <SecondaryButton
                  value="Reschedule"
                  handleClick={onSubmit}
                  className="success-button"
                  // style={{ height: 35 }}
                  disabled={
                    !data?.date ||
                    !time ||
                    !appointmentId ||
                    !reasonForRescheduling.length
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Schedule;
