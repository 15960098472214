import React, { useState } from 'react';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PCOS from './PCOS';

import './style.scss';

const userData = {
  userName: 'Admin',
};

const Programs = (props) => {
  const [tab, setTab] = useState('PCOS');

  return (
    <div className='programs-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Programs' />
        <div className='tabs'>
          <div className={`tab ${tab === 'PCOS' && 'active'}`}>PCOS</div>
        </div>
        {tab === 'PCOS' && <PCOS />}
      </div>
    </div>
  )
};

export default Programs;
