import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllTestimonialsService = async (params) => {
  return await axiosInstance.get(BackendConstants.testimonials, {
    params: params,
  });
};

export const addNewTestimonialsService = (data) => {
  return axiosInstance.post(BackendConstants.testimonials, data);
};

export const getSingleTestimonialDetailsService = (
  testimonialIdentifier,
  params
) => {
  return axiosInstance.get(
    BackendConstants.testimonialById.replace(':id', testimonialIdentifier),
    { params: params }
  );
};

export const updateTestimonialDetailsService = (
  testimonialIdentifier,
  data
) => {
  return axiosInstance.patch(
    BackendConstants.testimonialById.replace(':id', testimonialIdentifier),
    data
  );
};

export const updateTestimonialStatusService = (testimonialIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.changeTestimonialStatusById.replace(
      ':id',
      testimonialIdentifier
    ),
    data
  );
};
