import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllTopicsService = (params) => {
  return axiosInstance.get(BackendConstants.topics, { params: params });
};

export const addNewTopicService = (data) => {
  return axiosInstance.post(BackendConstants.topics, data);
};

export const getTopicByIdService = (topicId) => {
  return axiosInstance.get(BackendConstants.topicById.replace(':id', topicId));
};

export const updateTopicService = (topicId, data) => {
  return axiosInstance.patch(BackendConstants.topicById.replace(':id', topicId), data);
};

export const deleteTopicService = (topicId) => {
  return axiosInstance.delete(BackendConstants.deleteTopicId.replace(':id', topicId));
};