import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getAllVideosService } from '../../services/videosService';
import { useToasts } from 'react-toast-notifications';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';
import Input from '../../components/Input';
import PrimaryButton from '../../components/PrimaryButton';
import Search from '../../components/Search';

import './styles.scss';
import sessionStorage from 'redux-persist/es/storage/session';

const columnHeaders = [
  'S. No.',
  'Title',
  'Speaker',
  'AOC',
  'Created',
  'Action',
];

const userData = {
  userName: 'Admin',
};
const VideosListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [videosData, setVideosData] = useState([]);

  const handleActionTrigger = (selectedItem) => {
    const videoId = selectedItem.id;
    history.push(`/videos/edit/${videoId}`, { page_number: currentPage });
  };

  const fetchVideosData = (params) => {
    setLoading(true);

    getAllVideosService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setVideosData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };
  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchVideosData({ page: pageNumber, include: 'aoc' });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
        include: 'aoc',
      };
      fetchVideosData(params);
      props.location.state.page_current = null;
    } else {
      const params = { page: currentPage, include: 'aoc' };
      fetchVideosData(params);
    }
  }, []); // eslint-disable-line

  const handleAddAuthorCta = () => {
    history.push('/videos/create', { page_number: currentPage });
  };

  return (
    <div className='videos-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Videos' />
        <div className='videos-wrapper-content'>
          <div className='videos-wrapper-header d-flex align-items-center justify-content-end'>
            <div className='create-cta' onClick={handleAddAuthorCta}>
              Add New Video
            </div>
          </div>
          {videosData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={videosData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchVideosData({ page: currentPage + 1, include: 'aoc' });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchVideosData({ page: currentPage - 1, include: 'aoc' });
              }}
            />
          ) : (
            <EmptyState title='No Videos found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default VideosListing;
