import moment from 'moment';
import React from 'react';
import { RangePicker } from 'react-minimal-datetime-range';
import addIcon from './../../assets/images/add.svg';
import deleteIcon from './../../assets/images/delete.svg';
import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';

const DateTimeRangePicker = ({
  unavailabilities,
  addUnavailability,
  handleOnChange,
  removeUnavailability,
}) => {
  return (
    <div>
      {unavailabilities.map((item, index) => {
        const from = moment.utc(item.from);
        const to = moment.utc(item.to);
        return (
          <div index={index} style={{ display: 'flex' }}>
            <RangePicker
              locale="en-us"
              disabled={false}
              allowPageClickToClose={true}
              onConfirm={(res) =>
                handleOnChange({ from: res[0], to: res[1], i: index })
              }
              style={{ width: '400px', margin: '20 auto' }}
              placeholder={['Start Time', 'End Time']}
              showOnlyTime={false}
              defaultDates={[
                from.format('YYYY-MM-DD'),
                to.format('YYYY-MM-DD'),
              ]}
              defaultTimes={[from.format('HH:mm'), to.format('HH:mm')]}
              initialDates={[
                from.format('YYYY-MM-DD'),
                to.format('YYYY-MM-DD'),
              ]}
              initialTimes={[from.format('HH:mm'), to.format('HH:mm')]}
            />
            <button
              style={{ backgroundColor: 'transparent' }}
              onClick={() => removeUnavailability(index)}
            >
              <img src={deleteIcon} style={{ height: 20, width: 20 }} />
            </button>
          </div>
        );
      })}

      <button
        onClick={addUnavailability}
        style={{ backgroundColor: 'transparent' }}
      >
        <img src={addIcon} style={{ height: 20, width: 20 }} />
      </button>
    </div>
  );
};

export default DateTimeRangePicker;
