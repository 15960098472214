import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllSupportGroupsService = (params) => {
  return axiosInstance.get(BackendConstants.supportGroups, { params: params });
};

export const addNewSupportGroupService = (data) => {
  return axiosInstance.post(BackendConstants.supportGroups, data);
};

export const getSingleSupportGroupDetailsService = (supportGroupIdentifier) => {
  return axiosInstance.get(BackendConstants.supportGroupsById.replace(':id', supportGroupIdentifier));
};

export const updateSupportGroupDetailsService = (supportGroupIdentifier, data) => {
  return axiosInstance.patch(BackendConstants.supportGroupsById.replace(':id', supportGroupIdentifier), data);
};

export const deleteSupportGroupService = (supportGroupIdentifier) => {
  return axiosInstance.delete(BackendConstants.supportGroupsById.replace(':id', supportGroupIdentifier));
};
