import './styles.scss';

const TextArea = (props) => {
  return (
    <div className='textarea-wrapper'>
      {props.label && (
        <label className='textarea-label' htmlFor={props.name}>
          {props.label}
          {props.required === 'yes' ? (
            <span className='text-danger'>*</span>
          ) : (
            ''
          )}
        </label>
      )}

      <textarea
        name={props.name}
        value={props.value}
        placeholder={props.placeholder || 'Input text here'}
        onChange={(event) => props.handleChange(props.name, event.target.value)}
        className={
          props.className
            ? `textarea-field ${props.className}`
            : `textarea-field`
        }
        {...props}
      />
    </div>
  );
};

export default TextArea;
