import moment from 'moment';

export const parseTableData = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (((current_page - 1 ) * 20) + (arrayIndex + 1));
      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.question = arrayItem.question;
      renderingObject.authorName = arrayItem.authorName;
      renderingObject.aoc = arrayItem.aoc.name;
      renderingObject.status = arrayItem.status;
      
      renderingObject.created = moment(arrayItem.createdAt).format('DD/MM/YYYY hh:mm A');
      resultsArr.push(renderingObject);
    });
  }
  return resultsArr;
};
