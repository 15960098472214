import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import {
  getSingleAuthorDetailsService,
  updateAuthorDetailsService,
} from '../../services/authorsService';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const certifiedOptions = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

const EditAuthor = (props) => {
  const history = useHistory();
  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useState('');
  const [authorEmail, setAuthorEmail] = useState();
  const [authorDescription, setAuthorDescription] = useState('');
  const [hasFileModified, setFileModified] = useState(false);
  const [authorIdentifier, setAuthorIdentifier] = useState(null);
  const [imageIdentifier, setImageIdentifier] = useState('');
  const [certifiedProfessional, setCertifiedProfessional] = useState(null);
  const [role, setRole] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const userData = {
    userName: 'Admin',
  };

  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  const fetchAuthorDetailsForPrefill = () => {
    setLoading(true);

    getSingleAuthorDetailsService(authorIdentifier)
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            setAuthorName(response.data?.name);
            setImage(response.data?.image?.url);
            setAuthorDescription(response.data?.about);
            setImageIdentifier(response.data?.image?.id);
            setRole(response.data?.role);
            setCertifiedProfessional(response.data?.isCertifiedProfessional);
            if (response.data?.email !== null) {
              setAuthorEmail(response.data?.email);
            } else {
              setAuthorEmail('');
            }
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));

      setFileModified(true);
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'authorName') {
      setAuthorName(fieldValue);
    } else if (fieldName === 'authorEmail') {
      setAuthorEmail(fieldValue);
    } else if (fieldName === 'authorDescription') {
      setAuthorDescription(fieldValue);
    }
  };

  const handleCertifiedStatus = (selectedValue) => {
    if (selectedValue) {
      setCertifiedProfessional(selectedValue);
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const validateFormData = () => {
    let isFormValid = true;

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    if ((!file || !image) && hasFileModified) {
      addToast('Author photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorName) {
      addToast('Author name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    // else if (!authorEmail) {
    //   addToast('Author email is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });

    //   isFormValid = false;
    // }
    else if (authorEmail !== '' && !emailRegex.test(authorEmail)) {
      addToast('Author email should be a valid email.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorDescription) {
      addToast('Author Description is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    return isFormValid;
  };

  const submitAuthorInformation = (dataPayload) => {
    updateAuthorDetailsService(authorIdentifier, dataPayload)
      .then((responseData) => {
        setLoading(false);

        if (responseData) {
          if (responseData.code === 200) {
            addToast('Author has been updated successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            history.push('/authors', { page_current: currentPage });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
        }
      });
  };

  const handleUpdateAuthorData = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      if (hasFileModified) {
        uploadImage().then((responseDataImage) => {
          if (responseDataImage) {
            if (responseDataImage.code === 200) {
              const dataPayload = {
                name: authorName,
                about: authorDescription,
                image_id: responseDataImage.data?.id,
                is_certified_professional: certifiedProfessional,
              };
              if (role === 'doctor') {
                dataPayload.update_doctor_as_author = true;
              } else if (authorEmail !== '') {
                dataPayload.email = authorEmail;
              }

              submitAuthorInformation(dataPayload);
            }
          }
        });
      } else {
        const dataPayload = {
          name: authorName,
          about: authorDescription,
          image_id: imageIdentifier,
          is_certified_professional: certifiedProfessional,
        };
        if (role === 'doctor') {
          dataPayload.update_doctor_as_author = true;
        } else if (authorEmail !== '') {
          dataPayload.email = authorEmail;
        }

        submitAuthorInformation(dataPayload);
      }
    }
  };

  // Acting ComponentDidMount
  useEffect(() => {
    setLoading(true);

    const capturedIdentifier = props?.match?.params?.authorIdentifier;

    setAuthorIdentifier(capturedIdentifier);
  }, []); // eslint-disable-line

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (authorIdentifier && authorIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchAuthorDetailsForPrefill();
    }
  }, [authorIdentifier]);

  return (
    <div className='editauthor-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit Author' />

        <div className='editauthor-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='authorName'
                  label='Author Name'
                  required='yes'
                  value={authorName}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='email'
                  name='authorEmail'
                  label='Author Email'
                  value={authorEmail}
                  placeholder='Enter text'
                  handleChange={handleFieldUpdate}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={certifiedProfessional}
                  options={certifiedOptions}
                  label={'Certified healthcare professional?'}
                  placeholder='Choose a Status'
                  handleChange={handleCertifiedStatus}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='authorDescription'
                  value={authorDescription}
                  placeholder='Enter text'
                  label='Author Description'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  handleClick={() => handleUpdateAuthorData()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditAuthor;
