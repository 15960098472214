import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../actions/authActions";

import Logo from "../../assets/images/pfh-logo.svg";

import "./styles.scss";

const Header = (props) => {
  const dispatch = useDispatch();
  const [profileExpanded, setProfileExpanded] = useState(false);

  const toggleProfileExpanded = () => {
    setProfileExpanded((oldProfileExpanded) => !profileExpanded);
  };

  const logoutUser = () => {
    dispatch(logoutAction());
    // history.push('/');
  };
  const auth = useSelector((state) => state.auth);

  const userData = auth?.authData;

  return (
    <header className="header-primary d-flex align-items-center justify-content-between">
      <div className="header-primary-left">
        <Link to={"/"}>
          <img
            src={Logo}
            className="header-logo img-fluid"
            alt="Proactive for Her"
          />
        </Link>
      </div>

      {props.userData && (
        <div className="header-primary-right d-flex align-items-center justify-content-end">
          <div className="header-profile position-relative">
            <button
              type="button"
              className="header-profile-trigger"
              onClick={() => toggleProfileExpanded()}
            >
              {userData?.userName || userData.name } <FontAwesomeIcon icon={faBars} />
            </button>

            {profileExpanded && (
              <div className="header-profile-dropdown position-absolute">
                <button
                  type="button"
                  className="profile-dropdown-item"
                  onClick={() => logoutUser()}
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
