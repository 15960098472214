import moment from 'moment';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const parsePcosTable = (inputData, pagination, setFullPcos = () => { }) => {
  let resultsArr = [];
  const current_page = pagination?.current_page;

  if (inputData) {
    inputData?.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 10 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.name = arrayItem?.customerFullName;
      renderingObject.phone = arrayItem?.customerPhone;
      renderingObject.email = arrayItem?.customerEmail;
      renderingObject.date =
        moment(arrayItem.createdAt).format('DD/MM/YYYY') || 'N/A';
      renderingObject.action = (
        <button
          type="button"
          className="flexible-table-button flexible-table-button-width"
          variant="primary"
          onClick={() => setFullPcos({
            name: renderingObject.name,
            phone: renderingObject.phone,
            email: renderingObject.email,
            date: renderingObject.date,
            pincode: arrayItem?.order?.customerAddress.split(',').pop(),
            couponCode: arrayItem?.couponCode,
            originalAmount: arrayItem?.order?.totalAmount,
            paidAmount: arrayItem?.order?.payableAmount,
            serverPaymentId: arrayItem?.order?.payments?.[0].id,
            razorpayPaymentId: arrayItem?.order?.payments?.[0].razorpayPaymentId,
          })}
          title="View Users"
        >
          <FontAwesomeIcon icon={faEye} color="#4CA746" />
        </button>
      );
      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
