import React, { useState, useEffect } from 'react';
import EmptyState from '../../components/EmptyState';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import Table from '../../components/Table';
import './style.scss';
import { useToasts } from 'react-toast-notifications';
import { parsePaymentsTable } from './parser';
import { getAllCheckoutLogs } from '../../services/paymentsService';
import Search from '../../components/Search';

const userData = {
  userName: 'Admin',
};

const columnHeaders = [
  'S. No.',
  "Customer's Name",
  'Email',
  'Phone',
  'Coupon Code',
  'Amount',
  "Doctor's Name",
  'Type',
  'Date',
];

// const dummyData = [
//   {
//     sno: 1,
//     name: 'Saksham',
//     email: 'saksham@gmail.com',
//     phone: '9998887776',
//     coupon_code: 'DEAL10',
//     type: 'CONSULTATION',
//     amount: '1000',
//     date: '13/07/21',
//   },
// ];

const Payments = (props) => {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');

  const fetchPaymentsData = async (params) => {
    setLoading(true);

    getAllCheckoutLogs(params)
      .then((res) => {
        if (res?.code === 200) {
          const {
            data: { results = [], meta: { pagination = {} } = {} } = {},
          } = res || {};

          const { total_pages } = pagination;
          // console.log(results);
          const parsedTableData = parsePaymentsTable(
            results,
            res.data?.meta?.pagination
          );

          setPaymentData(parsedTableData);

          setTotalPage(total_pages);
        }

        setLoading(false);
      })
      .catch((err) => {
        const { response: { data: { errors = {}, message } = {} } = {} } = err;

        if (errors && Object.keys(errors).length > 0) {
          for (let key in errors) {
            for (let e of errors[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          }
        } else {
          addToast(message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        setLoading(false);
      });
  };

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log(pageNumber);
    fetchPaymentsData({ page: pageNumber });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'search') {
      setSearch(fieldValue);
    }
  };

  const handlePressKey = (e) => {
    // const value = e.target.value;
    if (e.key === 'Enter') {
      fetchPaymentsData({ keyword: search });
    }
  };

  //SEARCH SUBMIT
  const searchSubmit = () => {
    fetchPaymentsData({ keyword: search });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
      };
      fetchPaymentsData(params);
      props.location.state.page_current = null;
    } else {
      const params = { page: currentPage };
      fetchPaymentsData(params);
    }
  }, []);

  return (
    <div className='payments-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Payments' />

        <div className='coupon-wrapper-content'>
          {paymentData.length ? (
            <>
              <Search
                name={'search'}
                handleOnchange={handleFieldUpdate}
                value={search}
                searchSubmit={searchSubmit}
                placeholder='Search'
                handlePressKey={handlePressKey}
              />
              <br />
              <Table
                pagination={true}
                columnContent={paymentData}
                paginationPage={currentPage}
                columnHeaders={columnHeaders}
                paginationTotalPage={totalPage}
                handlePageNumber={handlePageNumber}
                nextPage={() => {
                  setCurrentPage(currentPage + 1);
                  fetchPaymentsData({ page: currentPage + 1 });
                }}
                prevPage={() => {
                  setCurrentPage(currentPage - 1);
                  fetchPaymentsData({ page: currentPage - 1 });
                }}
              />
            </>
          ) : (
            <EmptyState title='No Payments' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default Payments;
