import { parseTableData } from "./parser";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAllTopicsService } from "../../services/topicsService";

import Table from "../../components/Table";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import SubHeader from "../../components/SubHeader";
import { useToasts } from "react-toast-notifications";
import EmptyState from "../../components/EmptyState";

import "./styles.scss";

const TopicsListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columnHeaders = ["S. No.", "Name", "Created", "Action"];

  const userData = {
    userName: "Admin",
  };

  const handleActionTrigger = (selectedItem) => {
    history.push(`/topics/edit/${selectedItem.id}`,{page_number : currentPage});
  };

  const getAllTopics = (params) => {
    setLoading(true);

    getAllTopicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setTopics(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllTopics({ page: pageNumber });
  };

  useEffect(() => {
    // const params = { page: 1 };

    // getAllTopics(params);
    
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current};
      getAllTopics(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage};
      getAllTopics(params);
    }
  }, []); // eslint-disable-line

  const handleAddTopicCta = () =>{
    history.push("/topics/create",{page_number : currentPage})
  }

  return (
    <div className="topics-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <div className="flexible-container">
        <SubHeader title="Topics" />

        <div className="topics-wrapper-content">
          <div className="topics-wrapper-header d-flex align-items-center justify-content-end">
            <div onClick={handleAddTopicCta} className="create-cta">
              Create New Topic
            </div>
          </div>
          {topics.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={topics}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                getAllTopics({ page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                getAllTopics({ page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title="No topic found" />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default TopicsListing;
