import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parseDoctorsDropdownData } from './parser';
import { useToasts } from 'react-toast-notifications';
import {
  getAllDoctorsForAuthorService,
  mapDoctorToAuthorService,
  addNewAuthorService,
} from '../../services/authorsService';
import { uploadImageService } from '../../services/uploadService';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const certifiedOptions = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

const CreateAuthor = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useState('');
  const [authorEmail, setAuthorEmail] = useState('');
  const [authorDescription, setAuthorDescription] = useState('');
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [selectedPhysician, setSelectedPhysician] = useState('');
  const [certifiedProfessional, setCertifiedProfessional] = useState(null);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    description: false,
  });
  const [currentPage, setCurrentPage] = useState(null);

  const userData = {
    userName: 'Admin',
  };

  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  const fetchAvailableDoctorsForAuthors = (params) => {
    setLoading(true);

    getAllDoctorsForAuthorService(params)
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            const parsedDropdownData = parseDoctorsDropdownData(
              response?.data?.results
            );

            setAvailableDoctors(parsedDropdownData);
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);

        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handlePhysicianSelection = (eventValue) => {
    if (eventValue) {
      setSelectedPhysician(eventValue);
    }
  };

  const handleCertifiedStatus = (selectedValue) => {
    if (selectedValue) {
      setCertifiedProfessional(selectedValue);
    }
  };
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'authorName') {
      setAuthorName(fieldValue);
    } else if (fieldName === 'authorEmail') {
      setAuthorEmail(fieldValue);
    } else if (fieldName === 'authorDescription') {
      setAuthorDescription(fieldValue);
    }
  };

  const handleMapPhysicianToAuthor = () => {
    setLoading(true);

    if (selectedPhysician) {
      mapDoctorToAuthorService(selectedPhysician)
        .then((response) => {
          setLoading(false);

          addToast('Author has been created successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // TODO: Handle Response Using Response Code
          history.push('/authors', { page_current: currentPage });
        })
        .catch((errorData) => {
          setLoading(false);

          addToast(errorData.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const validateFormData = () => {
    let isFormValid = true;

    let tempErr = { name: false, email: false, description: false };
    setErrors({
      ...errors,
      ...tempErr,
    });
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    if (!file || !image) {
      addToast('Author photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorName) {
      tempErr['name'] = true;
      addToast('Author name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    //  else if (!authorEmail) {
    //   tempErr['email']= true;
    //   addToast('Author email is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });

    //   isFormValid = false;
    // }
    else if (authorEmail !== '' && !emailRegex.test(authorEmail)) {
      tempErr['email'] = true;
      addToast('Author email should be a valid email.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!authorDescription) {
      tempErr['description'] = true;
      addToast('Author Description is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    setErrors({
      ...errors,
      ...tempErr,
    });

    return isFormValid;
  };

  const handleCreateNewAuthor = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      uploadImage().then((responseDataImage) => {
        if (responseDataImage) {
          if (responseDataImage.code === 200) {
            const dataPayload = {
              name: authorName,
              role_id: 3,
              about: authorDescription,
              image_id: responseDataImage.data?.id,
              is_certified_professional: certifiedProfessional,
            };
            if (authorEmail !== '') {
              dataPayload.email = authorEmail;
            }

            addNewAuthorService(dataPayload)
              .then((responseData) => {
                setLoading(false);

                if (responseData) {
                  if (responseData.code === 200) {
                    addToast('Author has been created successfully.', {
                      appearance: 'success',
                      autoDismiss: true,
                    });

                    history.push('/authors', { page_current: currentPage });
                  }
                }
              })
              .catch((err) => {
                setLoading(false);
                const errorData = err.response?.data?.errors;
                if (errorData && Object.keys(errorData).length > 0) {
                  for (let key in errorData) {
                    for (let e of errorData[key]) {
                      addToast(e, {
                        appearance: 'error',
                        autoDismiss: true,
                      });
                      setLoading(false);
                    }
                  }
                } else {
                  setLoading(false);
                  addToast(err.response?.data?.message, {
                    appearance: 'error',
                    autoDismiss: true,
                  });
                }
              });
          } else {
            setLoading(false);
          }
        }
      });
    }
  };

  useEffect(() => {
    const params = { role: 'doctor', notAuthor: true, page: 1, limit: 100 };

    fetchAvailableDoctorsForAuthors(params);
  }, []); // eslint-disable-line

  return (
    <div className='createauthor-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Add Author' />

        <div className='createauthor-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={selectedPhysician}
                  options={availableDoctors}
                  label={'Choose a physician'}
                  placeholder='Choose a Physician'
                  handleChange={handlePhysicianSelection}
                />
              </div>
            </div>

            {/* <hr /> */}
            {!selectedPhysician && (
              <>
                <div className='input-replicator-title mt-3 mb-5'>
                  -----OR-----
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <div className='input-wrapper-display'>
                      <img
                        src={image ? image : Placeholder}
                        className='display-image'
                        alt='Uploaded File'
                      />
                    </div>

                    <Input
                      type='file'
                      name='authorPhoto'
                      handleChange={handleImageSelection}
                      required='yes'
                      label='Upload Photo (1:1 Circular Corners)'
                      accept='.jpg, .png, .jpeg, .webp'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <Input
                      name='authorName'
                      value={authorName}
                      placeholder='Enter Text'
                      handleChange={handleFieldUpdate}
                      label='Author Name'
                      required='yes'
                      // className = {(errors && errors.name) ? 'input-field input-error':'input-field'}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <Input
                      type='email'
                      name='authorEmail'
                      value={authorEmail}
                      placeholder='Enter Text'
                      handleChange={handleFieldUpdate}
                      label='Author Email'
                      // className = {(errors && errors.email) ? 'input-field input-error':'input-field'}
                    />
                  </div>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <DropdownSelect
                      value={certifiedProfessional}
                      options={certifiedOptions}
                      label={'Certified healthcare professional?'}
                      placeholder='Choose a Status'
                      handleChange={handleCertifiedStatus}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <TextArea
                      name='authorDescription'
                      value={authorDescription}
                      placeholder='Enter Text'
                      handleChange={handleFieldUpdate}
                      label='Author Description'
                      required='yes'
                      // className = {(errors && errors.description) ? 'input-field input-error':'input-field'}
                    />
                  </div>
                </div>
              </>
            )}

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  handleClick={() => {
                    if (selectedPhysician) {
                      handleMapPhysicianToAuthor();
                    } else {
                      handleCreateNewAuthor();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateAuthor;
