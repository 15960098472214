import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  getSingleSymptomDetailsService,
  updateSymptomDetailsService,
  updateSymptomStatusService,
} from '../../services/symptomsService';
import { uploadImageService } from '../../services/uploadService';
import { cloneDeep } from 'lodash';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import MultiDropdownSelect from '../../components/MultiDropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';
import './styles.scss';
import SecondaryButton from '../../components/SecondaryButton';
import { getAllClinicsService } from '../../services/clinicsService';

const EditSymptoms = (props) => {
  const history = useHistory();
  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [symptomName, setSymptomName] = useState('');
  const [symptomStatus, setSymptomStatus] = useState('');
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedClinicOptions, updateSelectedClinicOptions] = useState([]);
  const [symptomIdentifier, setSymptomIdentifier] = useState('');
  const [hasFileModified, setFileModified] = useState(false);
  const [symptomImageIdentifier, setSymptomImageIdentifier] = useState('');
  const [currentPage, setCurrentPage] = useState(null);
  const [description, setDescription] = useState('');

  const userData = {
    userName: 'Admin',
  };

  //paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  useEffect(() => {
    const symptomID = props?.match?.params?.editSymptomIdentifier;
    setSymptomIdentifier(symptomID);
  }, []);

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (symptomIdentifier && symptomIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchPrefillData();
    }
  }, [symptomIdentifier]);

  const fetchPrefillData = () => {
    setLoading(true);

    getSingleSymptomDetailsService(symptomIdentifier)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const { data } = response;
          let tempArr = [];
          if (data?.clinics) {
            data?.clinics.map((item) => {
              tempArr.push(item.id);
            });
          }
          setImage(response.data?.image?.url);
          setSymptomImageIdentifier(response.data?.image?.id);
          setSymptomName(data?.name);
          setDescription(data?.description);
          setSymptomStatus(data?.status);
          updateSelectedClinicOptions(tempArr);
        }
      })
      .catch((err) => {
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });

        setLoading(false);
      });
  };

  const fetchClinicData = (params) => {
    setLoading(true);

    getAllClinicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          setClinicOptions(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const clinicParams = { page: 1, limit: 100 };

    fetchClinicData(clinicParams);
  }, []); // eslint-disable-line

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'symptomName') {
      setSymptomName(fieldValue);
    }
    if (fieldName === 'description') {
      setDescription(fieldValue);
    }
  };

  const handleClinicSelection = (obj) => {
    const index = selectedClinicOptions.findIndex((el) => el === obj.id);
    const tempArr = cloneDeep(selectedClinicOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedClinicOptions(tempArr);
  };

  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));

      setFileModified(true);
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const validateFormSubmission = () => {
    let isFormValid = true;

    if ((!file || !image) && hasFileModified) {
      addToast('Symptom photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!symptomName) {
      addToast('Symptom Name is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    return isFormValid;
  };

  //Submit Information
  const submitSymptomDetails = (dataPayload) => {
    updateSymptomDetailsService(symptomIdentifier, dataPayload)
      .then((responseData) => {
        if (responseData.code === 200 || responseData.code === 204) {
          addToast('Symptom Updated successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // history.push('/symptoms');
        }
        history.push('/symptoms', { page_current: currentPage });
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Update Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/symptoms');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

          setLoading(false);
        }
      });
  };

  const handleUpdateSymptomDetails = () => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);
      if (hasFileModified) {
        uploadImage()
          .then((imageResponseData) => {
            if (imageResponseData) {
              if (imageResponseData.code === 200) {
                const dataPayload = {
                  image_id: imageResponseData.data?.id,
                  name: symptomName,
                  description: description,
                  status: symptomStatus,
                  clinics: selectedClinicOptions,
                };

                submitSymptomDetails(dataPayload);
              }
            }
          })
          .catch((uploadErrorData) => {
            setLoading(false);
            addToast('Could not Upload Image', {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      } else {
        const dataPayload = {
          image_id: symptomImageIdentifier,
          name: symptomName,
          status: symptomStatus,
          description: description,
          clinics: selectedClinicOptions,
        };

        submitSymptomDetails(dataPayload);
      }
    } else {
      setLoading(false);
    }
  };

  const handleDeleteSymptom = (status, identifier) => {
    let data = { status: '' };
    if (status === 'ACTIVE') {
      data.status = 'INACTIVE';
      // setSymptomStatus('INACTIVE')
    } else if (status === 'INACTIVE') {
      data.status = 'ACTIVE';
      // setSymptomStatus('ACTIVE')
    }
    const symptomIdIdentifier = identifier;
    if (symptomIdIdentifier) {
      updateSymptomStatusService(symptomIdIdentifier, data)
        .then((response) => {
          if (response.code === 200) {
            setLoading(false);

            addToast('Symptom status has been changed successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            // TODO: Handle Response Using Response Code
            history.push('/symptoms');
          }
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  return (
    <div className='symptom-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader
          title='Edit Symptom Details'
          // subtitle={ (symptomName && `Name: ${symptomName}`) || 'Name: Untitled Symptom'}
        />

        <div className='symptom-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='symptomName'
                  value={symptomName}
                  label={'Symptom Name'}
                  placeholder='Title of the post'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='description'
                  value={description}
                  placeholder='Description'
                  handleChange={handleFieldUpdate}
                  label='Description'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={clinicOptions}
                  label={'Choose Clinics'}
                  placeholder='Choose contents'
                  handleChange={handleClinicSelection}
                  // required='yes'
                  selectedList={selectedClinicOptions}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleUpdateSymptomDetails}
                />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value={
                    symptomStatus === 'ACTIVE' ? 'MAKE INACTIVE' : 'MAKE ACTIVE'
                  }
                  className={
                    symptomStatus === 'ACTIVE'
                      ? 'danger-button'
                      : 'success-button'
                  }
                  handleClick={() =>
                    handleDeleteSymptom(symptomStatus, symptomIdentifier)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditSymptoms;
