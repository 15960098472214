import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import {
  getSingleDoctorDetailsService,
  updatePhysicianDetailsService,
  deletePhysicianService,
} from '../../services/doctorsService';
import { getAllSpecializationsService } from '../../services/specializationsService';
import { parseTableData } from './parser';
import { cloneDeep } from 'lodash';
import Spinner from '../../components/Spinner';

import MultiDropdownSelect from '../../components/MultiDropdownSelect';
import { getAllClinicsService } from '../../services/clinicsService';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import TextArea from '../../components/TextArea';
import Checkbox from '../../components/Checkbox';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';
import SecondaryButton from '../../components/SecondaryButton';
import DayComponent from '../../components/DayComponent';
import Placeholder from '../../assets/images/placeholder.webp';
import CountryPicker from '../../components/CountryPickerDropdown';
import { validatePhone } from '../../common/validateInputField';
import './styles.scss';
import TimeRangeSelector from '../../components/TimeRangeSelector';
import DateTimeRangeSelector from '../../components/DateTimeRangeSelector';

const EditPhysician = (props) => {
  const daysArr = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const history = useHistory();
  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const userData = {
    userName: 'Admin',
  };

  const { physicianIdentifier } = useParams();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [physicianName, setPhysicianName] = useState('');
  const [physicianEmail, setPhysicianEmail] = useState('');
  const [physicianMobileNumber, setPhysicianMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('91');
  const [physicianStatus, setPhysicianStatus] = useState('ACTIVE');
  const [physicianSpeciality, setPhysicianSpeciality] = useState('');
  const [physicianExperience, setPhysicianExperience] = useState('');
  const [physicianFee, setPhysicianFee] = useState('');
  const [promoPhysicianFee, setPromoPhysicianFee] = useState('');
  const [physicianConsultationDuration, setPhysicianConsultationDuration] =
    useState('');
  const [clinicOptions, setClinicOptions] = useState([]);

  const [physicianUrl, setPhysicianUrl] = useState('');
  const [physicianDescription, setPhysicianDescription] = useState('');
  const [specializationId, setSpecializationId] = useState('');
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState([]);
  const [imageIdentifier, setImageIdentifier] = useState();
  const [hasFileModified, setFileModified] = useState(false);
  const [doctorIdentifier, setDoctorIdentifier] = useState(null);
  const [physicianRank, setPhysicianRank] = useState(null);

  const [patientPhilosophy, setPatientPhilosophy] = useState('');
  const [highlights, setHighlights] = useState([{ start_year: '', title: '' }]);
  const [clinicalExperience, setClinicalExperience] = useState([
    { details: '', hospital: '', duration: '' },
  ]);
  const [physicianEducation, setPhysicianEducation] = useState([
    { degree: '', university: '', graduation_year: '' },
  ]);
  const [certificates, setCertificates] = useState([
    { information: '', registration_no: '' },
  ]);
  const [professionalExperience, setProfessionalExperience] = useState([
    { position: '', hospital: '', duration: '' },
  ]);
  const [videos, setVideos] = useState([{ url: '', title: '', leaders: '' }]);

  const [memberFlag, setMemberFlag] = useState('false');
  const [memberDiscount, setMemberDiscount] = useState('');
  const [memberFee, setMemberFee] = useState('');
  const [razorpayLink, setRazorPayLink] = useState('');
  const [offer1Link, setOffer1Link] = useState('');
  const [offer2Link, setOffer2Link] = useState('');
  const [currentPage, setCurrentPage] = useState(null);
  const [selectedClinicOptions, updateSelectedClinicOptions] = useState([]);
  const [onNewSystem, setOnNewSystem] = useState('');
  const [hasPromo, setHasPromo] = useState('');
  const [Timings, setHasTimings] = useState('');

  const [countryIsoCode, setCountryIsoCode] = useState('IN');
  const [showList, setShowList] = useState(false);
  const [error, setError] = useState({
    error: true,
    message: '',
  });

  const MemberFlagOptions = [
    {
      name: 'True',
      value: true,
    },
    {
      name: 'False',
      value: false,
    },
  ];

  const fetchClinicData = (params) => {
    setLoading(true);

    getAllClinicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          // const parsedDropdownData = parseDropdownData(response?.data?.results);
          // setClinicOptions(parsedDropdownData);
          setClinicOptions(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  useEffect(() => {
    const clinicParams = { page: 1, limit: 100 };

    fetchClinicData(clinicParams);
  }, []);

  const handleClinicSelection = (obj) => {
    const index = selectedClinicOptions.findIndex((el) => el === obj.id);

    const tempArr = cloneDeep(selectedClinicOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedClinicOptions(tempArr);
  };

  //paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };
  const fetchDoctorDetailsForPrefill = () => {
    setLoading(true);

    getSingleDoctorDetailsService(doctorIdentifier, {
      include: 'doctorDetails',
    })
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            setPhysicianName(response.data?.name);
            setImage(response.data?.image?.url);
            setImageIdentifier(response.data?.image.id);
            setPhysicianEmail(response.data?.email);
            setPhysicianMobileNumber(response.data?.mobile);
            setCountryCode(response.data?.countryCode);
            setPhysicianStatus(response?.data?.status);
            setPhysicianSpeciality(response.data?.doctorDetails?.speciality);
            setPhysicianRank(response.data?.doctorDetails?.rank);
            setPhysicianExperience(
              response.data?.doctorDetails?.experienceYears,
            );
            setPhysicianFee(response.data?.doctorDetails?.consultationAmount);
            setPromoPhysicianFee(
              response.data?.doctorDetails?.promoConsultationAmount,
            );
            setHasPromo(
              response.data?.doctorDetails?.hasPromo ? 'true' : false,
            );
            setOnNewSystem(
              response.data?.doctorDetails?.onNewSystem ? 'true' : false,
            );
            setPhysicianUrl(response.data?.doctorDetails?.calendlyUrl);
            setPhysicianEducation(response.data?.doctorDetails?.education);
            setPhysicianDescription(response.data?.about);
            setSpecializationId(
              response.data?.doctorDetails?.specialization?.id,
            );
            setSelectedAvailabilities(
              response.data?.doctorDetails?.availabilities,
            );
            //Additional Fields
            setHighlights(response.data?.doctorDetails?.highlights);
            setPatientPhilosophy(
              response.data?.doctorDetails?.patientPhilosophy,
            );

            // if(response.data?.doctorDetails?.educations?.length !==0){
            setPhysicianEducation(response.data?.doctorDetails?.educations);
            // }
            // if(response.data?.doctorDetails?.videos?.length !==0){
            setVideos(response.data?.doctorDetails?.videos);
            // }
            // if(response.data?.doctorDetails?.certificates.length !==0){
            setCertificates(response.data?.doctorDetails?.certificates);
            // }
            // if(response.data?.doctorDetails?.clinicalExperiences.length !==0){
            setClinicalExperience(
              response.data?.doctorDetails?.clinicalExperiences,
            );
            // }
            // if(response.data?.doctorDetails?.professionalExperiences.length !==0){
            setProfessionalExperience(
              response.data?.doctorDetails?.professionalExperiences,
            );
            setRazorPayLink(response.data?.doctorDetails?.paymentUrl);
            setOffer1Link(response.data?.doctorDetails?.offer1Link);
            setOffer2Link(response.data?.doctorDetails?.offer2Link);
            // }
            let tempArr = [];
            if (response?.data?.clinics) {
              response?.data?.clinics.map((item) => {
                tempArr.push(item.id);
              });
            }
            updateSelectedClinicOptions(tempArr);
            if (response.data?.doctorDetails?.consultationDuration !== null) {
              setPhysicianConsultationDuration(
                response.data?.doctorDetails?.consultationDuration,
              );
            } else {
              setPhysicianConsultationDuration('');
            }
            if (response.data?.email !== null) {
              setPhysicianEmail(response.data?.email);
            } else {
              setPhysicianEmail('');
            }
            if (response.data?.mobile !== null) {
              setPhysicianMobileNumber(response.data?.mobile);
            } else {
              setPhysicianMobileNumber('');
            }
            if (response.data?.countryCode !== null) {
              setCountryCode(response.data?.countryCode);
            } else {
              setCountryCode('');
            }
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);

        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  useEffect(() => {
    setLoading(true);
    const capturedIdentifier = props?.match?.params?.physicianIdentifier;
    setDoctorIdentifier(capturedIdentifier);
  }, []); // eslint-disable-line

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (doctorIdentifier && doctorIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchDoctorDetailsForPrefill();
    }
  }, [doctorIdentifier]); // eslint-disable-line

  //Get dropdown values
  const getAllSpecializations = (params) => {
    setLoading(true);
    getAllSpecializationsService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(response?.data?.results);
          setSpecializationOptions(parsedTableData);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const params = { page: 1, limit: 100 };
    getAllSpecializations(params);
  }, []); // eslint-disable-line

  //File Input
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));

      setFileModified(true);
    }
  };

  //Upload Image
  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file, 512, 512, 80)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  //DropDown
  const handleSpecializationSelection = (eventValue) => {
    if (eventValue) {
      setSpecializationId(eventValue);
    }
  };

  const validate = (code, number) => {
    let codeValue = code.substring(1);
    let err = validatePhone(codeValue, number);
    if (err && err.error) {
      setError({ ...err });
      return err.error;
    } else {
      setError({ ...err });
      return err.error;
    }
  };
  //Fields
  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'physicianName') {
      setPhysicianName(fieldValue);
    } else if (fieldName === 'physicianEmail') {
      setPhysicianEmail(fieldValue);
    } else if (fieldName === 'physicianMobileNumber') {
      setCountryCode('91');
      setPhysicianMobileNumber(fieldValue);
    } else if (fieldName === 'physicianSpeciality') {
      setPhysicianSpeciality(fieldValue);
    } else if (fieldName === 'physicianRank') {
      setPhysicianRank(fieldValue);
    } else if (fieldName === 'physicianExperience') {
      const numberRegex = /^\d+$/;
      if (numberRegex.test(fieldValue) || fieldValue === '') {
        setPhysicianExperience(fieldValue);
      }
    } else if (fieldName === 'physicianFee') {
      const numberRegex = /^\d+$/;
      if (numberRegex.test(fieldValue) || fieldValue === '') {
        setPhysicianFee(fieldValue);
      }
    } else if (fieldName === 'promoPhysicianFee') {
      const numberRegex = /^\d+$/;
      if (numberRegex.test(fieldValue) || fieldValue === '') {
        setPromoPhysicianFee(fieldValue);
      }
    } else if (fieldName === 'physicianConsultationDuration') {
      const numberRegex = /^\d+$/;
      if (numberRegex.test(fieldValue) || fieldValue === '') {
        setPhysicianConsultationDuration(fieldValue);
      }
    } else if (fieldName === 'physicianBookingURL') {
      setPhysicianUrl(fieldValue);
    } else if (fieldName === 'physicianEducation') {
      setPhysicianEducation(fieldValue);
    } else if (fieldName === 'physicianDescription') {
      setPhysicianDescription(fieldValue);
    } else if (fieldName === 'physicianPatientPhilisophy') {
      setPatientPhilosophy(fieldValue);
    } else if (fieldName === 'memberDiscount') {
      setMemberDiscount(fieldValue);
    } else if (fieldName === 'memberFee') {
      setMemberFee(fieldValue);
    } else if (fieldName === 'physicianRazorPayLink') {
      setRazorPayLink(fieldValue);
    } else if (fieldName === 'offer1Link') {
      setOffer1Link(fieldValue);
    } else if (fieldName === 'offer2Link') {
      setOffer2Link(fieldValue);
    }
  };

  const handleHasPromo = (selectedValue) => {
    if (selectedValue) {
      setHasPromo(selectedValue);
    }
  };

  const handleonNewSystem = (selectedValue) => {
    if (selectedValue) {
      console.log(selectedValue);
      setOnNewSystem(selectedValue);
    }
  };

  //CHECKBOX
  const handleAvailabilitySelection = (fieldName, fieldValue) => {
    const tempArr = cloneDeep(selectedAvailabilities);

    const index = tempArr.findIndex((eachObj) =>
      eachObj.hasOwnProperty(fieldName),
    );

    if (index === -1) {
      let obj = {};
      obj[fieldName] = '';
      tempArr.push(obj);
    } else {
      tempArr.splice(index, 1);
    }
    setSelectedAvailabilities(tempArr);
  };

  const handleAvailabilityFieldUpdate = (fieldName, fieldValue) => {
    const tempArr = cloneDeep(selectedAvailabilities);

    const index = tempArr.findIndex((eachObj) =>
      eachObj.hasOwnProperty(fieldName),
    );

    if (index !== -1) {
      tempArr[index][fieldName] = fieldValue;
    }
    setSelectedAvailabilities(tempArr);
  };

  // validate
  const validateFormData = () => {
    let isFormValid = true;
    const numberFloatRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    if (!image) {
      addToast('Physician photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianName) {
      addToast('Physician name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!razorpayLink || razorpayLink === '') {
      addToast('RazorPay link is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (
      memberFlag === 'true' &&
      !numberFloatRegex.test(memberDiscount)
    ) {
      addToast('Member Discount should be a valid number', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (parseInt(memberDiscount) > 90) {
      addToast('Member Discount cannot be more than 90', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (parseInt(memberDiscount) < 0) {
      addToast('Member Discount cannot be negative', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === 'true' && !numberFloatRegex.test(memberFee)) {
      addToast('Member Fee should be a valid number', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === 'true' && parseInt(memberFee) < 0) {
      addToast('Member Fee cannot be negative', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (physicianEmail !== '' && !emailRegex.test(physicianEmail)) {
      addToast('Physician email should be a valid email.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianMobileNumber) {
      addToast('Physician mobile is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!countryCode) {
      addToast('Physician mobile is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianSpeciality) {
      addToast('Physician Speciality is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianRank) {
      addToast('Physician Rank is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianExperience) {
      addToast('Physician Experience is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianFee) {
      addToast('Physician Fee is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianUrl) {
      addToast('Physician Url is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!razorpayLink) {
      addToast('RazorPay Link is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!physicianDescription) {
      addToast('Physician Description is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else {
      const ind = selectedAvailabilities.findIndex(
        (dayObj) => !Object.values(dayObj)[0],
      );
      physicianEducation.map((eduObj, eduIndex) => {
        if (
          !eduObj['degree'].length &&
          !eduObj['graduation_year'].length &&
          !eduObj['university'].length
        ) {
          physicianEducation.splice(eduIndex, 1);
        }
      });
      highlights.map((highObj, highIndex) => {
        if (!highObj['start_year'].length && !highObj['title'].length) {
          highlights.splice(highIndex, 1);
        }
      });
      videos.map((videoObj, videoIndex) => {
        if (
          !videoObj['url'].length &&
          !videoObj['leaders'].length &&
          !videoObj['title'].length
        ) {
          videos.splice(videoIndex, 1);
        }
      });
      clinicalExperience.map((cliExpObj, cliExpIndex) => {
        if (
          !cliExpObj['details'].length &&
          !cliExpObj['duration'].length &&
          !cliExpObj['hospital'].length
        ) {
          clinicalExperience.splice(cliExpIndex, 1);
        }
      });
      professionalExperience.map((proExpObj, proObjIndex) => {
        if (
          !proExpObj['duration'].length &&
          !proExpObj['position'].length &&
          !proExpObj['hospital'].length
        ) {
          professionalExperience.splice(proObjIndex, 1);
        }
      });
      certificates.map((certiObj, certiIndex) => {
        if (
          !certiObj['information'].length &&
          !certiObj['registration_no'].length
        ) {
          certificates.splice(certiIndex, 1);
        }
      });
      // let highValid = true;
      // let eduValid = true;
      // let videoValid = true;
      // let cliExpValid = true;
      // let proExpValid = true;
      // let certiValid = true;
      // highlights.map((highObj,highIndex)=>{
      //   Object.keys(highObj).map((objKey,objKeyIndex)=>{
      //     if(highObj[objKey]===''||!highObj[objKey]){
      //       highValid=false;
      //     }
      //   });
      // })
      // physicianEducation.map((eduObj,highIndex)=>{
      //   Object.keys(eduObj).map((objKey,objKeyIndex)=>{
      //     if(eduObj[objKey]===''||!eduObj[objKey]){
      //       eduValid=false;
      //     }
      //   });
      // })
      // videos.map((videoObj,highIndex)=>{
      //   Object.keys(videoObj).map((objKey,objKeyIndex)=>{
      //     if(videoObj[objKey]===''||!videoObj[objKey]){
      //       videoValid=false;
      //     }
      //   });
      // })
      // clinicalExperience.map((clinObj,highIndex)=>{
      //   Object.keys(clinObj).map((objKey,objKeyIndex)=>{
      //     if(clinObj[objKey]===''||!clinObj[objKey]){
      //       cliExpValid=false;
      //     }
      //   });
      // })
      // professionalExperience.map((proObj,highIndex)=>{
      //   Object.keys(proObj).map((objKey,objKeyIndex)=>{
      //     if(proObj[objKey]===''||!proObj[objKey]){
      //       proExpValid=false;
      //     }
      //   });
      // })
      // certificates.map((certiObj,highIndex)=>{
      //   Object.keys(certiObj).map((objKey,objKeyIndex)=>{
      //     if(certiObj[objKey]===''||!certiObj[objKey]){
      //       certiValid=false;
      //     }
      //   });
      // })
      // if(!eduValid){
      //   addToast('Please fill all Education Details.', {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      //   isFormValid = false;
      // } else if(!highValid){
      //   addToast('Please fill all Highlights Details.', {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      //   isFormValid = false;
      // } else if(!cliExpValid){
      //   addToast('Please fill all Clinical Experience Details.', {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      //   isFormValid = false;
      // } else if(!proExpValid){
      //   addToast('Please fill all Professional Experience Details.', {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      //   isFormValid = false;
      // } else if(!certiValid){
      //   addToast('Please fill all Certifications Details.', {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      //   isFormValid = false;
      // } else if(!videoValid){
      //   addToast('Please fill all Video Details.', {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      //   isFormValid = false;
      // } else
      if (ind !== -1) {
        addToast('Physician Availabilites Time is a required field.', {
          appearance: 'error',
          autoDismiss: true,
        });
        isFormValid = false;
      }
    }

    return isFormValid;
  };

  //submit physician
  const submitPhysicianInformation = (dataPayload) => {
    updatePhysicianDetailsService(doctorIdentifier, dataPayload)
      .then((responseData) => {
        setLoading(false);

        if (responseData) {
          if (responseData.code === 200) {
            addToast('Physician has been updated successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            history.push('/physicians', { page_current: currentPage });
          }
        }
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  //update physician
  const handleUpdatePhysicianData = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      if (hasFileModified) {
        uploadImage().then((responseDataImage) => {
          if (responseDataImage) {
            if (responseDataImage.code === 200) {
              const dataPayload = {
                name: physicianName,
                role_id: 2,
                image_id: responseDataImage.data?.id,
                specialization_id: specializationId,
                speciality: physicianSpeciality,
                experience_years: physicianExperience,
                consultation_amount: physicianFee,
                promo_consultation_amount: promoPhysicianFee,
                has_promo: hasPromo === 'true' ? true : false,
                on_new_system: onNewSystem === 'true' ? true : false,
                calendly_url: physicianUrl,
                education: 'hello test',
                about: physicianDescription,
                patient_philosophy: patientPhilosophy,
                availabilities: JSON.stringify(selectedAvailabilities),
                highlights: highlights,
                clinical_experiences: clinicalExperience,
                educations: physicianEducation,
                certificates: certificates,
                professional_experiences: professionalExperience,
                videos: videos,
                payment_url: razorpayLink,
                offer1_link: offer1Link || null,
                offer2_link: offer2Link || null,
                clinics: selectedClinicOptions,
                rank: Number(physicianRank),
                mobile: physicianMobileNumber,
                countryCode: countryCode,
              };
              if (physicianConsultationDuration !== '') {
                dataPayload.consultation_duration =
                  physicianConsultationDuration;
              } else if (physicianEmail !== '') {
                dataPayload.email = physicianEmail;
              }
              console.log(dataPayload);
              submitPhysicianInformation(dataPayload);
            }
          }
        });
      } else {
        const dataPayload = {
          name: physicianName,
          role_id: 2,
          image_id: imageIdentifier,
          specialization_id: specializationId,
          speciality: physicianSpeciality,
          experience_years: physicianExperience,
          consultation_amount: physicianFee,
          promo_consultation_amount: promoPhysicianFee,
          has_promo: hasPromo === 'true' ? true : false,
          on_new_system: onNewSystem === 'true' ? true : false,
          calendly_url: physicianUrl,
          // education: ,
          about: physicianDescription,
          patient_philosophy: patientPhilosophy,
          availabilities: JSON.stringify(selectedAvailabilities),
          highlights: highlights,
          clinical_experiences: clinicalExperience,
          educations: physicianEducation,
          certificates: certificates,
          professional_experiences: professionalExperience,
          videos: videos,
          payment_url: razorpayLink,
          offer1_link: offer1Link || null,
          offer2_link: offer2Link || null,
          clinics: selectedClinicOptions,
          rank: Number(physicianRank),
          mobile: physicianMobileNumber,
          countryCode: countryCode,
        };
        if (physicianConsultationDuration !== '') {
          dataPayload.consultation_duration = physicianConsultationDuration;
        }
        if (physicianEmail !== '') {
          dataPayload.email = physicianEmail;
        }
        console.log(dataPayload);

        submitPhysicianInformation(dataPayload);
      }
    }
  };

  //Delete Physician
  const handleDeletePhysician = (idIdentifier) => {
    const physicianId = idIdentifier;
    if (physicianId) {
      deletePhysicianService(physicianId)
        .then((res) => {
          addToast('Physician has been deleted successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // TODO: Handle Response Using Response Code
          history.push('/physicians');
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  //Highlights  Fields
  const addHighlightsFields = () => {
    let tempArr = cloneDeep(highlights);
    let sampleObj = {
      start_year: '',

      title: '',
    };
    tempArr.push(sampleObj);
    setHighlights(tempArr);
  };

  const renderHighlightsFields = () => {
    let htmlValue = [];
    for (let i = 0; i < highlights.length; i++) {
      htmlValue.push(
        <div className="input-replicator-fieldset d-flex align-items-center justify-content-start">
          <Input
            name="start_year"
            placeholder={i === 0 ? 'Start Year' : ''}
            value={highlights[i].start_year}
            handleChange={(name, value, e) =>
              updateHighlightsFields(value, i, 'start_year')
            }
          />
          {/* <Input
            name="end_year"
            placeholder={i === 0 ? "End Year" : ""}
            value={highlights[i].end_year}
            handleChange={(name, value, e) =>
              updateHighlightsFields(value, i, "end_year")
            }
          /> */}
          <Input
            name="title"
            placeholder={i === 0 ? 'Title' : ''}
            value={highlights[i].title}
            handleChange={(name, value, e) =>
              updateHighlightsFields(value, i, 'title')
            }
          />
          <SecondaryButton
            value="x"
            className="danger-button"
            handleClick={() => handleHighlightsRemoveField(i)}
          />
        </div>,
      );
    }
    return htmlValue;
  };

  const handleHighlightsRemoveField = (index) => {
    setLoading(true);

    // if(highlights?.length === 1){
    //   setLoading(false);
    //   addToast('Highlights is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    // }else{
    let tempArr = cloneDeep(highlights);
    tempArr.splice(index, 1);
    setHighlights(tempArr);
    setLoading(false);
    // }
  };

  //update fields
  const updateHighlightsFields = (value, index, type) => {
    let tempArr = cloneDeep(highlights);
    tempArr[index][type] = value;
    setHighlights(tempArr);
  };

  //Clinical Experience Fields

  const addFields = () => {
    let tempArr = cloneDeep(clinicalExperience);
    let sampleObj = {
      details: '',
      hospital: '',
      duration: '',
    };
    tempArr.push(sampleObj);
    setClinicalExperience(tempArr);
  };

  const renderClinicalExperienceFileds = () => {
    let htmlValue = [];
    for (let i = 0; i < clinicalExperience.length; i++) {
      htmlValue.push(
        <div className="input-replicator-fieldset d-flex align-items-center justify-content-start">
          <Input
            name="details"
            placeholder={i === 0 ? 'Details' : ''}
            value={clinicalExperience[i].details}
            handleChange={(name, value, e) => updateValue(value, i, 'details')}
          />
          <Input
            name="hospital"
            placeholder={i === 0 ? 'Hospital' : ''}
            value={clinicalExperience[i].hospital}
            handleChange={(name, value, e) => updateValue(value, i, 'hospital')}
          />
          <Input
            name="duration"
            placeholder={i === 0 ? 'Duration' : ''}
            value={clinicalExperience[i].duration}
            handleChange={(name, value, e) => updateValue(value, i, 'duration')}
          />
          <SecondaryButton
            value="x"
            className="danger-button"
            handleClick={() => handleRemoveField(i)}
          />
        </div>,
      );
    }
    return htmlValue;
  };

  const handleRemoveField = (index) => {
    setLoading(true);

    // if(clinicalExperience?.length === 1){
    //   setLoading(false);
    //   addToast('Clinical Experience is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    // }else{
    let tempArr = cloneDeep(clinicalExperience);
    tempArr.splice(index, 1);
    setClinicalExperience(tempArr);
    setLoading(false);
    // }
  };

  //update fileds
  const updateValue = (value, index, type) => {
    let tempArr = cloneDeep(clinicalExperience);
    tempArr[index][type] = value;
    setClinicalExperience(tempArr);
  };

  //Education Fields
  const addEducationFields = () => {
    let tempArr = cloneDeep(physicianEducation);
    let sampleObj = {
      degree: '',
      university: '',
      graduation_year: '',
    };
    tempArr.push(sampleObj);
    setPhysicianEducation(tempArr);
  };

  const renderEducationFields = () => {
    let htmlValue = [];
    for (let i = 0; i < physicianEducation?.length; i++) {
      htmlValue.push(
        <div className="input-replicator-fieldset d-flex align-items-center justify-content-start">
          <Input
            name="degree"
            placeholder={i === 0 ? 'Information' : ''}
            value={physicianEducation[i].degree}
            handleChange={(name, value, e) =>
              updateEducationValue(value, i, 'degree')
            }
          />
          <Input
            name="university"
            placeholder={i === 0 ? 'University' : ''}
            value={physicianEducation[i].university}
            handleChange={(name, value, e) =>
              updateEducationValue(value, i, 'university')
            }
          />
          <Input
            name="graduation_year"
            placeholder={i === 0 ? 'Graduation_year' : ''}
            value={physicianEducation[i].graduation_year}
            handleChange={(name, value, e) =>
              updateEducationValue(value, i, 'graduation_year')
            }
          />
          <SecondaryButton
            value="x"
            className="danger-button"
            handleClick={() => handleEducationRemoveField(i)}
          />
        </div>,
      );
    }
    return htmlValue;
  };

  const handleEducationRemoveField = (index) => {
    setLoading(true);
    // if(physicianEducation?.length === 1){
    //   setLoading(false);
    //   addToast('Physician Education is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    // }else {
    let tempArr = cloneDeep(physicianEducation);
    tempArr.splice(index, 1);
    setPhysicianEducation(tempArr);

    setLoading(false);
    // }
  };

  //update fileds
  const updateEducationValue = (value, index, type) => {
    let tempArr = cloneDeep(physicianEducation);
    tempArr[index][type] = value;
    setPhysicianEducation(tempArr);
  };

  //Certificate Fields
  const addCertificateFields = () => {
    let tempArr = cloneDeep(certificates);
    let sampleObj = {
      information: '',
      registration_no: '',
    };
    tempArr.push(sampleObj);
    setCertificates(tempArr);
  };

  const renderCertificatesFields = () => {
    let htmlValue = [];
    for (let i = 0; i < certificates.length; i++) {
      htmlValue.push(
        <div className="input-replicator-fieldset d-flex align-items-center justify-content-start">
          <Input
            name="information"
            placeholder={i === 0 ? 'Information' : ''}
            value={certificates[i].information}
            handleChange={(name, value, e) =>
              updateCertificateValue(value, i, 'information')
            }
          />
          <Input
            name="registration_no"
            placeholder={i === 0 ? 'Registration_no' : ''}
            value={certificates[i].registration_no}
            handleChange={(name, value, e) =>
              updateCertificateValue(value, i, 'registration_no')
            }
          />
          <SecondaryButton
            value="x"
            className="danger-button"
            handleClick={() => handleCertificateRemoveField(i)}
          />
        </div>,
      );
    }
    return htmlValue;
  };

  const handleCertificateRemoveField = (index) => {
    // if(certificates?.length === 1){
    //   setLoading(false);
    //   addToast('Certificates is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    // }else{
    let tempArr = cloneDeep(certificates);
    tempArr.splice(index, 1);

    setCertificates(tempArr);
    setLoading(false);
    // }
  };

  //update fileds
  const updateCertificateValue = (value, index, type) => {
    let tempArr = cloneDeep(certificates);
    tempArr[index][type] = value;
    setCertificates(tempArr);
  };

  //Professional Experience Fields
  const addProfessionalExpFields = () => {
    let tempArr = cloneDeep(professionalExperience);
    let sampleObj = {
      position: '',
      hospital: '',
      duration: '',
    };
    tempArr.push(sampleObj);
    setProfessionalExperience(tempArr);
  };

  const renderProfessionalExperienceFields = () => {
    let htmlValue = [];
    for (let i = 0; i < professionalExperience.length; i++) {
      htmlValue.push(
        <div className="input-replicator-fieldset d-flex align-items-center justify-content-start">
          <Input
            name="position"
            placeholder={i === 0 ? 'Position' : ''}
            value={professionalExperience[i].position}
            handleChange={(name, value, e) =>
              updateProfessionalExpValue(value, i, 'position')
            }
          />
          <Input
            name="hospital"
            placeholder={i === 0 ? 'Hospital' : ''}
            value={professionalExperience[i].hospital}
            handleChange={(name, value, e) =>
              updateProfessionalExpValue(value, i, 'hospital')
            }
          />
          <Input
            name="duration"
            placeholder={i === 0 ? 'Duration' : ''}
            value={professionalExperience[i].duration}
            handleChange={(name, value, e) =>
              updateProfessionalExpValue(value, i, 'duration')
            }
          />
          <SecondaryButton
            value="x"
            className="danger-button"
            handleClick={() => handleProfessionalExpRemoveField(i)}
          />
        </div>,
      );
    }
    return htmlValue;
  };

  const handleProfessionalExpRemoveField = (index) => {
    // if(professionalExperience?.length === 1){
    //   setLoading(false);
    //   addToast('Professional Experience is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    // }else{

    let tempArr = cloneDeep(professionalExperience);
    tempArr.splice(index, 1);
    setProfessionalExperience(tempArr);
    setLoading(false);
    // }
  };

  //update fields
  const updateProfessionalExpValue = (value, index, type) => {
    let tempArr = cloneDeep(professionalExperience);
    tempArr[index][type] = value;
    setProfessionalExperience(tempArr);
  };

  //Videos  Fields
  const addVideosFields = () => {
    let tempArr = cloneDeep(videos);
    let sampleObj = {
      url: '',
      title: '',
      leaders: '',
    };
    tempArr.push(sampleObj);
    setVideos(tempArr);
  };

  const renderVideosFields = () => {
    let htmlValue = [];
    for (let i = 0; i < videos.length; i++) {
      htmlValue.push(
        <div className="input-replicator-fieldset d-flex align-items-center justify-content-start">
          <Input
            name="url"
            placeholder={i === 0 ? 'Url' : ''}
            value={videos[i].url}
            handleChange={(name, value, e) => updateVideoValue(value, i, 'url')}
          />
          <Input
            name="title"
            placeholder={i === 0 ? 'Title' : ''}
            value={videos[i].title}
            handleChange={(name, value, e) =>
              updateVideoValue(value, i, 'title')
            }
          />
          <Input
            name="leaders"
            placeholder={i === 0 ? 'Leaders' : ''}
            value={videos[i].leaders}
            handleChange={(name, value, e) =>
              updateVideoValue(value, i, 'leaders')
            }
          />
          <SecondaryButton
            value="x"
            className="danger-button"
            handleClick={() => handleVideoRemoveField(i)}
          />
        </div>,
      );
    }
    return htmlValue;
  };

  const handleVideoRemoveField = (index) => {
    // if(videos?.length === 1){
    //   setLoading(false);
    //   addToast('Professional Experience is a required field.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    // }else{
    let tempArr = cloneDeep(videos);
    tempArr.splice(index, 1);
    setVideos(tempArr);
    setLoading(false);
    // }
  };

  //update fields
  const updateVideoValue = (value, index, type) => {
    let tempArr = cloneDeep(videos);
    tempArr[index][type] = value;
    setVideos(tempArr);
  };

  const handleToggle = () => {
    const dataPayload = {
      name: physicianName,
      role_id: 2,
      image_id: imageIdentifier,
      specialization_id: specializationId,
      speciality: physicianSpeciality,
      experience_years: physicianExperience,
      consultation_amount: physicianFee,
      promo_consultation_amount: promoPhysicianFee,
      has_promo: hasPromo === 'true' ? true : false,
      on_new_system: onNewSystem === 'true' ? true : false,
      calendly_url: physicianUrl,
      // education: ,
      about: physicianDescription,
      patient_philosophy: patientPhilosophy,
      availabilities: JSON.stringify(selectedAvailabilities),
      highlights: highlights,
      clinical_experiences: clinicalExperience,
      educations: physicianEducation,
      certificates: certificates,
      professional_experiences: professionalExperience,
      videos: videos,
      payment_url: razorpayLink,
      offer1_link: offer1Link || null,
      offer2_link: offer2Link || null,
      clinics: selectedClinicOptions,
      status: physicianStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      rank: Number(physicianRank),
      mobile: physicianMobileNumber,
      countryCode: countryCode,
    };

    submitPhysicianInformation(dataPayload);
  };

  return (
    <div className="createphysician-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <div className="flexible-container">
        <SubHeader
          title="Edit Physician"
          // subtitle={physicianName}
        />

        <div className="createphysician-wrapper-content">
          <div className="form-section">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-wrapper-display">
                  <img
                    src={image ? image : Placeholder}
                    className="display-image"
                    alt="Uploaded File"
                  />
                </div>

                <Input
                  type="file"
                  name="physicianPhoto"
                  required="yes"
                  accept=".jpg, .png, .jpeg, .webp"
                  label="Upload Photo (1:1 Circular Corners)"
                  handleChange={handleImageSelection}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianName"
                  placeholder="Enter text"
                  required="yes"
                  label="Full Name with Salutation"
                  value={physicianName}
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianEmail"
                  type="email"
                  label="Email Address"
                  value={physicianEmail}
                  placeholder="Enter text"
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="countryCode"
                  type="text"
                  required="yes"
                  label="Enter Country Code Number"
                  value={'91'}
                  placeholder="Enter Country Code"
                  handleChange={() => {}}
                  // handleChange={handleFieldUpdate}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianMobileNumber"
                  type="tel"
                  required="yes"
                  label="Enter Mobile Number"
                  value={physicianMobileNumber}
                  placeholder="Enter Mobile Number"
                  handleChange={handleFieldUpdate}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  minLength={10}
                  maxLength={10}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  placeholder="Enter text"
                  required="yes"
                  options={specializationOptions}
                  label="Choose a Specialization"
                  value={specializationId}
                  handleChange={handleSpecializationSelection}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianSpeciality"
                  required="yes"
                  label="Speciality of the Physician"
                  placeholder="Enter text"
                  value={physicianSpeciality}
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <MultiDropdownSelect
                  options={clinicOptions}
                  label={'Choose Clinics'}
                  placeholder="Choose clinics"
                  handleChange={handleClinicSelection}
                  // required='yes'
                  selectedList={selectedClinicOptions}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianRank"
                  label={'Rank on consultation page (lower is better)'}
                  value={physicianRank}
                  type="number"
                  placeholder="Enter Rank"
                  handleChange={handleFieldUpdate}
                  required="yes"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  type="text"
                  name="physicianExperience"
                  required="yes"
                  placeholder="Enter experience"
                  label="Years of Experience (in Years)"
                  value={physicianExperience}
                  handleChange={handleFieldUpdate}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <Input
                  type="text"
                  name="physicianFee"
                  required="yes"
                  label="Consultation Fee"
                  placeholder="Enter fee"
                  value={physicianFee}
                  handleChange={handleFieldUpdate}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  type="text"
                  name="physicianConsultationDuration"
                  label="Consultation Duration (in minutes)"
                  placeholder="Enter duration"
                  value={physicianConsultationDuration}
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-4 col-md-4">
                <DropdownSelect
                  value={hasPromo}
                  name="hasPromo"
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Has Promo Offer'}
                  placeholder="Has Promo Offer"
                  handleChange={handleHasPromo}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <Input
                  type="text"
                  name="promoPhysicianFee"
                  required="yes"
                  label="Promotional Consultation Fee"
                  placeholder="Enter fee"
                  value={promoPhysicianFee}
                  handleChange={handleFieldUpdate}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianBookingURL"
                  required="yes"
                  placeholder="Enter Text"
                  label="Booking URL"
                  value={physicianUrl}
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6">
                <Input
                  name="offer1Link"
                  value={offer1Link}
                  label="Offer 1 Link"
                  placeholder="Enter link"
                  handleChange={handleFieldUpdate}
                  required="no"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6">
                <Input
                  name="offer2Link"
                  value={offer2Link}
                  label="Offer 2 Link"
                  placeholder="Enter link"
                  handleChange={handleFieldUpdate}
                  required="no"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  disabled={onNewSystem ? true : false}
                  name="physicianRazorPayLink"
                  value={razorpayLink}
                  label="Razorpay Payment Page"
                  placeholder="Enter link"
                  handleChange={handleFieldUpdate}
                  required="yes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <TextArea
                  name="physicianDescription"
                  required="yes"
                  label="Physician Description"
                  placeholder="Enter Text"
                  value={physicianDescription}
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <TextArea
                  name="physicianPatientPhilisophy"
                  label="Patient philosophy"
                  placeholder="Enter Text"
                  handleChange={handleFieldUpdate}
                  value={patientPhilosophy}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 ">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">
                    Education
                  </span>
                  {renderEducationFields()}
                  <SecondaryButton
                    value="Add More"
                    handleClick={addEducationFields}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 ">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">
                    Highlights
                  </span>
                  {renderHighlightsFields()}
                  <SecondaryButton
                    value="Add More"
                    handleClick={addHighlightsFields}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 ">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">
                    Professional Experience
                  </span>
                  {renderProfessionalExperienceFields()}
                  <SecondaryButton
                    value="Add More"
                    handleClick={addProfessionalExpFields}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 ">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">
                    Clinical Experience
                  </span>
                  {renderClinicalExperienceFileds()}
                  <SecondaryButton value="Add More" handleClick={addFields} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">
                    Certificates
                  </span>
                  {renderCertificatesFields()}
                  <SecondaryButton
                    value="Add More"
                    handleClick={addCertificateFields}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">Videos</span>
                  <p className="input-replicator-example">
                    (To add a video link, please open the video on youtube,
                    click on SHARE, then click on EMBED, then pick the URL after
                    src that is shown in the displayed code. A URL should have a
                    URL similar to https://www.youtube.com/embed/0uQwp2qn9cQ)
                  </p>
                  {renderVideosFields()}
                  <SecondaryButton
                    value="Add More"
                    handleClick={addVideosFields}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="input-replicator">
                  <span className="input-replicator-label d-block">
                    Availabilities <span className="text-danger">*</span>
                  </span>

                  {daysArr.map((daysItem, index) => {
                    return (
                      <div
                        className="input-replicator-fieldset d-flex align-items-center justify-content-start"
                        key={index}
                      >
                        <Checkbox
                          name={`${daysItem}`}
                          label={`${daysItem}`}
                          handleChange={handleAvailabilitySelection}
                          availabilityValues={selectedAvailabilities}
                        />
                        <Input
                          placeholder="Time"
                          name={`${daysItem}`}
                          handleChange={handleAvailabilityFieldUpdate}
                          availabilityValues={selectedAvailabilities}
                          // value={}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <TimeRangeSelector
              minuteIncrement={15}
              physician={doctorIdentifier}
              doctorDuration={physicianConsultationDuration}
            />

            {/* <div className='row'>
              <div className='col-12 col-sm-6'>
                <div className='input-replicator'>
                  <span className='input-replicator-label d-block'>
                    Availabilities (Scheduling System)
                    <span className='text-danger'>*</span>
                  </span>

                  {daysArr.map((daysItem, index) => {
                    return (
                      <DayComponent
                        day={daysItem}
                        index={index}
                        physicianIdentifier={physicianIdentifier}
                      />
                    );
                  })}
                </div>
              </div>
            </div> */}
            <div className='mb-2 input-replicator-label'>Unavailabilities</div>
            <DateTimeRangeSelector
              physician={doctorIdentifier}
              type={'doctor'}
            />
            <DropdownSelect
              value={onNewSystem}
              name="onNewSystem"
              options={[
                {
                  name: 'True',
                  value: true,
                },
                {
                  name: 'False',
                  value: false,
                },
              ]}
              label={'On New System'}
              placeholder="On New System"
              handleChange={handleonNewSystem}
              required="yes"
            />

            <div className="row">
              <div className="col-12 col-sm-6 ">
                <PrimaryButton
                  value="Save"
                  handleClick={() => handleUpdatePhysicianData()}
                />
              </div>

              <div className="col-12 col-sm-6 text-right">
                {/* <SecondaryButton
                  value='Delete Physician'
                  className='danger-button'
                  handleClick={() => handleDeletePhysician(doctorIdentifier)}
                /> */}

                <SecondaryButton
                  value={
                    physicianStatus === 'ACTIVE'
                      ? 'MAKE INACTIVE'
                      : 'MAKE ACTIVE'
                  }
                  className={
                    physicianStatus === 'ACTIVE'
                      ? 'danger-button'
                      : 'success-button'
                  }
                  handleClick={handleToggle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default EditPhysician;
