import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { parseDropdownData } from './parser';
import {
  getSingleUserDetailsService,
  updateUserDetailsService,
  updateUserStatusService,
} from '../../services/userService';
import { getAllCompaniesService } from '../../services/companyService';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { uploadImageService } from '../../services/uploadService';
import { getMembership } from '../../services/membershipService';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';
import SecondaryButton from '../../components/SecondaryButton';
import MultiDropdownSelect from '../../components/MultiDropdownSelect';
import DateTimeInput from '../../components/DateTimeInput';

import Placeholder from '../../assets/images/placeholder.webp';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import './styles.scss';
import { data } from 'jquery';

const userData = {
  userName: 'Admin',
};

const statusOptions = [
  {
    name: 'Activate',
    value: 'ACTIVE',
  },
  {
    name: 'Deactivate',
    value: 'INACTIVE',
  },
];

const MemberFlagOptions = [
  {
    name: 'True',
    value: 1,
  },
  {
    name: 'False',
    value: 0,
  },
];

const EmailVerifiedOption = [
  {
    name: 'True',
    value: 1,
  },
  {
    name: 'False',
    value: 0,
  },
];
const EditUser = (props) => {
  const history = useHistory();
  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [hasFileModified, setFileModified] = useState(false);
  const [userImageIdentifier, setUserImageIdentifier] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userIdentifier, setUserIdentifier] = useState('');
  const [aocOptions, setAocOptions] = useState([]);
  const [selectedAocOptions, updateSelectedAocOptions] = useState([]);
  const [areaOfContentId, setAreaOfContentId] = useState('');
  const [companyOptions, setCompanyOptions] = useState('');
  const [companyId, setCompanyId] = useState('');

  const [memberFlag, setMemberFlag] = useState('');

  const [emailVerified, setEmailVerified] = useState('');
  const [membershipEndDate, setMembershipEndDate] = useState('');
  const [membershipDaysLeft, setMembershipDaysLeft] = useState('');
  const [numberVal, setNumberVal] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [memberShipData, setMemberShipData] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [numberOfAppointments, setNumberOfAppointments] = useState(null);

  //paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };
  const handleEmailVerifiedSelection = (selectedValue) => {
    if (selectedValue) {
      setEmailVerified(selectedValue);
    }
  };

  const fetchUsersDetailsForPrefill = () => {
    setLoading(true);

    getSingleUserDetailsService(userIdentifier)
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            let tempArr = [];
            setCustomerDetails(response.data);
            if (response.data?.aocs) {
              response.data?.aocs.map((item) => {
                tempArr.push(item.id);
              });
            }

            const tempNumber =
              '+' + response.data?.countryCode + '-' + response.data?.mobile;
            setImage(response.data?.image?.url);
            setUserImageIdentifier(response.data?.image?.id);
            setFirstName(response.data?.firstName);
            setLastName(response.data?.lastName);
            setUserStatus(response.data?.status);
            setUserEmail(response.data?.email);
            setUserPhoneNumber(tempNumber);
            setCompanyId(response.data?.company?.id);
            setMembershipDaysLeft(response?.data?.membershipDetails?.daysLeft);
            // setMembershipEndDate(response?.data?.membershipDetails?.endDate);
            setEndDate(moment(response.data?.membershipEndDate));
            setStartDate(moment(response.data?.membershipStartDate));
            if (response.data?.isEmailVerified !== null) {
              setMemberFlag(response.data?.isPremiumMember);
            } else {
              setMemberFlag(0);
            }
            if (response.data?.isEmailVerified !== null) {
              setEmailVerified(response.data?.isEmailVerified);
            } else {
              setEmailVerified(0);
            }
            setAreaOfContentId(response.data?.areaOfContentId);
            setAocOptions(response.data?.company?.aocs);
            updateSelectedAocOptions(tempArr);
            setNumberOfAppointments(response.data?.numberOfCCAppointments);
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  //Companies Data
  const fetchCompanyData = (params) => {
    setLoading(true);
    getAllCompaniesService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);
          setCompanyOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  //Image
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));

      setFileModified(true);
    }
  };

  //MemberShip Data
  const fetchMemberShipDetails = () => {
    getMembership()
      .then((res) => {
        if (res.code == 200 || res.code === 201) {
          setMemberShipData(res.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  //number
  useEffect(() => {
    if (userPhoneNumber) {
      const number = userPhoneNumber.split('-');
      setNumberVal(number[1]);
    }
  }, [userPhoneNumber]);

  const handleAocSelection = (obj) => {
    const index = selectedAocOptions.findIndex((el) => el === obj.id);
    const tempArr = cloneDeep(selectedAocOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedAocOptions(tempArr);
  };

  //handleDate
  const handleScheduleChange = (value) => {
    setEndDate(value);
  };

  //Upload Image
  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file, 512, 512, 80)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'firstName') {
      setFirstName(fieldValue);
    } else if (fieldName === 'lastName') {
      setLastName(fieldValue);
    } else if (fieldName === 'userEmail') {
      setUserEmail(fieldValue);
    } else if (fieldName === 'userPhoneNumber') {
      setUserPhoneNumber(fieldValue);
    } else if (fieldName === 'appointments') {
      setNumberOfAppointments(fieldValue);
    }
  };

  const handleCompanySelection = (eventValue) => {
    if (eventValue) {
      setCompanyId(eventValue);
    }
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setUserStatus(eventValue);
    }
  };

  const validateFormData = () => {
    let isFormValid = true;

    const nameRegex = /^[a-zA-Z ]*$/;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    const numberRegex = /^[4-9][0-9]{9}$/g;

    if (!firstName) {
      addToast('First name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!lastName) {
      addToast('Last Name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });
      isFormValid = false;
    } else if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      addToast('User name must not contain numbers.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!userEmail) {
      addToast('User email is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });
      isFormValid = false;
    } else if (!emailRegex.test(userEmail)) {
      addToast('User email should be a valid email.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (numberVal && !numberRegex.test(numberVal)) {
      addToast('User Phone Number should be a valid number.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!userStatus) {
      addToast('User Status is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  const submitUserInformation = (dataPayload) => {
    updateUserDetailsService(userIdentifier, dataPayload)
      .then((responseData) => {
        setLoading(false);

        if (responseData) {
          if (responseData.code === 200) {
            addToast('User has been updated successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            history.push(`/customers/${companyId}`, {
              page_current: currentPage,
            });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push(`/customers/${companyId}`, {
            page_current: currentPage,
          });
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
        }
      });
  };

  const handleUpdateUserData = () => {
    const isFormValid = validateFormData();
    if (isFormValid) {
      setLoading(true);
      if (hasFileModified) {
        uploadImage()
          .then((imageResponseData) => {
            if (imageResponseData) {
              if (imageResponseData.code === 200) {
                const dataPayload = {
                  image_id: imageResponseData.data?.id,
                  first_name: firstName,
                  last_name: lastName,
                  email: userEmail,
                  status: userStatus,
                  mobile: numberVal,
                  company_id: companyId,
                  aocs: selectedAocOptions,
                  membership_end_date:
                    memberFlag === '1' || memberFlag === 1
                      ? endDate
                      : undefined,
                  is_premium_member: parseInt(memberFlag),
                  number_of_cc_appointments: numberOfAppointments,
                };
                // if (memberFlag === '1') {
                //   dataPayload.membership_end_date = endDate;
                // }

                submitUserInformation(dataPayload);
              } else {
                setLoading(false);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            addToast('Could not Upload Image', {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      } else {
        const dataPayload = {
          image_id: userImageIdentifier,
          first_name: firstName,
          last_name: lastName,
          email: userEmail,
          status: userStatus,
          mobile: numberVal,
          company_id: companyId,
          aocs: selectedAocOptions,
          is_premium_member: parseInt(memberFlag),
          membership_end_date:
            memberFlag === '1' || memberFlag === 1 ? endDate : undefined,
          number_of_cc_appointments: Number(numberOfAppointments),
        };
        // if (memberFlag === '1') {
        //   dataPayload.membership_end_date = endDate;
        // }
        submitUserInformation(dataPayload);
      }
    } else {
      setLoading(false);
    }
  };

  const handleUserStatus = () => {};

  // Acting ComponentDidMount
  useEffect(() => {
    setLoading(true);
    const capturedIdentifier = props?.match?.params?.userIdentifier;
    const companyParams = {
      page: 1,
      limit: 100,
      role: 'company',
      getDefault: true,
    };
    const aocParams = { page: 1, limit: 100 };

    setUserIdentifier(capturedIdentifier);
    // fetchAocData(aocParams);
    fetchCompanyData(companyParams);
    fetchMemberShipDetails();
  }, []); // eslint-disable-line

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (userIdentifier && userIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchUsersDetailsForPrefill();
    }
  }, [userIdentifier]);

  const handleDeleteCompany = (status, identifier) => {
    let data = { status: '' };
    if (status === 'ACTIVE') {
      data.status = 'INACTIVE';
      // setCompanyStatus('INACTIVE')
    } else if (status === 'INACTIVE') {
      data.status = 'ACTIVE';
      // setCompanyStatus('ACTIVE')
    }
    const userIdentifier = identifier;
    if (userIdentifier) {
      updateUserStatusService(userIdentifier, data)
        .then((response) => {
          if (response.code === 200) {
            setLoading(false);

            addToast('User status has been changed successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            // TODO: Handle Response Using Response Code
            history.push(`/customers/${companyId}`, {
              page_current: currentPage,
            });
          }
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  useEffect(() => {
    if (memberFlag === '1') {
      if (customerDetails?.isPremiumMember === 0) {
        setEndDate(
          moment(new Date()).add(memberShipData?.membershipDuration, 'days')
        );
      }
    } else {
      if (customerDetails?.isPremiumMember === 0) {
        setEndDate(moment(new Date()));
      }
    }
  }, [memberFlag]);

  return (
    <div className='editUser-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit User' />

        <div className='editUser-wrapper-content'>
          {/* {memberFlag ? (
            <div className='editUser-wrapper-membership-content'>
              <div className='row'>
                <div className='col-sm-6'>
                  Membership End date:
                  <b> {moment(membershipEndDate).format('DD/MM/YYYY')}</b>
                </div>
                <div className='col-sm-6'>
                  Membership days left:<b> {membershipDaysLeft}</b>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )} */}

          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  handleChange={handleImageSelection}
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='firstName'
                  value={firstName}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  label='First Name'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='lastName'
                  value={lastName}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  label='Last Name'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='email'
                  name='userEmail'
                  value={userEmail}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  label='User Email'
                  disabled={true}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={memberFlag}
                  options={MemberFlagOptions}
                  label={'Is A Member ?'}
                  placeholder='Choose Member Status'
                  handleChange={handleMemberFlagSelection}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='tel'
                  name='userPhoneNumber'
                  value={userPhoneNumber}
                  placeholder='Enter Number'
                  handleChange={handleFieldUpdate}
                  label='User Phone Number'
                  required='yes'
                  disabled={true}
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={emailVerified}
                  options={EmailVerifiedOption}
                  label={'Is Email Verified ?'}
                  placeholder='Choose Option'
                  handleChange={handleEmailVerifiedSelection}
                  disabled={true}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DateTimeInput
                  label='Membership Start Date'
                  timeFormat={false}
                  dateFormat={'DD/MM/YYYY'}
                  onChange={handleScheduleChange}
                  name='supportGroupDate'
                  value={startDate}
                  disabled={true}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <DateTimeInput
                  label='Membership End Date'
                  timeFormat={false}
                  dateFormat={'DD/MM/YYYY'}
                  onChange={handleScheduleChange}
                  name='supportGroupDate'
                  value={endDate}
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='text'
                  name='Membership days left'
                  value={membershipDaysLeft}
                  placeholder='Membership days left'
                  handleChange={handleFieldUpdate}
                  label='Membership days left:'
                  disabled={true}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={aocOptions}
                  label={'Choose Area Of Contents'}
                  placeholder='Choose contents'
                  handleChange={handleAocSelection}
                  // required='yes'
                  selectedList={selectedAocOptions}
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select a Company'
                  label={'Select a Company'}
                  value={companyId}
                  options={companyOptions}
                  // options={companyOptions}
                  handleChange={handleCompanySelection}
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='number'
                  name='appointments'
                  value={numberOfAppointments}
                  handleChange={handleFieldUpdate}
                  placeholder='Enter number'
                  label='Number of appointments:'
                />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  value={userStatus}
                  options={statusOptions}
                  label={"User Status"}
                  placeholder="Choose a Status"
                  required="yes"
                  handleChange={handleStatusSelection}
                />
              </div>
            </div> */}

            <div className='row justify-content-between'>
              <div className='col-12 col-sm-6 col-md-6'>
                <PrimaryButton
                  value='Save'
                  handleClick={() => handleUpdateUserData()}
                />
              </div>
              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value={userStatus === 'ACTIVE' ? 'Deactivate' : 'Activate'}
                  className={
                    userStatus === 'ACTIVE' ? 'danger-button' : 'success-button'
                  }
                  handleClick={() =>
                    handleDeleteCompany(userStatus, userIdentifier)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditUser;
