import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import Spinner from '../../components/Spinner';
import EmptyState from '../../components/EmptyState';
import { getAllDoctorAction } from '../../services/doctorsService';

import './styles.scss';
import Search from '../../components/Search';

const PhysicianListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [physicians, setPhysicians] = useState([]);
  const [search, setSearch] = useState('');

  const columnHeaders = [
    'S. No.',
    'Name',
    'Specialization',
    'Speciality',
    'Experience',
    'Consultation Fee',
    'Created',
    'Status',
    'Action',
  ];

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selected) => {
    const physicianId = selected.id;
    history.push(`/physicians/edit/${physicianId}`, { page_number: page });
  };

  const getAllDoctors = (params) => {
    setLoading(true);
    getAllDoctorAction(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setPhysicians(parsedTableData);
          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const handlePageNumber = (pageNumber) => {
    setPage(pageNumber);
    getAllDoctors({
      role: 'doctor',
      include: 'doctorDetails,doctorDetails.specialization',
      page: pageNumber,
      sortBy: 'DATE',
    });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setPage(props.location?.state?.page_current);
      const params = {
        role: 'doctor',
        include: 'doctorDetails,doctorDetails.specialization',
        page: props.location?.state?.page_current,
        sortBy: 'DATE',
      };
      getAllDoctors(params);
      props.location.state.page_current = null;
    } else {
      const params = {
        role: 'doctor',
        include: 'doctorDetails,doctorDetails.specialization',
        page,
        sortBy: 'DATE',
      };
      getAllDoctors(params);
    }
  }, []); // eslint-disable-line

  const handleAddDoctorCta = () => {
    history.push('/physicians/create', { page_number: page });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'searchUser') {
      setSearch(fieldValue);
    }
  };

  const handlePressKey = (e) => {
    if (e.key === 'Enter') {
      searchSubmit();
    }
  };

  const searchSubmit = () => {
    getAllDoctors({
      role: 'doctor',
      include: 'doctorDetails,doctorDetails.specialization',
      keyword: search,
    });
  };

  return (
    <div className='physicians-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Physicians' />

        <div className='physicians-wrapper-content'>
          {physicians.length ? (
            <Search
              name={'searchUser'}
              handleOnchange={handleFieldUpdate}
              value={search}
              searchSubmit={searchSubmit}
              placeholder='Search for a User'
              handlePressKey={handlePressKey}
            />
          ) : null}
          <div className='physicians-wrapper-header d-flex align-items-center justify-content-end'>
            <div onClick={handleAddDoctorCta} className='create-cta'>
              Add New Physician
            </div>
          </div>
          {physicians.length ? (
            <div>
              <Table
                pagination={true}
                actionAvailable={true}
                paginationPage={page}
                columnHeaders={columnHeaders}
                columnContent={physicians}
                paginationTotalPage={totalPage}
                handleAction={handleActionTrigger}
                handlePageNumber={handlePageNumber}
                nextPage={() => {
                  setPage(page + 1);
                  getAllDoctors({
                    role: 'doctor',
                    include: 'doctorDetails,doctorDetails.specialization',
                    page: page + 1,
                  });
                }}
                prevPage={() => {
                  setPage(page - 1);
                  getAllDoctors({
                    role: 'doctor',
                    include: 'doctorDetails,doctorDetails.specialization',
                    page: page - 1,
                  });
                }}
              />
            </div>
          ) : (
            <EmptyState title='No physician found' />
          )}
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default PhysicianListing;
