import { parseTableData } from './parser';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import { useToasts } from 'react-toast-notifications';
import EmptyState from '../../components/EmptyState';
import { useSelector } from 'react-redux';

import './styles.scss';
import { getAllSymptomsService } from '../../services/symptomsService';

const SymptomsListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [symptomsData, setSymptomsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);

  const auth = useSelector((state) => state.auth);

  const columnHeaders = [
    'S. No.',
    'Name',
    'Clinics',
    'Status',
    'Created',
    'Action',
  ];

  const [userData, setUserData] = useState({
    userName: 'Admin',
    value: 1,
  });

  useEffect(() => {
    if (auth?.authData?.role !== 'admin') {
      let user = {
        userName: 'Super Admin',
        value: 0,
      };
      setUserData({ ...user });
    }
  }, [auth]);

  const handleActionTrigger = (selectedSymptom) => {
    const symptomId = selectedSymptom.id;
    history.push(`/symptoms/edit/${symptomId}`, { page_number: currentPage });
  };

  const handleViewActionTrigger = (selectedSymptom) => {
    const symptomId = selectedSymptom.id;
    history.push(`/customers/${symptomId}`, {
      currSymptom: selectedSymptom,
      page_number: currentPage,
    });
  };

  const handleSendLinkAction = (selectedSymptom) => {
    let text =
      'https://' +
      selectedSymptom?.subdomain +
      '.' +
      `${process.env.REACT_APP_SIGNUP_URL}` +
      '?' +
      'membershipCode=' +
      selectedSymptom?.code;
    var dummy = document.createElement('textarea');

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    addToast(`SignUp Link Copied!`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const getAllSymptoms = (params) => {
    setLoading(true);

    getAllSymptomsService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setSymptomsData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllSymptoms({ page: pageNumber, getDefault: true });
  };
  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
        getDefault: true,
      };
      getAllSymptoms(params);
      props.location.state.page_current = null;
    } else {
      const params = { page: currentPage, getDefault: true };
      getAllSymptoms(params);
    }
  }, []); // eslint-disable-line

  const handleAddSymptomCta = () => {
    history.push('/symptoms/create', { page_number: currentPage });
  };

  return (
    <div className='symptoms-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Symptoms' />

        <div className='symptoms-wrapper-content'>
          <div className='symptoms-wrapper-header d-flex align-items-center justify-content-end'>
            <div className='create-cta' onClick={handleAddSymptomCta}>
              Create New Symptom
            </div>
          </div>

          {symptomsData.length ? (
            <Table
              pagination={true}
              columnContent={symptomsData}
              actionAvailable={true}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
                getAllSymptoms({ page: currentPage + 1, getDefault: true });
              }}
              prevPage={() => {
                setCurrentPage((oldCurrentPage) => oldCurrentPage - 1);
                getAllSymptoms({ page: currentPage - 1, getDefault: true });
              }}
            />
          ) : (
            <EmptyState title='No Symptoms found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default SymptomsListing;
