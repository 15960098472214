import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  deleteClinicService,
  getSingleClinicDetailsService,
  updateClinicDetailsService,
} from '../../services/clinicsService';
import Placeholder from '../../assets/images/placeholder.webp';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { cloneDeep } from 'lodash';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';
import SecondaryButton from '../../components/SecondaryButton';

import './styles.scss';
import { uploadImageService } from '../../services/uploadService';
import { getAllSpecializationsService } from '../../services/specializationsService';
import { parseTableData } from './parser';

const EditClinic = (props) => {
  const history = useHistory();

  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const defaultFeatures = { visibility: true, list: [{}, {}, {}, {}] };

  const [loading, setLoading] = useState(false);
  const [clinicIdentifier, setClinicIdentifier] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [currentPage, setCurrentPage] = useState(null);
  const [bannerDescription, setBannerDescription] = useState('');
  const [bannerHeading, setBannerHeading] = useState('');
  const [bannerButton1Text, setBannerButton1Text] = useState('');
  const [bannerButton1Url, setBannerButton1Url] = useState('');
  const [providerLabel, setProviderLabel] = useState('');
  // const [specializationsKeywords, setSpecializationsKeywords] = useState('');
  // const [specializationsKeywordsArray, setSpecializationsKeywordsArray] =
  //   useState('');
  const [testimonialsHeading, setTestimonialsHeading] = useState('');
  const [symptomsButtonText, setSymptomsButtonText] = useState('');
  const [symptomsButtonLink, setSymptomsButtonLink] = useState('');
  const [blogsButtonLink, setBlogsButtonLink] = useState('');
  const [videosButtonLink, setVideosButtonLink] = useState('');
  const [membershipHeading, setMembershipHeading] = useState('');
  const [membershipText, setMembershipText] = useState('');
  const [membershipButtonText, setMembershipButtonText] = useState('');
  const [membershipButtonLink, setMembershipButtonLink] = useState('');
  const [symptomsSubHeading, setSymptomsSubHeading] = useState('');
  const [symptomsHeading, setSymptomsHeading] = useState('');
  const [symptomsTextAboveButton, setSymptomsTextAboveButton] = useState('');

  const [features, setFeatures] = useState(defaultFeatures);
  const [membershipSectionVisibility, setMembershipSectionVisibility] =
    useState('');
  const [clinicClickable, setClinicClickable] = useState('');
  const [showQuizSection, setShowQuizSection] = useState('');
  const [featureSectionVisibility, setFeatureSectionVisibility] = useState('');

  const [description, setDescription] = useState('');

  const [clinicIconFile, setClinicIconFile] = useState(null);
  const [bannerBackgroundFile, setBannerBackgroundFile] = useState(null);
  const [membershipIllustrationFile, setMembershipIllustrationFile] =
    useState(null);
  const [clinicIconImage, setClinicIconImage] = useState(null);
  const [clinicIconImageId, setClinicIconImageId] = useState(null);
  const [bannerBackgroundImage, setBannerBackgroundImage] = useState(null);
  const [membershipIllustration, setMembershipIllustration] = useState(null);
  const [bannerBackgroundImageId, setBannerBackgroundImageId] = useState(null);
  const [membershipIllustrationImageId, setMembershipIllustrationImageId] =
    useState(null);
  const [hasClinicIconFileModified, setClinicIconFileModified] =
    useState(false);
  const [hasBannerBackgroundFileModified, setBannerBackgroundFileModified] =
    useState(false);
  const [
    hasMembershipIllustrationFileModified,
    setMembershipIllustrationFileModified,
  ] = useState(false);
  const [featureImage1File, setfeatureImage1File] = useState(null);
  const [hasfeatureImage1FileModified, setfeatureImage1FileModified] =
    useState(false);
  const [featureImage2File, setfeatureImage2File] = useState(null);
  const [hasfeatureImage2FileModified, setfeatureImage2FileModified] =
    useState(false);
  const [featureImage3File, setfeatureImage3File] = useState(null);
  const [hasfeatureImage3FileModified, setfeatureImage3FileModified] =
    useState(false);
  const [featureImage4File, setfeatureImage4File] = useState(null);
  const [hasfeatureImage4FileModified, setfeatureImage4FileModified] =
    useState(false);
  const [clinicSpecializations, setClinicSpecializations] = useState([]);
  const [specializationIds, setSpecializationIds] = useState(['']);
  const [specializationDescriptions, setSpecializationDescriptions] = useState([
    '',
  ]);
  const [specializationOptions, setSpecializationOptions] = useState(['']);

  const userData = {
    userName: 'Admin',
  };

  const editorToolbarConfig = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji'],
  };
  const styleMap = {
    UNDERLINE: {
      textDecoration: 'underline !important',
      borderBottom: '1px solid black',
    },
    LINK: {
      color: '#007bff !important',
      // textDecoration: 'underline !important',
      // borderBottom: '1px solid black',
    },
  };

  const [membershipTextContent, setMembershipTextEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleMembershipTextEditorChanges = (updatedEditorState) => {
    setMembershipTextEditorState(updatedEditorState);
  };

  //paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  useEffect(() => {
    const clinicId = props?.match?.params?.clinicIdentifier;
    setClinicIdentifier(clinicId);
  }, []);

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (clinicIdentifier && clinicIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line
      const params = {};

      fetchPrefillData(params);
    }
  }, [clinicIdentifier]);

  //fetch clinics data
  const fetchPrefillData = (params) => {
    setLoading(true);

    getAllSpecializationsService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(response?.data?.results);
          setSpecializationOptions(parsedTableData);
          // const ids = parsedTableData?.map((e) => {
          //   return e?.value;
          // });
          // setSpecializationIds(ids);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });

    getSingleClinicDetailsService(clinicIdentifier, params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const { data } = response;
          setClinicName(data?.name);
          setClinicClickable(data?.clinicDetails?.clickable ? 'true' : false);
          setShowQuizSection(
            data?.clinicDetails?.symptoms?.showQuizSection ? 'true' : false
          );
          setBannerDescription(data?.clinicDetails?.banner?.description);
          setDescription(data?.clinicDetails?.description);
          setBannerButton1Text(data?.clinicDetails?.banner?.button1Text);
          setBannerButton1Url(data?.clinicDetails?.banner?.button1Link);
          setBannerBackgroundImage(
            data?.clinicDetails?.banner?.backgroundImage?.url
          );
          setMembershipIllustration(
            data?.clinicDetails?.membership?.illustrationImage?.url
          );
          setBannerBackgroundImageId(
            data?.clinicDetails?.banner?.backgroundImage?.id
          );
          setMembershipIllustrationImageId(
            data?.clinicDetails?.membership?.illustrationImage?.id
          );
          setBannerHeading(data?.clinicDetails?.banner?.heading);
          setProviderLabel(data?.clinicDetails?.provider?.label);
          // setSpecializationsKeywordsArray(
          //   data?.clinicDetails?.provider?.specializationsKeywords
          // );
          // setSpecializationsKeywords(
          //   data?.clinicDetails?.provider?.specializationsKeywords?.join()
          // );
          setTestimonialsHeading(data?.clinicDetails?.testimonials?.heading);
          setSymptomsButtonText(data?.clinicDetails?.symptoms?.buttonText);
          setSymptomsButtonLink(data?.clinicDetails?.symptoms?.buttonLink);
          setBlogsButtonLink(data?.clinicDetails?.blogs?.buttonLink);
          setVideosButtonLink(data?.clinicDetails?.videos?.buttonLink);
          setSymptomsSubHeading(data?.clinicDetails?.symptoms?.subHeading);
          setSymptomsHeading(data?.clinicDetails?.symptoms?.heading);
          setSymptomsTextAboveButton(
            data?.clinicDetails?.symptoms?.textAboveButton
          );
          setMembershipHeading(data?.clinicDetails?.membership?.heading);
          setMembershipSectionVisibility(
            data?.clinicDetails?.membership?.visibility ? 'true' : false
          );
          setFeatureSectionVisibility(
            data?.clinicDetails?.features?.visibility ? 'true' : false
          );
          setMembershipButtonText(data?.clinicDetails?.membership?.buttonText);
          setMembershipButtonLink(data?.clinicDetails?.membership?.buttonLink);
          setClinicIconImage(data?.clinicDetails?.iconImage?.url);
          setClinicIconImageId(data?.clinicDetails?.iconImage?.id);
          setFeatures(data?.clinicDetails?.features || defaultFeatures);

          if (data?.clinicDetails?.provider?.specializations?.length) {
            const tempArray = [];
            data?.clinicDetails?.provider?.specializations?.forEach(() => {
              tempArray.push({
                specializationId: '',
                specializationDescription: '',
              });
            });
            setClinicSpecializations(tempArray);

            const specsIds =
              data?.clinicDetails?.provider?.specializations?.map((v) => {
                return v?.specializationId;
              });

            const specsDescs =
              data?.clinicDetails?.provider?.specializations?.map((v) => {
                return v?.specializationDescription;
              });
            setSpecializationIds(specsIds);
            setSpecializationDescriptions(specsDescs);
            updateClinicSpecializations(specsIds, specsDescs, tempArray);
          }
          const membershipContentBlock = htmlToDraft(
            data?.clinicDetails?.membership?.text
          );
          if (membershipContentBlock) {
            const contentState = ContentState.createFromBlockArray(
              membershipContentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setMembershipTextEditorState(editorState);
          }
        }
      })
      .catch((err) => {
        if (err.response)
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

        setLoading(false);
      });
  };

  const handleSpecializationSelection = (eventValue, index) => {
    if (eventValue) {
      let tempSpecializationIds = cloneDeep(specializationIds);
      tempSpecializationIds[index] = eventValue;
      setSpecializationIds(tempSpecializationIds);
      updateClinicSpecializations(tempSpecializationIds);
    }
  };

  const handleDeleteCurrentSpecialization = (index) => {
    let tempClinicSpecialization = cloneDeep(clinicSpecializations);
    tempClinicSpecialization.splice(index, 1);
    setClinicSpecializations(tempClinicSpecialization);

    let tempSpecializationIds = cloneDeep(specializationIds);
    tempSpecializationIds.splice(index, 1);
    setSpecializationIds(tempSpecializationIds);

    let tempSpecializationDescs = cloneDeep(specializationDescriptions);
    tempSpecializationDescs.splice(index, 1);
    setSpecializationDescriptions(tempSpecializationDescs);
  };

  const updateClinicSpecializations = (
    ids = specializationIds,
    descriptions = specializationDescriptions,
    clinicSpecializationsList = clinicSpecializations
  ) => {
    let tempClinicSpecs = cloneDeep(clinicSpecializationsList);
    ids?.forEach((value, index) => {
      tempClinicSpecs[index]['specializationId'] = value;
    });
    descriptions?.forEach((value, index) => {
      tempClinicSpecs[index]['specializationDescription'] = value;
    });
    setClinicSpecializations(tempClinicSpecs);
  };

  const handleSpecializationDescriptionUpdate = (eventValue, index) => {
    let tempSpecializationDesc = cloneDeep(specializationDescriptions);
    tempSpecializationDesc[index] = eventValue;
    setSpecializationDescriptions(tempSpecializationDesc);
    updateClinicSpecializations(undefined, tempSpecializationDesc);
  };

  const addSpecializationsFields = () => {
    let tempArr = cloneDeep(clinicSpecializations);
    let sampleObj = {
      specializationId: '',
      specializationDescription: '',
    };
    tempArr.push(sampleObj);
    setClinicSpecializations(tempArr);
  };

  const validateFormData = () => {
    let isFormValid = true;

    return isFormValid;
  };

  const uploadImage = (file) => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleEditClinic = async () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);
      let dataPayload = {
        description: description,
        iconImageId: clinicIconImageId,
        clickable: clinicClickable === 'true' ? true : false,
        banner: {
          heading: bannerHeading,
          description: bannerDescription,
          button1Link: bannerButton1Url,
          button1Text: bannerButton1Text,
          backgroundImageId: bannerBackgroundImageId,
        },
        symptoms: {
          buttonText: symptomsButtonText,
          buttonLink: symptomsButtonLink,
          subHeading: symptomsSubHeading,
          heading: symptomsHeading,
          textAboveButton: symptomsTextAboveButton,
          showQuizSection: showQuizSection === 'true' ? true : false,
        },
        features: {
          visibility: featureSectionVisibility === 'true' ? true : false,
          list: features?.list.map((ele) => ({
            link: ele?.link,
            text: ele?.text,
            illustrationImageId: ele?.illustrationImage?.id,
          })),
        },
        provider: {
          label: providerLabel,
          // specializationsKeywords: specializationsKeywordsArray,
          specializations: clinicSpecializations,
        },
        testimonials: {
          heading: testimonialsHeading,
        },
        membership: {
          heading: membershipHeading,
          text: draftToHtml(
            convertToRaw(membershipTextContent.getCurrentContent())
          ),
          buttonText: membershipButtonText,
          buttonLink: membershipButtonLink,
          visibility: membershipSectionVisibility === 'true' ? true : false,
          illustrationImageId: membershipIllustrationImageId,
        },
        videos: {
          buttonLink: videosButtonLink,
        },
        blogs: {
          buttonLink: blogsButtonLink,
        },
      };

      if (hasClinicIconFileModified) {
        const responseDataImage = await uploadImage(clinicIconFile);
        if (responseDataImage) {
          dataPayload.iconImageId = responseDataImage.data?.id;
        }
      }
      if (hasBannerBackgroundFileModified) {
        const responseDataImage = await uploadImage(bannerBackgroundFile);
        if (responseDataImage) {
          dataPayload.banner.backgroundImageId = responseDataImage.data?.id;
        }
      }
      if (hasMembershipIllustrationFileModified) {
        const responseDataImage = await uploadImage(membershipIllustrationFile);
        if (responseDataImage) {
          dataPayload.membership.illustrationImageId =
            responseDataImage.data?.id;
        }
      }
      if (hasfeatureImage1FileModified) {
        const responseDataImage = await uploadImage(featureImage1File);
        if (responseDataImage) {
          dataPayload.features.list[0].illustrationImageId =
            responseDataImage.data?.id;
        }
      }
      if (hasfeatureImage2FileModified) {
        const responseDataImage = await uploadImage(featureImage2File);
        if (responseDataImage) {
          dataPayload.features.list[1].illustrationImageId =
            responseDataImage.data?.id;
        }
      }
      if (hasfeatureImage3FileModified) {
        const responseDataImage = await uploadImage(featureImage3File);
        if (responseDataImage) {
          dataPayload.features.list[2].illustrationImageId =
            responseDataImage.data?.id;
        }
      }
      if (hasfeatureImage4FileModified) {
        const responseDataImage = await uploadImage(featureImage4File);
        if (responseDataImage) {
          dataPayload.features.list[3].illustrationImageId =
            responseDataImage.data?.id;
        }
      }
      // console.log(dataPayload);
      submitClinicDetails(dataPayload);
    } else {
      setLoading(false);
    }
  };

  const handleImageSelection = (name, targetValue, event) => {
    console.log(event);
    if (event?.target?.files && event?.target?.files[0]) {
      const file = event.target.files[0];
      if (name === 'clinicIcon') {
        setClinicIconFile(file);
        setClinicIconImage(URL.createObjectURL(file));
        setClinicIconFileModified(true);
      } else if (name === 'bannerBackgroundImage') {
        setBannerBackgroundFile(file);
        setBannerBackgroundImage(URL.createObjectURL(file));
        setBannerBackgroundFileModified(true);
      } else if (name === 'membershipIllustration') {
        setMembershipIllustrationFile(file);
        setMembershipIllustration(URL.createObjectURL(file));
        setMembershipIllustrationFileModified(true);
      }
    }
  };

  const handleFeaturesImagesSelection = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      let tempFeatures = JSON.parse(JSON.stringify(features));
      if (!tempFeatures.list[index].illustrationImage)
        tempFeatures.list[index].illustrationImage = {};
      tempFeatures.list[index].illustrationImage.url =
        URL.createObjectURL(file);
      setFeatures(tempFeatures);

      if (index === 0) {
        setfeatureImage1File(file);
        setfeatureImage1FileModified(true);
      } else if (index === 1) {
        setfeatureImage2File(file);
        setfeatureImage2FileModified(true);
      } else if (index === 2) {
        setfeatureImage3File(file);
        setfeatureImage3FileModified(true);
      } else if (index === 3) {
        setfeatureImage4File(file);
        setfeatureImage4FileModified(true);
      }
    }
  };

  const submitClinicDetails = (dataPayload) => {
    updateClinicDetailsService(clinicIdentifier, dataPayload)
      .then((responseData) => {
        setLoading(false);

        if (responseData) {
          if (responseData.code === 200 || responseData.code === 201) {
            addToast('Clinic has been updated successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            history.push('/clinics', { page_current: currentPage });
          }
        }
      })
      .catch((err) => {
        setLoading(false);

        const errorData = err.response?.data?.errors;
        let numOfErrors = 0;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              numOfErrors++;
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/clinics');
        } else {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        if (
          errorData &&
          numOfErrors === 0 &&
          Object.keys(errorData).length > 0
        ) {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  //Delete Questions
  const handleDeleteClinic = () => {
    if (clinicIdentifier) {
      deleteClinicService(clinicIdentifier)
        .then((res) => {
          setLoading(false);

          addToast('Clinic has been deactivated successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // TODO: Handle Response Using Response Code
          history.push('/clinics', { page_current: currentPage });
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'bannerDescription') {
      setBannerDescription(fieldValue);
    } else if (fieldName === 'description') {
      setDescription(fieldValue);
    } else if (fieldName === 'bannerHeading') {
      setBannerHeading(fieldValue);
    } else if (fieldName === 'bannerButton1Text') {
      setBannerButton1Text(fieldValue);
    } else if (fieldName === 'bannerButton1Url') {
      setBannerButton1Url(fieldValue);
    } else if (fieldName === 'symptomsButtonText') {
      setSymptomsButtonText(fieldValue);
    } else if (fieldName === 'symptomsButtonLink') {
      setSymptomsButtonLink(fieldValue);
    } else if (fieldName === 'providerLabel') {
      setProviderLabel(fieldValue);
    } else if (fieldName === 'testimonialsHeading') {
      setTestimonialsHeading(fieldValue);
    } else if (fieldName === 'membershipHeading') {
      setMembershipHeading(fieldValue);
    } else if (fieldName === 'membershipText') {
      setMembershipText(fieldValue);
    } else if (fieldName === 'membershipButtonText') {
      setMembershipButtonText(fieldValue);
    } else if (fieldName === 'membershipButtonLink') {
      setMembershipButtonLink(fieldValue);
    } else if (fieldName === 'symptomsTextAboveButton') {
      setSymptomsTextAboveButton(fieldValue);
    } else if (fieldName === 'symptomsSubHeading') {
      setSymptomsSubHeading(fieldValue);
    } else if (fieldName === 'symptomsHeading') {
      setSymptomsHeading(fieldValue);
    } else if (fieldName === 'blogsButtonLink') {
      setBlogsButtonLink(fieldValue);
    } else if (fieldName === 'videosButtonLink') {
      setVideosButtonLink(fieldValue);
    }
    // else if (fieldName === 'specializationsKeywords') {
    //   setSpecializationsKeywords(fieldValue);
    //   setSpecializationsKeywordsArray(fieldValue?.split(','));
    // }
  };

  const handleFeaturesFieldUpdate = (event, index, type) => {
    let tempFeatures = JSON.parse(JSON.stringify(features));
    tempFeatures.list[index][type] = event.target.value;
    setFeatures(tempFeatures);
  };

  const handleMembershipVisibilityFlagSelector = (selectedValue) => {
    if (selectedValue) {
      setMembershipSectionVisibility(selectedValue);
    }
  };

  const handleClinicClickableFlagSelector = (selectedValue) => {
    if (selectedValue) {
      setClinicClickable(selectedValue);
    }
  };

  const handleShowQuizSectionFlagSelector = (selectedValue) => {
    if (selectedValue) {
      setShowQuizSection(selectedValue);
    }
  };

  const handleFeatureVisibilityFlagSelector = (selectedValue) => {
    if (selectedValue) {
      setFeatureSectionVisibility(selectedValue);
    }
  };

  return (
    <div className='clinic-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title={'Edit Clinic: ' + clinicName} />

        <div className='clinic-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={clinicIconImage ? clinicIconImage : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='clinicIcon'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Icon Image'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='description'
                  value={description}
                  placeholder='Description'
                  handleChange={handleFieldUpdate}
                  label='Clinic Description'
                  required='yes'
                />
                <DropdownSelect
                  value={clinicClickable}
                  name='clinicClickable'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Clickable'}
                  placeholder='Clickable'
                  handleChange={handleClinicClickableFlagSelector}
                  required='yes'
                />
              </div>
            </div>
            <div className='sectionHeading'>Banner Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={
                      bannerBackgroundImage
                        ? bannerBackgroundImage
                        : Placeholder
                    }
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='bannerBackgroundImage'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Background Image'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
              <div className='row col-12 col-sm-6 col-md-8'>
                <div className='col-12 col-sm-4 col-md-8'>
                  <Input
                    name='bannerHeading'
                    value={bannerHeading}
                    placeholder='Heading'
                    handleChange={handleFieldUpdate}
                    label='Heading'
                    required='yes'
                  />
                </div>
                <div className='col-12 col-sm-4 col-md-10'>
                  <TextArea
                    name='bannerDescription'
                    value={bannerDescription}
                    placeholder='Description'
                    handleChange={handleFieldUpdate}
                    label='Description'
                    required='yes'
                  />
                </div>
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='bannerButton1Text'
                  value={bannerButton1Text}
                  placeholder='Enter value'
                  handleChange={handleFieldUpdate}
                  label='Button 1 Text'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-6'>
                <Input
                  name='bannerButton1Url'
                  value={bannerButton1Url}
                  placeholder='Button 1 URL'
                  handleChange={handleFieldUpdate}
                  label='Button 1 URL'
                  required='yes'
                />
              </div>
            </div>
            <div className='sectionHeading'>Symptoms Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='symptomsHeading'
                  value={symptomsHeading}
                  placeholder='Heading'
                  handleChange={handleFieldUpdate}
                  label='Heading'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='symptomsSubHeading'
                  value={symptomsSubHeading}
                  placeholder='Sub Heading'
                  handleChange={handleFieldUpdate}
                  label='Sub Heading'
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={showQuizSection}
                  name='showQuizSection'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show Quiz Section'}
                  placeholder='Show Quiz Section'
                  handleChange={handleShowQuizSectionFlagSelector}
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-6'>
                <Input
                  name='symptomsTextAboveButton'
                  value={symptomsTextAboveButton}
                  placeholder='Text Above the button'
                  handleChange={handleFieldUpdate}
                  label='Text Above the button'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='symptomsButtonText'
                  value={symptomsButtonText}
                  placeholder='Button Text'
                  handleChange={handleFieldUpdate}
                  label='Button Text'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-6'>
                <Input
                  name='symptomsButtonLink'
                  value={symptomsButtonLink}
                  placeholder='Button Link'
                  handleChange={handleFieldUpdate}
                  label='Button Link'
                  required='yes'
                />
              </div>
            </div>
            <div className='sectionHeading'>Features Section</div>
            <div className='row'>
              <div className='col-12 col-sm-4 col-md-4'>
                <DropdownSelect
                  value={featureSectionVisibility}
                  name='featureSectionVisibility'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Feature Section Visibility'}
                  placeholder='Feature Section Visibility'
                  handleChange={handleFeatureVisibilityFlagSelector}
                  required='yes'
                />
              </div>
            </div>
            {features.list.map((e, index) => {
              return (
                <div
                  className='row'
                  style={{
                    marginBottom: '2em',
                  }}
                >
                  <div className='col-12 col-sm-6 col-md-4'>
                    <div className='input-wrapper-display'>
                      <img
                        src={
                          e?.illustrationImage?.url
                            ? e?.illustrationImage?.url
                            : Placeholder
                        }
                        className='display-image'
                        alt='Uploaded File'
                      />
                    </div>

                    <Input
                      type='file'
                      name='featureImage'
                      required='yes'
                      onChange={(e) => handleFeaturesImagesSelection(e, index)}
                      label={'Upload Feature ' + (index + 1) + ' illustration'}
                      accept='.jpg, .png, .jpeg, .webp'
                    />
                  </div>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <Input
                      name='featureText'
                      value={e.text}
                      placeholder={'Feature ' + (index + 1) + ' Text'}
                      onChange={(e) =>
                        handleFeaturesFieldUpdate(e, index, 'text')
                      }
                      label={'Feature ' + (index + 1) + ' Text'}
                      required='yes'
                    />
                  </div>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <Input
                      name='featureLink'
                      value={e.link}
                      placeholder={'Feature ' + (index + 1) + ' Link'}
                      onChange={(e) =>
                        handleFeaturesFieldUpdate(e, index, 'link')
                      }
                      label={'Feature ' + (index + 1) + ' Link'}
                      required='yes'
                    />
                  </div>
                </div>
              );
            })}
            <div className='sectionHeading'>Provider Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='providerLabel'
                  value={providerLabel}
                  placeholder='Label'
                  handleChange={handleFieldUpdate}
                  label='Label'
                  required='yes'
                />
              </div>
              {/* <div className='col-12 col-sm-6 col-md-6'>
                <Input
                  name='specializationsKeywords'
                  value={specializationsKeywords}
                  placeholder='specialization1,specialization2, ..'
                  handleChange={handleFieldUpdate}
                  label='Specializations Keywords (Comma separated names to specify order of specializations)'
                  required='yes'
                />
              </div> */}
            </div>
            <span>
              <h5>Specialisations to shows</h5>
            </span>
            {clinicSpecializations &&
              clinicSpecializations.map((value, index) => {
                return (
                  <div className='row specialization-row'>
                    <div className='col-4 col-sm-4 col-md-4'>
                      <DropdownSelect
                        value={specializationIds[index]}
                        options={specializationOptions}
                        label={'Specialization'}
                        placeholder='Choose Specialization'
                        handleChange={(e) =>
                          handleSpecializationSelection(e, index)
                        }
                        required='yes'
                      />
                    </div>

                    <div className='col-6 col-sm-6 col-md-6'>
                      <TextArea
                        name='specializationDescription'
                        value={specializationDescriptions[index]}
                        placeholder='Description'
                        handleChange={(name, value) => {
                          handleSpecializationDescriptionUpdate(value, index);
                        }}
                        label='Description'
                        required='yes'
                      />
                    </div>

                    <button
                      className='delete-button button-secondary danger-button'
                      onClick={() => {
                        handleDeleteCurrentSpecialization(index);
                      }}
                    >
                      x
                    </button>
                  </div>
                );
              })}
            <SecondaryButton
              className='add-more-button'
              value='Add More'
              handleClick={addSpecializationsFields}
            />
            <div className='sectionHeading'>Testimonials Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='testimonialsHeading'
                  value={testimonialsHeading}
                  placeholder='Heading'
                  handleChange={handleFieldUpdate}
                  label='Heading'
                  required='yes'
                />
              </div>
            </div>
            <div className='sectionHeading'>Membership Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={
                      membershipIllustration
                        ? membershipIllustration
                        : Placeholder
                    }
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='membershipIllustration'
                  required='yes'
                  handleChange={handleImageSelection}
                  label={'Upload Membership Illustration'}
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='membershipHeading'
                  value={membershipHeading}
                  placeholder='Heading'
                  handleChange={handleFieldUpdate}
                  label='Heading'
                  required='yes'
                />
                <Input
                  name='membershipButtonText'
                  value={membershipButtonText}
                  placeholder='Button Text'
                  handleChange={handleFieldUpdate}
                  label='Button Text'
                  required='yes'
                />
                <Input
                  name='membershipButtonLink'
                  value={membershipButtonLink}
                  placeholder='Button Link'
                  handleChange={handleFieldUpdate}
                  label='Button Link'
                  required='yes'
                />
                <DropdownSelect
                  value={membershipSectionVisibility}
                  name='membershipSectionVisibility'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Visibility'}
                  placeholder='Visibility'
                  handleChange={handleMembershipVisibilityFlagSelector}
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6'>
                <div className='col-12 editor-class'>
                  <div className='col-12 editor-label'>
                    Description{' '}
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      *
                    </span>
                  </div>
                  <Editor
                    editorState={membershipTextContent}
                    toolbar={editorToolbarConfig}
                    wrapperClassName='input-editor'
                    editorClassName='input-editor-field'
                    onEditorStateChange={handleMembershipTextEditorChanges}
                    customStyleMap={styleMap}
                  />
                </div>
              </div>
            </div>
            <div className='sectionHeading'>Blogs Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-6'>
                <Input
                  name='blogsButtonLink'
                  value={blogsButtonLink}
                  placeholder='Button Link'
                  handleChange={handleFieldUpdate}
                  label='Button Link'
                  required='yes'
                />
              </div>
            </div>
            <div className='sectionHeading'>Videos Section</div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-6'>
                <Input
                  name='videosButtonLink'
                  value={videosButtonLink}
                  placeholder='Button Link'
                  handleChange={handleFieldUpdate}
                  label='Button Link'
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton value='Save' handleClick={handleEditClinic} />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value='Deactivate Clinic'
                  className='danger-button'
                  handleClick={handleDeleteClinic}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditClinic;
