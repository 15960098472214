import { parseTableData, parseTableDataFeedback } from './parser';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAllAppointmentsService } from '../../services/appointmentServices';
import { exportApplicationData } from '../../services/exportServices';
import Modal from 'react-bootstrap/Modal';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import { useToasts } from 'react-toast-notifications';
import EmptyState from '../../components/EmptyState';

import './styles.scss';
import DateTimeRangeSelector from '../../components/DateTimeRangeSelector';

const AppointmentsListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedField, setSearchedField] = useState('customerName');
  const [search, setSearch] = useState('');
  const [sendSearchData, setSendSearchData] = useState('');
  let columnHeaders = [
    'S. No.',
    'Customer Name',
    'Customer Email',
    'Customer City',
    'Doctor Name',
    'Specialization',
    'Appointment Date',
    'Appointment Status',
    'Coupon Code',
    'Final Price',
    'Feedback',
    'History',
    'Booking Date',
    'Action',
  ];

  const initialSortColumns = {
    'Booking Date': {
      key: 'bookingDate',
      value: null,
    },
    'Appointment Date': {
      key: 'appointmentDate',
      value: null,
    },
    'Doctor Name': {
      key: 'doctorName',
      value: null,
    },
  };
  const [sortColumns, setSortColumns] = useState(initialSortColumns);
  const sortColumn = Object.values(sortColumns).find((val) => val.value);
  const sortParam = sortColumn && `${sortColumn.key}:${sortColumn.value}`;
  const columnHeadersFeedback = ['Title', 'Feedback'];
  const [unavailabilityModal, setUnavailabilityModal] = useState(false);
  const [upcomingAppointment, SetupcomingAppointment] = useState(true);
  const [previousAppointment, SetpreviousAppointment] = useState(false);
  const [inactiveAppointment, SetinactiveAppointment] = useState(false);

  if (previousAppointment) {
    columnHeaders.splice(7, 0, 'Consultation Status');
  }

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selectedItem) => {
    history.push(`/appointments/edit/${selectedItem.id}`, {
      page_number: currentPage,
      doctor_id: selectedItem.doctor_id,
    });
  };

  const handleClose = () => setShow(false);

  const handleShow = (data) => {
    const parsedFeedbackData = parseTableDataFeedback(data);

    setFeedback(parsedFeedbackData);

    setShow(true);
  };

  const getAllAppointments = (params) => {
    setLoading(true);
    getAllAppointmentsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination,
            handleShow,
          );
          setAppointments(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    let isPrevious;
    let isActive;
    if (upcomingAppointment && !previousAppointment && !inactiveAppointment) {
      isPrevious = 0;
      isActive = 0;
    }
    if (!upcomingAppointment && previousAppointment && !inactiveAppointment) {
      isPrevious = 1;
      isActive = 0;
    }
    if (!upcomingAppointment && !previousAppointment && inactiveAppointment) {
      isActive = 1;
    }
    getAllAppointments({
      page: pageNumber,
      isPrevious: isPrevious,
      isActive: isActive,
      sort: sortParam,
    });
  };

  useEffect(() => {
    let isPrevious;
    let isActive;
    if (!upcomingAppointment && !previousAppointment && !inactiveAppointment) {
      isActive = 0;
    }
    if (upcomingAppointment && !previousAppointment && !inactiveAppointment) {
      isPrevious = 0;
      isActive = 0;
    }
    if (!upcomingAppointment && previousAppointment && !inactiveAppointment) {
      isPrevious = 1;
      isActive = 0;
    }
    if (!upcomingAppointment && !previousAppointment && inactiveAppointment) {
      isActive = 1;
    }
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);

      const params = {
        page: props.location?.state?.page_current,
        isPrevious: isPrevious,
        isActive: isActive,
        sort: sortParam,
        search: sendSearchData,
      };
      getAllAppointments(params);
      props.location.state.page_current = null;
    } else {
      const params = {
        page: currentPage,
        isPrevious: isPrevious,
        isActive: isActive,
        sort: sortParam,
        search: `${searchedField}:${sendSearchData}`,
      };
      getAllAppointments(params);
    }
  }, [
    upcomingAppointment,
    previousAppointment,
    inactiveAppointment,
    sortParam,
    sendSearchData,
  ]); // eslint-disable-line

  const changeSearchContent = (e) => {
    setSearch(e.target.value);
  };

  const searchKeyPress = (e) => {
    if (e.which === 13) {
      if (search !== '') {
        setSendSearchData(search);
      }
    }
  };

  const handleSearchOnClick = () => {
    setSendSearchData(search);
  };

  useEffect(() => {
    if (search === '') {
      setSendSearchData('');
    }
  }, [search]);

  const getExportData = () => {
    setSendSearchData(search);
    let isPrevious;
    let isActive;
    if (!upcomingAppointment && !previousAppointment && !inactiveAppointment) {
      isActive = 0;
    }
    if (upcomingAppointment && !previousAppointment && !inactiveAppointment) {
      isPrevious = 0;
      isActive = 0;
    }
    if (!upcomingAppointment && previousAppointment && !inactiveAppointment) {
      isPrevious = 1;
      isActive = 0;
    }
    if (!upcomingAppointment && !previousAppointment && inactiveAppointment) {
      isActive = 1;
    }
    const params = {
      isPrevious: isPrevious,
      isActive: isActive,
      sort: sortParam,
      search: `${searchedField}:${sendSearchData}`,
    };

    exportApplicationData(params)
      .then((res) => {
        downloadFile({
          data: res.data.body,
          fileName: 'appointments.csv',
          fileType: 'text/csv',
        });
      })
      .catch((err) => {
        console.log('error ', err);
      });

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType });
      const a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            actionAvailable={false}
            columnContent={feedback}
            columnHeaders={columnHeadersFeedback}
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>

      <div className="appointments-wrapper">
        <Header userData={userData} />
        <Sidebar />
        <div className="flexible-container">
          <SubHeader title="Appointments" />
          <div className="appointments-wrapper-content">
            <div className="appointments-wrapper-header">
              <div className="d-flex">
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search"
                    className="appointment-search mr-2"
                    onChange={changeSearchContent}
                    value={search}
                    onKeyPress={searchKeyPress}
                  ></input>
                  <button
                    onClick={() => handleSearchOnClick()}
                    className="search-btn"
                  >
                    Search
                  </button>
                </div>

                <div className="ml-3">
                  <select
                    name="search"
                    id="search"
                    onChange={(e) => {
                      setSearchedField(e.target.value);
                      setSendSearchData('');
                      setSearch('');
                    }}
                  >
                    <option value="customerName">Customer Name</option>
                    <option value="doctorName">Doctor Name</option>
                    <option value="customerEmail">Email</option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-upcoming mr-3"
                  data-toggle="button"
                  aria-pressed="false"
                  autoComplete="off"
                  onClick={(e) => {
                    setCurrentPage(1);
                    SetupcomingAppointment(true);
                    SetpreviousAppointment(false);
                    SetinactiveAppointment(false);
                  }}
                >
                  Upcoming
                </button>
                <button
                  type="button"
                  className="btn btn-previous m-3"
                  data-toggle="button"
                  aria-pressed="false"
                  autoComplete="off"
                  onClick={(e) => {
                    setCurrentPage(1);
                    SetupcomingAppointment(false);
                    SetpreviousAppointment(true);
                    SetinactiveAppointment(false);
                  }}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="btn btn-danger m-3"
                  data-toggle="button"
                  aria-pressed="false"
                  autoComplete="off"
                  onClick={(e) => {
                    setCurrentPage(1);
                    SetupcomingAppointment(false);
                    SetpreviousAppointment(false);
                    SetinactiveAppointment(true);
                  }}
                >
                  Inactive
                </button>
                <button
                  type="button"
                  className="btn btn-export m-3"
                  data-toggle="button"
                  aria-pressed="false"
                  autoComplete="off"
                  onClick={(e) => {
                    getExportData();
                  }}
                >
                  Export Data
                </button>
                <button
                  type="button"
                  className="btn btn-export m-3"
                  data-toggle="button"
                  aria-pressed="false"
                  autoComplete="off"
                  onClick={() => setUnavailabilityModal(true)}
                >
                  Public Holiday
                </button>
              </div>
            </div>

            <Modal
              show={unavailabilityModal}
              onHide={() => setUnavailabilityModal(false)}
              size="xl"
              centered
              className="cancel-appointment-modal"
            >
              <Modal.Body>
                <DateTimeRangeSelector type={'general'} />
              </Modal.Body>
            </Modal>
            <div className="appointments-wrapper-header d-flex align-items-center justify-content-end"></div>
            {appointments.length ? (
              <Table
                pagination={true}
                actionAvailable={true}
                feedbackAvailable={true}
                columnContent={appointments}
                paginationPage={currentPage}
                columnHeaders={columnHeaders}
                initialSortColumns={initialSortColumns}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                paginationTotalPage={totalPage}
                handleAction={handleActionTrigger}
                handlePageNumber={handlePageNumber}
                handleViewFeedbackAction={handleShow}
                nextPage={() => {
                  setCurrentPage(currentPage + 1);
                  getAllAppointments({ page: currentPage + 1 });
                }}
                prevPage={() => {
                  setCurrentPage(currentPage - 1);
                  getAllAppointments({ page: currentPage - 1 });
                }}
              />
            ) : (
              <EmptyState title="No Appointments found" />
            )}
          </div>
        </div>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default AppointmentsListing;
