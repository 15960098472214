/** On basis of the role, provision the routes and map them
* @param userRole {string} - enum of superadmin, admin, contentwriter
* @param routePath {string} - route navigated
* @returns {boolean} - to render the path or not
*/
export const renderRoutes = (userRole, routePath) => {
 if (userRole === -1) return true;
 /** Ignore slugs after the second '/' */
 const routeStart = routePath
   .split('/')
   .filter((str) => !!str)
   .shift();
 let shouldRenderRoute = true;
 switch (userRole) {
   case 'admin':
     const restrictedRoutes = ['appointments'];
     shouldRenderRoute = !restrictedRoutes.includes(routeStart);
     break;
   case 'contentwriter':
     const allowedRoutes = ['blogs', 'authors', 'topics', 'area-of-content'];
     shouldRenderRoute = allowedRoutes.includes(routeStart);
     break;
   default:
     shouldRenderRoute = true;
     break;
 }
 return shouldRenderRoute;
};