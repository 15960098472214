export const parseDropdownData = (inputData) => {
  let resultsArr = [];

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};

      renderingObject.name = arrayItem.name;
      renderingObject.value = arrayItem.id;

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
