import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllUsersService = (params) => {
  return axiosInstance.get(BackendConstants.users, { params: params });
};

export const exportCustomersService = (params) => {
  return axiosInstance.get(BackendConstants.exportUsers, { params: params });
};

export const addNewUserService = (data) => {
  return axiosInstance.post(BackendConstants.users, data);
};

export const getSingleUserDetailsService = (userIdentifier) => {
  return axiosInstance.get(
    BackendConstants.userById.replace(':id', userIdentifier)
  );
};

export const updateUserDetailsService = (userIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.userById.replace(':id', userIdentifier),
    data
  );
};

export const getCompanyUserStats = (params) => {
  return axiosInstance.get(BackendConstants.userStats, { params: params });
};

export const updateUserStatusService = (userIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.changeUserStatusById.replace(':id', userIdentifier),
    data
  );
};
