import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  deleteWebinarService,
  updateWebinarDetailsService,
  getSingleWebinarDetailsService,
} from '../../services/webinarsService';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { getAllCompaniesService } from '../../services/companyService';
import { uploadImageService } from '../../services/uploadService';

import moment from 'moment';
import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DateTimeInput from '../../components/DateTimeInput';
import DropdownSelect from '../../components/DropdownSelect';
import SecondaryButton from '../../components/SecondaryButton';
import { parseDropdownData } from './parser';
import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const EditWebinar = (props) => {
  const history = useHistory();

  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [webinarTitle, setWebinarTitle] = useState('');
  const [webinarUrl, setWebinarUrl] = useState('');
  const [webinarSummary, setWebinarSummary] = useState('');
  const [webinarDate, setWebinarDate] = useState(new Date());
  const [webinarDuration, setWebinarDuration] = useState('');
  const [webinarLeaders, setWebinarLeaders] = useState('');
  const [webinarButtonText, setWebinarButtonText] = useState('');
  const [webinarIsFeatured, setWebinarIsFeatured] = useState('');
  const [webinarStatus, setWebinarStatus] = useState('');
  const [webinarIdentifier, setWebinarIdentifier] = useState(null);
  const [hasFileModified, setFileModified] = useState(false);
  const [webinarImageIdentifier, setWebinarImageIdentifier] = useState('');
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [aocOptions, setAOCOptions] = useState([]);
  const [areaOfContentId, setAreaOfContentId] = useState('');
  const [memberFlag, setMemberFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  const userData = {
    userName: 'Admin',
  };

  const featuredOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  const webinarStatusOptions = [
    {
      name: 'Upcoming',
      value: 'UPCOMING',
    },
    {
      name: 'Archived',
      value: 'ARCHIVED',
    },
  ];
  const MemberFlagOptions = [
    {
      name: 'True',
      value: true,
    },
    {
      name: 'False',
      value: false,
    },
  ];

  //AOC Data
  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAOCOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  //Paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  //Companies Data
  const fetchCompanyData = (params) => {
    setLoading(true);
    getAllCompaniesService()
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setCompanyOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
    fetchCompanyData();
  }, []); // eslint-disable-line

  const fetchWebinarDetails = () => {
    setLoading(true);

    getSingleWebinarDetailsService(webinarIdentifier)
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            setWebinarUrl(response.data?.url);
            setWebinarTitle(response.data?.title);
            setImage(response.data?.image?.url);
            setWebinarSummary(response.data?.summary);
            setWebinarDate(moment(response.data?.dateTime).utc());
            setWebinarDuration(response.data?.duration);
            setWebinarLeaders(response.data?.leaders);
            setWebinarButtonText(response.data?.buttonText);
            setWebinarIsFeatured(response.data?.isFeatured);
            setWebinarStatus(response.data?.status);
            setWebinarImageIdentifier(response.data?.image?.id);
            setAreaOfContentId(response.data?.aoc?.id);
            setCompanyId(response.data?.company?.id);
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);

        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    setLoading(true);

    const capturedIdentifier = props?.match?.params?.webinarIdentifier;

    setWebinarIdentifier(capturedIdentifier);
  }, []); // eslint-disable-line

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (webinarIdentifier && webinarIdentifier !== null && !hasDetailsFetched) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchWebinarDetails();
    }
  }, [webinarIdentifier]); // eslint-disable-line
  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };
  const handleFeaturedSelection = (eventValue) => {
    if (eventValue) {
      setWebinarIsFeatured(eventValue);
    }
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setWebinarStatus(eventValue);
    }
  };

  const handleScheduleChange = (momentValue) => {
    if (momentValue) {
      setWebinarDate(momentValue);
    }
  };

  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAreaOfContentId(eventValue);
    } else {
      setAreaOfContentId(null);
    }
  };
  const handleCompanySelection = (eventValue) => {
    if (eventValue) {
      setCompanyId(eventValue);
    } else {
      setCompanyId(null);
    }
  };

  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));

      setFileModified(true);
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'webinarTitle') {
      setWebinarTitle(fieldValue);
    } else if (fieldName === 'webinarLeader') {
      setWebinarLeaders(fieldValue);
    } else if (fieldName === 'webinarDuration') {
      setWebinarDuration(fieldValue);
    } else if (fieldName === 'webinarURL') {
      setWebinarUrl(fieldValue);
    } else if (fieldName === 'webinarButtonText') {
      setWebinarButtonText(fieldValue);
    } else if (fieldName === 'webinarSummary') {
      setWebinarSummary(fieldValue);
    }
  };

  const validateFormData = () => {
    let isFormValid = true;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    if ((!file || !image) && hasFileModified) {
      addToast('Webinar photo is a required field. Please upload a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarTitle) {
      addToast('Title is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarUrl) {
      addToast('URL is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarSummary) {
      addToast('Summary is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (webinarSummary.length > 300) {
      addToast('Summary cant exceed more than 300 characters.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarDate) {
      addToast('Date is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarDuration) {
      addToast('Duration is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarLeaders) {
      addToast('Leaders is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarButtonText) {
      addToast('Button Text is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (webinarIsFeatured === '') {
      addToast('Featured status is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!webinarStatus || webinarStatus === '') {
      addToast('Status is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (
      !moment(
        moment(webinarDate).format(dateFormat),
        dateFormat,
        true
      ).isValid()
    ) {
      addToast('Enter a Valid Schedule', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const submitWebinarInformation = (dataPayload) => {
    updateWebinarDetailsService(webinarIdentifier, dataPayload)
      .then((responseData) => {
        setLoading(false);

        if (responseData) {
          if (responseData.code === 200) {
            addToast('Webinar has been Updated successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            history.push('/webinars', { page_current: currentPage });
          }
        }
      })
      .catch((err) => {
        setLoading(false);

        const errorData = err.response?.data?.errors;

        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });

              setLoading(false);
            }
          }
        } else {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const handleUpdateWebinar = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);
      if (hasFileModified) {
        uploadImage()
          .then((responseDataImage) => {
            if (responseDataImage) {
              if (responseDataImage.code === 200) {
                const dataPayload = {
                  title: webinarTitle,
                  url: webinarUrl,
                  summary: webinarSummary,
                  date_time: moment(webinarDate).format('YYYY-MM-DD HH:mm:ss'),
                  duration: webinarDuration,
                  leaders: webinarLeaders,
                  is_featured: webinarIsFeatured,
                  status: webinarStatus,
                  button_text: webinarButtonText,
                  image_id: responseDataImage.data?.id,
                  aoc_id: areaOfContentId,
                  company_id: companyId,
                };

                submitWebinarInformation(dataPayload);
              }
            }
          })
          .catch((uploadErrorData) => {
            setLoading(false);
            addToast('Could not Upload Image', {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      } else {
        const dataPayload = {
          title: webinarTitle,
          url: webinarUrl,
          summary: webinarSummary,
          date_time: moment(webinarDate).format('YYYY-MM-DD HH:mm:ss'),
          duration: webinarDuration,
          leaders: webinarLeaders,
          is_featured: webinarIsFeatured,
          status: webinarStatus,
          button_text: webinarButtonText,
          image_id: webinarImageIdentifier,
          aoc_id: areaOfContentId,
          company_id: companyId,
        };

        submitWebinarInformation(dataPayload);
      }
    } else {
      setLoading(false);
    }
  };

  //Delete Webinar
  const handleDeleteWebinar = () => {
    const webinarId = props?.match?.params?.webinarIdentifier;
    if (webinarId) {
      deleteWebinarService(webinarId)
        .then((res) => {
          setLoading(false);

          addToast('Webinar has been deleted successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // TODO: Handle Response Using Response Code
          history.push('/webinars');
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };
  return (
    <div className='createwebinar-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader
          title='Edit Webinar'
          // subtitle={webinarTitle || "N/A"}
        />

        <div className='createwebinar-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarTitle'
                  value={webinarTitle}
                  placeholder='Title'
                  handleChange={handleFieldUpdate}
                  label='Title'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarLeader'
                  value={webinarLeaders}
                  placeholder='Leaders'
                  handleChange={handleFieldUpdate}
                  label='Leaders'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DateTimeInput
                  label='Schedule'
                  required='yes'
                  dateFormat={'DD/MM/YYYY'}
                  timeFormat={'HH:mm A'}
                  onChange={handleScheduleChange}
                  value={webinarDate}
                  name='webinarDate'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarDuration'
                  value={webinarDuration}
                  placeholder='Duration'
                  handleChange={handleFieldUpdate}
                  label='Duration'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarURL'
                  value={webinarUrl}
                  placeholder='Webinar URL / Link'
                  handleChange={handleFieldUpdate}
                  label='Webinar URL'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='webinarButtonText'
                  value={webinarButtonText}
                  placeholder='Button Text to Display'
                  handleChange={handleFieldUpdate}
                  label='Button Text'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={webinarIsFeatured}
                  options={featuredOptions}
                  label={'Featured on Homepage'}
                  placeholder='Choose an Option'
                  required='yes'
                  handleChange={handleFeaturedSelection}
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={webinarStatus}
                  options={webinarStatusOptions}
                  label={'Choose a Status'}
                  placeholder='Choose a Status'
                  required='yes'
                  handleChange={handleStatusSelection}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select AOC'
                  label={'Select a AOC'}
                  value={areaOfContentId}
                  options={aocOptions}
                  // options={AocOptions}
                  handleChange={handleAocSelection}
                  // required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select a Company'
                  label={'Select a Company'}
                  value={companyId}
                  options={companyOptions}
                  // options={companyOptions}
                  handleChange={handleCompanySelection}
                  // required='yes'
                />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  options={MemberFlagOptions}
                  label={"Member Only ?"}
                  placeholder="Choose"
                  handleChange={handleMemberFlagSelection}
                />
              </div>
            </div> */}

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='webinarSummary'
                  value={webinarSummary}
                  placeholder='Enter Text'
                  handleChange={handleFieldUpdate}
                  label='Summary'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <PrimaryButton value='Save' handleClick={handleUpdateWebinar} />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value='Delete Webinar'
                  className='danger-button'
                  handleClick={handleDeleteWebinar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditWebinar;
