import { NavLink } from 'react-router-dom';
import { renderRoutes } from '../../common/utils';

const ProtectedRoute = ({ userRole, to, ...props }) => {
  return (
    renderRoutes(userRole, to) && (
      <NavLink
        to={to}
        className="sidebar-primary-link"
        activeClassName="sidebar-primary-active"
        {...props}
      />
    )
  );
};

export default ProtectedRoute;
