import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TimeRange from 'react-time-range';
import { useToasts } from 'react-toast-notifications';
import saveIcon from './../../assets/images/save.svg';
import addIcon from './../../assets/images/add.svg';
import deleteIcon from './../../assets/images/delete.svg';
import editIcon from './../../assets/images/edit.svg';
import './styles.css';

const TimeDayRangeSelector = ({ schedule = [], onChange = () => {}, minuteIncrement }) => {
  const [slots, setSlots] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    setSlots(schedule);
  }, [schedule]);

  const merge = (intervals) => {
    if (intervals.length < 2) return intervals;
    intervals.sort(
      (a, b) =>
        moment(a.startTime, 'HH:mm').utc(true) -
        moment(b.startTime, 'HH:mm').utc(true),
    );
    const res = [intervals[0]];
    for (let curr of intervals) {
      let prev = res[res.length - 1];
      if (
        moment(prev.endTime, 'HH:mm').utc(true) >=
        moment(curr.startTime, 'HH:mm').utc(true)
      ) {
        prev.endTime =
          moment(curr.endTime, 'HH:mm').utc(true) >=
          moment(prev.endTime, 'HH:mm').utc(true)
            ? curr.endTime
            : prev.endTime;
      } else {
        res.push(curr);
      }
    }
    return res;
  };

  const returnFunctionStart = (i, e) => {
    const state = slots;
    const startTime = moment.utc(e.startTime);
    const endTime = moment.utc(state[i].endTime, 'HH:mm');
    if (startTime !== endTime && startTime < endTime) {
      state[i] = {
        ...state[i],
        startTime: startTime.format('HH:mm'),
      };
      setSlots([...state]);
      return;
    }
    addToast('Invalid Time range', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const returnFunctionEnd = (i, e) => {
    const state = slots;
    const startTime = moment.utc(state[i].startTime, 'HH:mm');
    const endTime = moment.utc(e.endTime);
    if (startTime != endTime && startTime < endTime) {
      state[i] = {
        ...state[i],
        endTime: endTime.format('HH:mm'),
      };
      setSlots([...state]);
      return;
    }
    addToast('Invalid Time range', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const edit = (i) => {
    const state = slots;
    if (state.every((e) => e.edit === false)) {
      slots[i].edit = true;
      setSlots([...state]);
      return;
    }
    addToast('Please save all slot first', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const saveSlot = (i) => {
    let state = slots;
    if (
      state.every(
        (e, index) =>
          e.startTime !== e.endTime &&
          e.startTime < e.endTime &&
          (e.edit === false || index === i),
      )
    ) {
      state = merge(state);
      slots[i].edit = false;
      onChange([...state]);
      return;
    }
    addToast('Invalid Time range being saved', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const addSlot = () => {
    const state = slots;
    if (
      state.every(
        (e) =>
          e.edit === false &&
          e.startTime !== e.endTime &&
          e.startTime < e.endTime,
      )
    ) {
      state.push({ startTime: '00:00', endTime: '23:00', edit: true });
      setSlots([...state]);
      return;
    }
    addToast('Save the edited slot first', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const removeSlot = (i) => {
    const state = slots.filter((e, index) => i !== index);
    onChange([...state]);
  };
  return (
    <div>
      {slots.map((timeRange, i) => {
        return (
          <>
            {!timeRange.edit && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div id="react-time-range">
                  <div id="start-component" className="component">
                    <span className="label">Start</span>
                    <select id="select-start" disabled="true">
                      <option selected="selected">{timeRange.startTime}</option>
                    </select>
                  </div>
                  <div id="end-component" className="component">
                    <span className="label">End</span>
                    <select id="select-start" disabled="true">
                      <option selected="selected">{timeRange.endTime}</option>
                    </select>
                  </div>
                </div>
                <button
                  style={{ backgroundColor: 'transparent' }}
                  onClick={() => edit(i)}
                >
                  <img src={editIcon} style={{ height: 20, width: 20 }} />
                </button>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {timeRange.edit && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TimeRange
                    use24Hours={true}
                    sameIsValid={false}
                    onStartTimeChange={(e) => returnFunctionStart(i, e)}
                    onEndTimeChange={(e) => returnFunctionEnd(i, e)}
                    startMoment={moment.utc(timeRange.startTime, 'HH:mm')}
                    endMoment={moment.utc(timeRange.endTime, 'HH:mm')}
                    minuteIncrement={minuteIncrement}
                  />
                  <button
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => saveSlot(i)}
                  >
                    <img src={saveIcon} style={{ height: 20, width: 20 }} />
                  </button>

                  <button
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => removeSlot(i)}
                  >
                    <img src={deleteIcon} style={{ height: 20, width: 20 }} />
                  </button>
                </div>
              )}
            </div>
          </>
        );
      })}
      <button
        onClick={() => addSlot()}
        style={{ backgroundColor: 'transparent' }}
      >
        <img src={addIcon} style={{ height: 20, width: 20 }} />
      </button>
    </div>
  );
};

export default TimeDayRangeSelector;
