import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { parseDropdownData } from './parser';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { useToasts } from 'react-toast-notifications';
import { addNewBlogService, imageInEditor } from '../../services/blogsService';
import { uploadImageService } from '../../services/uploadService';
import { getAllAuthorsService } from '../../services/authorsService';
import { getAllTopicsService } from '../../services/topicsService';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';
import { getAllCompaniesService } from '../../services/companyService';
import draftToHtml from 'draftjs-to-html';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DropdownSelect from '../../components/DropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './styles.scss';
import { store } from '../../store';
const CreateBlogPost = (props) => {
  const history = useHistory();

  function uploadImageCallBack(file) {
    return new Promise(async (resolve, reject) => {
      const value = await uploadImage(file);

      const authToken = store.getState().auth.authToken;

      const res = await imageInEditor(value.data.id);

      resolve({
        data: {
          link: res.data,
        },
      });
    });
  }
  const editorToolbarConfig = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'list',
      'textAlign',
      'link',
      'embedded',
      'emoji',
      'image',
      'history',
      'colorPicker',
    ],
    image: {
      uploadCallback: uploadImageCallBack,
      alt: { present: true, mandatory: false },
    },
    colorPicker: {
      colors: ['rgb(134,165,217)'],
    },
  };
  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blogTitle, setBlogTitle] = useState('');
  const [blogTopicId, setTopicId] = useState('');
  const [blogAuthorId, setAuthorId] = useState('');
  const [blogSummary, setBlogSummary] = useState('');
  const [topicOptions, setTopicOptions] = useState([]);
  const [authorOptions, setAuthorOptions] = useState([]);
  const [listingStatus, setListingStatusOptions] = useState('');
  const [blogMetaDescription, setBlogMeta] = useState('');
  const [blogHeaderImage, setBlogHeaderImage] = useState(null);
  const [blogContent, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [aocOptions, setAOCOptions] = useState('');
  const [areaOfContentId, setAreaOfContentId] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  const [memberFlag, setMemberFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [slug, setSlug] = useState('');

  // useEffect(()=>{
  //   //set all default values of the by api
  // },[])

  const userData = {
    userName: 'Admin',
  };

  const listingOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  const MemberFlagOptions = [
    {
      name: 'True',
      value: true,
    },
    {
      name: 'False',
      value: false,
    },
  ];

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };

  const fetchAuthorsData = (params) => {
    setLoading(true);

    getAllAuthorsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAuthorOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
      });
  };

  const fetchTopicsData = (params) => {
    setLoading(true);

    getAllTopicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setTopicOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  //AOC Data
  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAOCOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  //Companies Data
  const fetchCompanyData = (params) => {
    setLoading(true);
    getAllCompaniesService()
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setCompanyOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  useEffect(() => {
    const authorParams = { page: 1, limit: 500, role: 'author' };
    const topicParams = { page: 1, limit: 100 };
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
    fetchAuthorsData(authorParams);
    fetchTopicsData(topicParams);
    fetchCompanyData();
  }, []); // eslint-disable-line

  const handleEditorChanges = (updatedEditorState) => {
    setEditorState(updatedEditorState);
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'blogTitle') {
      setBlogTitle(fieldValue);
    } else if (fieldName === 'blogSummary') {
      setBlogSummary(fieldValue);
    } else if (fieldName === 'blogMetaDescription') {
      setBlogMeta(fieldValue);
    }
  };

  const handleAuthorSelection = (selectedValue) => {
    if (selectedValue) {
      setAuthorId(selectedValue);
    }
  };

  const handleTopicSelection = (selectedValue) => {
    if (selectedValue) {
      setTopicId(selectedValue);
    }
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setListingStatusOptions(eventValue);
    }
  };
  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAreaOfContentId(eventValue);
    } else {
      setAreaOfContentId(null);
    }
  };
  const handleCompanySelection = (eventValue) => {
    if (eventValue) {
      setCompanyId(eventValue);
    } else {
      setCompanyId(null);
    }
  };

  const uploadImage = (file) => {
    setLoading(true);

    return uploadImageService(file)
      .then((responseData) => {
        if (responseData.code === 200) {
          setLoading(false);

          return responseData;
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const onImageChange = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setBlogHeaderImage(URL.createObjectURL(file));

      setFile(file);
    }
  };


  const handleSlugChange = (name, eventValue, event) => {
    if (eventValue) {
      setSlug(eventValue);
    } else {
      setSlug(null);
    }
  };

  const validateFormSubmission = () => {
    let isFormValid = true;

    if (!blogTitle) {
      addToast('Blog title is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!blogSummary) {
      addToast('Blog summary is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!blogAuthorId || blogAuthorId === '') {
      addToast('Blog author is a required selection. Please choose a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!blogTopicId || blogTopicId === '') {
      addToast('Blog topic is a required selection. Please choose a value.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!listingStatus || listingStatus === '') {
      addToast('Visible on the Website is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!blogHeaderImage) {
      addToast('Blog header image is required. Please upload an image.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleCreateNewBlogPost = (status = 'DRAFT') => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);

      uploadImage(file)
        .then((response) => {
          const dataPayload = {
            status: status,
            title: blogTitle,
            summary: blogSummary,
            topic_id: blogTopicId,
            author_id: blogAuthorId,
            meta: blogMetaDescription,
            header_image_id: response.data?.id,
            slug: slug,
            content: draftToHtml(convertToRaw(blogContent.getCurrentContent())),
            listed: listingStatus,
            aoc_id: areaOfContentId,
            // company_id: companyId,
          };
          if (companyId !== '') {
            dataPayload.company_id = companyId;
          }

          addNewBlogService(dataPayload)
            .then((responseData) => {
              if (responseData.code === 200) {
                addToast('Blog created successfully.', {
                  appearance: 'success',
                  autoDismiss: true,
                });

                history.push('/blogs', { page_current: currentPage });
              }

              setLoading(false);
            })
            .catch((err) => {
              const errorData = err.response?.data?.errors;
              if (errorData && Object.keys(errorData).length > 0) {
                for (let key in errorData) {
                  for (let e of errorData[key]) {
                    addToast(e, {
                      appearance: 'error',
                      autoDismiss: true,
                    });
                    setLoading(false);
                  }
                }
              } else {
                addToast(err.response?.data?.message, {
                  appearance: 'error',
                  autoDismiss: true,
                });

                setLoading(false);
              }
            });
        })
        .catch((error) => {
          const errorData = error.response?.data?.errors;
          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              if (key === 'mimeType') {
                addToast('Please upload an image', {
                  appearance: 'error',
                  autoDismiss: true,
                });
                setLoading(false);
              }
            }
          } else {
            addToast(error.response?.data?.message, {
              appearance: 'error',
              autoDismiss: true,
            });

            throw error;
          }
        });
    }
  };
  const styleMap = {
    UNDERLINE: {
      // color : 'yellow',
      textDecoration: 'underline !important',
      borderBottom: '1px solid black',
    },
    // 'STRIKETHROUGH':{
    //   // color: 'orange',
    //   textDecoration : 'line-through !important',
    //   borderBottom: '1px solid black',
    //   marginBottom: '-10px'
    // }
  };

  return (
    <div className='createpost-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Blog Post' />

        <div className='createpost-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='blogTitle'
                  value={blogTitle}
                  label={'Title'}
                  placeholder='Title of the post'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='blogSummary'
                  label={'Summary'}
                  value={blogSummary}
                  placeholder='Summary of the post'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='blogMetaDescription'
                  value={blogMetaDescription}
                  placeholder='Meta Description'
                  handleChange={handleFieldUpdate}
                  label={'Meta Description'}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  options={topicOptions}
                  label={'Choose a topic'}
                  placeholder='Choose a topic'
                  handleChange={handleTopicSelection}
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  options={authorOptions}
                  label={'Choose an author'}
                  placeholder='Choose an author'
                  handleChange={handleAuthorSelection}
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select AOC'
                  label={'Select a AOC'}
                  value={areaOfContentId}
                  options={aocOptions}
                  // options={AocOptions}
                  handleChange={handleAocSelection}

                  // required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={listingStatus}
                  options={listingOptions}
                  label={'Visible on the Website'}
                  placeholder='Choose a Status'
                  required='yes'
                  handleChange={handleStatusSelection}
                />
              </div>
            </div>

            <div className='row'>
              {/* <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  options={MemberFlagOptions}
                  label={"Member Only ?"}
                  placeholder="Choose whether this blog is member only"
                  handleChange={handleMemberFlagSelection}
                />
              </div> */}
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  placeholder='Select a Company'
                  label={'Select a Company'}
                  value={companyId}
                  options={companyOptions}
                  // options={companyOptions}
                  handleChange={handleCompanySelection}
                  // required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='text'
                  name='slug'
                  value={slug}
                  label='Enter slug'
                  placeholder='Eg: new-blog-post'
                  required='yes'
                  handleChange={handleSlugChange}
                />
              </div>
            </div>

            {/* <div className='row'>
              
            </div> */}

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={blogHeaderImage ? blogHeaderImage : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='blogHeaderImage'
                  label='Upload Header Image (16:9 or 4:3)'
                  accept='.jpg, .png, .jpeg, .webp'
                  required='yes'
                  handleChange={(name, targetValue, event) =>
                    onImageChange(name, targetValue, event)
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <Editor
                  editorState={blogContent}
                  toolbar={editorToolbarConfig}
                  wrapperClassName='input-editor'
                  editorClassName='input-editor-field'
                  onEditorStateChange={handleEditorChanges}
                  customStyleMap={styleMap}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 '>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleCreateNewBlogPost}
                />
              </div>
              <div className='col-12 col-sm-6 text-right'>
                <PrimaryButton
                  value='Save & Publish'
                  handleClick={() => handleCreateNewBlogPost('PUBLISHED')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateBlogPost;
