import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { addNewSupportGroupService } from "../../services/supportGroupsService";
import { getAllAreaOfContentService } from "../../services/areaOfContentService";
import { getAllCompaniesService } from "../../services/companyService";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import TextArea from "../../components/TextArea";
import SubHeader from "../../components/SubHeader";
import PrimaryButton from "../../components/PrimaryButton";
import DateTimeInput from "../../components/DateTimeInput";
import DropdownSelect from "../../components/DropdownSelect";

import "./styles.scss";
import { parseDropdownData } from "./parser";

const CreateSupportGroup = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupUrl, setGroupUrl] = useState("");
  const [groupSummary, setGroupSummary] = useState("");
  const [groupDate, setGroupDate] = useState(new Date());
  const [groupDuration, setGroupDuration] = useState("");
  const [groupLeaders, setGroupLeaders] = useState("");
  const [supportGroupButtonText, setSupportGroupButtonText] = useState("");
  const [groupIsFeatured, setGroupIsFeatured] = useState("");
  const [groupStatus, setGroupStatus] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [aocOptions, setAOCOptions] = useState("");
  const [areaOfContentId, setAreaOfContentId] = useState(null);
  const [memberFlag, setMemberFlag] = useState(false);
  const [feeType, setFeeType] = useState("");
  const [memberDiscount, setMemberDiscount] = useState("");
  const [memberFee, setMemberFee] = useState(null);
  const [razorpayLink, setRazorPayLink] = useState(null);
  const [currentPage,setCurrentPage] = useState(null);

  const userData = {
    userName: "Admin",
  };

  const featuredOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ];

  const groupStatusOptions = [
    {
      name: "Support programs",
      value: "UPCOMING",
    },
    {
      name: "Workshop",
      value: "ARCHIVED",
    },
  ];

  const MemberFlagOptions = [
    {
      name: "True",
      value: true,
    },
    {
      name: "False",
      value: false,
    },
  ];

  const MemberFeeTypeOptions = [
    {
      name: "Monthly",
      value: "MONTHLY",
    },
    {
      name: "Lumpsum",
      value: "LUMPSUM",
    },
  ];

  //Paging
  useEffect(()=>{
    
    if(props.location.state.page_number){
      
      setCurrentPage(props.location.state?.page_number);
    }
    
  },[])

  //AOC Data
  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAOCOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast("An error occurred while trying to Get Options.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  //Companies Data
  const fetchCompanyData = (params) => {
    setLoading(true);
    getAllCompaniesService()
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setCompanyOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast("An error occurred while trying to Get Options.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
    fetchCompanyData();
  }, []); // eslint-disable-line

  const handleMemberFlagSelection = (selectedValue) => {
    if (selectedValue) {
      setMemberFlag(selectedValue);
    }
  };
  const handleFeeType = (selectedValue) => {
    if (selectedValue) {
      setFeeType(selectedValue);
    }
  };

  const handleFeaturedSelection = (eventValue) => {
    if (eventValue) {
      setGroupIsFeatured(eventValue);
    }
  };

  const handleStatusSelection = (eventValue) => {
    if (eventValue) {
      setGroupStatus(eventValue);
    }
  };

  const handleScheduleChange = (momentValue) => {
    if (momentValue) {
      setGroupDate(momentValue);
    }
  };

  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAreaOfContentId(eventValue);
    } else {
      setAreaOfContentId(null);
    }
  };

  const handleCompanySelection = (eventValue) => {
    if (eventValue) {
      setCompanyId(eventValue);
    } else {
      setCompanyId(null);
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === "supportGroupTitle") {
      setGroupTitle(fieldValue);
    } else if (fieldName === "supportGroupLeader") {
      setGroupLeaders(fieldValue);
    } else if (fieldName === "supportGroupDuration") {
      setGroupDuration(fieldValue);
    } else if (fieldName === "supportGroupURL") {
      setGroupUrl(fieldValue);
    } else if (fieldName === "supportGroupSummary") {
      setGroupSummary(fieldValue);
    } else if (fieldName === "supportGroupButtonText") {
      setSupportGroupButtonText(fieldValue);
    } else if (fieldName === "memberDiscount") {
      setMemberDiscount(fieldValue);
    } else if (fieldName === "memberFee") {
      setMemberFee(fieldValue);
    } else if (fieldName === "physicianRazorPayLink") {
      setRazorPayLink(fieldValue);
    }
  };

  const validateFormData = () => {
    const numberFloatRegex = /[+-]?([0-9]*[.])?[0-9]+/;

    let isFormValid = true;

    if (!groupTitle) {
      addToast("Title is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupUrl) {
      addToast("URL is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupSummary) {
      addToast("Summary is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (groupSummary.length > 260) {
      addToast("Summary cant exceed more than 260 characters.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === "true" && razorpayLink === "") {
      addToast("RazorPay link is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (
      memberFlag === "true" &&
      !numberFloatRegex.test(memberDiscount)
    ) {
      addToast("Member Discount should be a valid number", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === "true" && parseInt(memberDiscount) > 90) {
      addToast("Member Discount cannot be more than 90", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === "true" && parseInt(memberDiscount) < 0) {
      addToast("Member Discount cannot be negative", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === "true" && !numberFloatRegex.test(memberFee)) {
      addToast("Member Fee should be a valid number", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (memberFlag === "true" && parseInt(memberFee) < 0) {
      addToast("Member Fee cannot be negative", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupDate) {
      addToast("Date is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupDuration) {
      addToast("Duration is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupLeaders) {
      addToast("Leaders is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupIsFeatured || groupIsFeatured === "") {
      addToast("Featured status is a required selection.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!groupStatus || groupStatus === "") {
      addToast("Status is a required selection.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!supportGroupButtonText) {
      addToast("Button Text is a required field.", {
        appearance: "error",
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleCreateSupportGroup = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      const dataPayload = {
        title: groupTitle,
        url: groupUrl,
        summary: groupSummary,
        date: groupDate,
        duration: groupDuration,
        leaders: groupLeaders,
        is_featured: groupIsFeatured,
        status: groupStatus,
        button_text: supportGroupButtonText,
        aoc_id: areaOfContentId,
        company_id: companyId,
        payment_url: razorpayLink,
        fees: memberFee,
        fee_type: feeType,
      };

      addNewSupportGroupService(dataPayload)
        .then((responseData) => {
          setLoading(false);

          if (responseData) {
            if (responseData.code === 200) {
              addToast("Support group has been created successfully.", {
                appearance: "success",
                autoDismiss: true,
              });

              history.push("/support-groups",{page_current: currentPage});
            }
          }
        })
        .catch((err) => {
          setLoading(false);

          const errorData = err.response?.data?.errors;

          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              for (let e of errorData[key]) {
                addToast(e, {
                  appearance: "error",
                  autoDismiss: true,
                });

                setLoading(false);
              }
            }
          } else {
            setLoading(false);

            addToast(err.response?.data?.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="createsupportgroup-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <div className="flexible-container">
        <SubHeader title="Create Support Group" />

        <div className="createsupportgroup-wrapper-content">
          <div className="form-section">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="supportGroupTitle"
                  value={groupTitle}
                  placeholder="Title"
                  handleChange={handleFieldUpdate}
                  label="Title"
                  required="yes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="supportGroupLeader"
                  value={groupLeaders}
                  placeholder="Leaders"
                  handleChange={handleFieldUpdate}
                  label="Leaders"
                  required="yes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DateTimeInput
                  label="Schedule"
                  required="yes"
                  timeFormat={false}
                  dateFormat={"DD/MM/YYYY"}
                  onChange={handleScheduleChange}
                  name="supportGroupDate"
                  value={groupDate}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="supportGroupDuration"
                  value={groupDuration}
                  placeholder="Duration"
                  handleChange={handleFieldUpdate}
                  label="Duration"
                  required="yes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="supportGroupURL"
                  value={groupUrl}
                  placeholder="Support Group URL / Link"
                  handleChange={handleFieldUpdate}
                  label="Support Program URL"
                  required="yes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="supportGroupButtonText"
                  value={supportGroupButtonText}
                  placeholder="Button Text to Display"
                  handleChange={handleFieldUpdate}
                  label="Button Text"
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="physicianRazorPayLink"
                  value={razorpayLink}
                  label="Razorpay Payment Page"
                  placeholder="Enter link"
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  value={groupIsFeatured}
                  options={featuredOptions}
                  label={"Featured on Homepage"}
                  placeholder="Choose an Option"
                  required="yes"
                  handleChange={handleFeaturedSelection}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  value={groupStatus}
                  options={groupStatusOptions}
                  label={"Choose a Status"}
                  placeholder="Choose a Status"
                  required="yes"
                  handleChange={handleStatusSelection}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  placeholder="Select AOC"
                  label={"Select a AOC"}
                  value={areaOfContentId}
                  options={aocOptions}
                  // options={AocOptions}
                  handleChange={handleAocSelection}
                  // required='yes'
                />
              </div>
              {/* <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  options={MemberFlagOptions}
                  label={"Member Only ?"}
                  placeholder="Choose"
                  handleChange={handleMemberFlagSelection}
                />
              </div> */}
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  options={MemberFeeTypeOptions}
                  label={"Fee Type"}
                  placeholder="Choose"
                  handleChange={handleFeeType}
                  required="yes"
                />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="memberDiscount"
                  value={memberDiscount}
                  label="Member Discount (%)"
                  placeholder="0"
                  handleChange={handleFieldUpdate}
                  type="text"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="memberFee"
                  value={memberFee}
                  label="Member Fee"
                  placeholder= 'Enter Member Fee'
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <DropdownSelect
                  placeholder="Select a Company"
                  label={"Select a Company"}
                  value={companyId}
                  options={companyOptions}
                  // options={companyOptions}
                  handleChange={handleCompanySelection}
                  // required='yes'
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <Input
                  name="memberFee"
                  value={memberFee}
                  label="Member Fee"
                  placeholder="Enter Member Fee"
                  handleChange={handleFieldUpdate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <TextArea
                  name="supportGroupSummary"
                  value={groupSummary}
                  placeholder="Enter Text"
                  handleChange={handleFieldUpdate}
                  label="Summary"
                  required="yes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <PrimaryButton
                  value="Save"
                  handleClick={handleCreateSupportGroup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateSupportGroup;
