import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllVideosService = (params) => {
  return axiosInstance.get(BackendConstants.videos, { params: params });
};

export const addNewVideoService = (data) => {
  return axiosInstance.post(BackendConstants.videos, data);
};

export const getSingleVideoDetailsService = (videoIdentifier, params) => {
  return axiosInstance.get(
    BackendConstants.videoById.replace(':id', videoIdentifier),
    { params: params }
  );
};

export const updateVideoDetailsService = (videoIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.videoById.replace(':id', videoIdentifier),
    data
  );
};

export const deleteVideoService = (videoId) => {
  return axiosInstance.delete(
    BackendConstants.deleteVideoById.replace(':id', videoId)
  );
};
