import { axiosInstance } from './index';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import BackendConstants from '../common/backendConstants';

const resizeFile = (
  file,
  maxWidth = 1920,
  maxHeight = 1080,
  compressFormat = 'WEBP',
  quality = 100
) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      0,
      (file) => {
        resolve(file);
      },
      'file'
    );
  });
};

export const uploadImageService = async (
  file,
  maxWidth = 1920,
  maxHeight = 1080,
  quality = 100,
  compressFormat = 'WEBP'
) => {
  file = await resizeFile(file, maxWidth, maxHeight, compressFormat, quality);
  const { name, type } = file;
  const data = {
    fileName: name.split('.')[0],
    mimeType: type,
    objType: 'images',
    sourceDisk: 'media',
  };
  var formData = new FormData();
  formData.append('file', file);

  return axiosInstance
    .post(BackendConstants.uploads, data)
    .then((imageUploadResponse) => {
      if (imageUploadResponse.code === 200) {
        return axios
          .put(imageUploadResponse.data.url, file, {
            headers: {
              'Content-Type': type,
            },
          })
          .then((s3Response) => {
            if (s3Response.status === 200) {
              return imageUploadResponse;
            }
          })
          .catch((err) => {
            throw err;
          });
      }
    })
    .catch((err) => {
      throw err;
    });
};
