import React, { useState, useEffect } from 'react';
import EmptyState from '../../components/EmptyState';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import Table from '../../components/Table';
import './style.scss';
import { useToasts } from 'react-toast-notifications';
import Search from '../../components/Search';
import { getAllMessagesLogs, optinUsers } from '../../services/messagesService';
import { parseMessagesTable } from './parser';
import { useHistory } from 'react-router-dom';

const userData = {
  userName: 'Admin',
};

const columnHeaders = [
  'S. No.',
  "Customer's Name",
  'Phone',
  'Message',
  'Timestamp',
];

const Messages = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [messagesData, setMessagesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log(pageNumber);
    fetchMessagesData({ page: pageNumber });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'search') {
      setSearch(fieldValue);
    }
  };

  const handlePressKey = (e) => {
    // const value = e.target.value;
    if (e.key === 'Enter') {
      fetchMessagesData({ keyword: search });
    }
  };

  async function readCSV(event) {
    setLoading(true);
    const file = document.getElementById('csvInput')?.files[0];

    const outUrl = URL.createObjectURL(file);
    fetch(outUrl)
      .then((res) => res.text())
      .then((data) => {
        const phoneNumbers = data?.split('\r\n');
        optinNumbers(phoneNumbers.map((e) => '+' + e));
      });
  }

  async function optinNumbers(numbers) {
    const payload = { numbers: numbers };
    optinUsers(payload)
      .then((responseData) => {
        if (responseData.code === 200) {
          addToast('Optin Done successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
        }
      });
  }

  const fetchMessagesData = async (params) => {
    setLoading(true);

    getAllMessagesLogs(params)
      .then((res) => {
        if (res?.code === 200) {
          const {
            data: { results = [], meta: { pagination = {} } = {} } = {},
          } = res || {};

          const { total_pages } = pagination;
          // console.log(results);
          const parsedTableData = parseMessagesTable(
            results,
            res.data?.meta?.pagination
          );

          setMessagesData(parsedTableData);

          setTotalPage(total_pages);
        }

        setLoading(false);
      })
      .catch((err) => {
        const { response: { data: { errors = {}, message } = {} } = {} } = err;

        if (errors && Object.keys(errors).length > 0) {
          for (let key in errors) {
            for (let e of errors[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          }
        } else {
          addToast(message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        setLoading(false);
      });
  };

  //SEARCH SUBMIT
  const searchSubmit = () => {
    fetchMessagesData({ keyword: search });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
      };
      fetchMessagesData(params);
      props.location.state.page_current = null;
    } else {
      const params = { page: currentPage };
      fetchMessagesData(params);
    }
  }, []);

  return (
    <div className='messages-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Messages' />

        <div className='coupon-wrapper-content'>
          <div style={{ display: 'flex' }}>
            <Search
              name={'search'}
              handleOnchange={handleFieldUpdate}
              value={search}
              searchSubmit={searchSubmit}
              placeholder='Search'
              handlePressKey={handlePressKey}
            />
            <label
              class='optinFileUploadLabel'
              style={{
                position: 'absolute',
                right: '1em',
              }}
            >
              Optin from CSV
              <input
                type='file'
                accept='.csv'
                id='csvInput'
                onChange={(e) => readCSV(e)}
              />
            </label>
          </div>
          <br />
          {messagesData.length ? (
            <>
              <Table
                pagination={true}
                columnContent={messagesData}
                paginationPage={currentPage}
                columnHeaders={columnHeaders}
                paginationTotalPage={totalPage}
                handlePageNumber={handlePageNumber}
                nextPage={() => {
                  setCurrentPage(currentPage + 1);
                  fetchMessagesData({ page: currentPage + 1 });
                }}
                prevPage={() => {
                  setCurrentPage(currentPage - 1);
                  fetchMessagesData({ page: currentPage - 1 });
                }}
              />
            </>
          ) : (
            <EmptyState title='No Messages' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default Messages;
