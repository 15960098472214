import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllCheckoutLogs = (params) => {
  return axiosInstance.get(BackendConstants.checkoutLogs, {
    params: { ...params, limit: 100 },
  });
};
export const syncAllPayments = (params) => {
  return axiosInstance.put(
    BackendConstants.refreshPaymentsOnOrder.replace(':id', params.id),
  );
};

export const initiateRefund = (params) => {
  return axiosInstance.post(
    BackendConstants.initiateRefund.replace(':id', params.id),
  );
};
