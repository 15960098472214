import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllWebinarsService = (params) => {
  return axiosInstance.get(BackendConstants.webinars, { params: params });
};

export const addNewWebinarService = (data) => {
  return axiosInstance.post(BackendConstants.webinars, data);
};

export const getSingleWebinarDetailsService = (webinarIdentifier,params) => {
  return axiosInstance.get(BackendConstants.webinarById.replace(':id', webinarIdentifier),{params:params});
};

export const updateWebinarDetailsService = (webinarIdentifier, data) => {
  return axiosInstance.patch(BackendConstants.webinarById.replace(':id', webinarIdentifier), data);
};

export const deleteWebinarService = (webinarId) => {
  return axiosInstance.delete(BackendConstants.deleteWebinarById.replace(':id', webinarId));
};
