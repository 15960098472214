import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';
import moment from 'moment';

export const getAllAppointmentsService = async (params) => {
  return await axiosInstance.get(BackendConstants.appointments, {
    params: params,
  });
};

export const getSingleAppointmentDetailsService = async (
  appointmentIdentifier,
  params,
) => {
  return await axiosInstance.get(
    BackendConstants.appointmentById.replace(':id', appointmentIdentifier),
    { params: params },
  );
};

export const getSlotsByDate = async (params) => {
  const url = `${BackendConstants.slotsByDate.replace(':id', params.doctorId)}${
    params.date
  }`;
  return await axiosInstance.get(url);
};

export const updateAppointment = async (appointmentIdentifier, params) => {
  const url = BackendConstants.appointmentById.replace(
    ':id',
    appointmentIdentifier,
  );
  return await axiosInstance.put(url, params);
};

export const rescheduleAppointment = async (appointmentIdentifier, params) => {
  const url = BackendConstants.rescheduleAppointment.replace(
    ':id',
    appointmentIdentifier,
  );
  return await axiosInstance.put(url, params);
};

export const updateAppointmentsStatus = async (data) => {
  const url = BackendConstants.updateStatus.replace(':id', `${data.id}`);
  return await axiosInstance.patch(url, data);
};

export const deleteAppointment = async (appointmentIdentifier, message) => {
  const url = BackendConstants.appointmentById.replace(
    ':id',
    appointmentIdentifier,
  );
  return await axiosInstance.delete(`${url}?message=${message}`);
};
export const getAvailableDate = async (doctorID) => {
  const url = `${BackendConstants.latestAvailableDate.replace(
    ':id',
    `${doctorID}`,
  )}?date=${moment.utc().format('YYYY-MM-DD')}`;
  return await axiosInstance.get(url);
};
