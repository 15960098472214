import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { addNewVideoService } from '../../services/videosService';
import { parseDropdownData } from './parser';
import { getAllAreaOfContentService } from '../../services/areaOfContentService';

import Input from '../../components/Input';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import DateTimeInput from '../../components/DateTimeInput';
import DropdownSelect from '../../components/DropdownSelect';

import './styles.scss';

const CreateVideos = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [speaker, setSpeaker] = useState('');
  const [aocId, setAocId] = useState('');
  const [aocOptions, setAOCOptions] = useState('');
  const [currentPage, setCurrentPage] = useState(null);

  const userData = {
    userName: 'Admin',
  };

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  //AOC Data
  const fetchAOCData = (params) => {
    setLoading(true);

    getAllAreaOfContentService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedDropdownData = parseDropdownData(response?.data?.results);

          setAOCOptions(parsedDropdownData);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const aocParams = { page: 1, limit: 100 };

    fetchAOCData(aocParams);
  }, []); // eslint-disable-line

  const handleAocSelection = (eventValue) => {
    if (eventValue) {
      setAocId(eventValue);
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'videoTitle') {
      setVideoTitle(fieldValue);
    } else if (fieldName === 'videoSpeaker') {
      setSpeaker(fieldValue);
    } else if (fieldName === 'videoUrl') {
      setVideoUrl(fieldValue);
    }
  };

  const validateFormData = () => {
    let isFormValid = true;

    if (!videoTitle) {
      addToast('Title is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!videoUrl) {
      addToast('URL is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!aocId || aocId === '') {
      addToast('Area Of Content is a required selection.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleCreateVideos = () => {
    const isFormValid = validateFormData();

    if (isFormValid) {
      setLoading(true);

      const url = videoUrl.split('=');
      const urlVal = `https://www.youtube.com/embed/${url[1]}`;

      const dataPayload = {
        title: videoTitle,
        url: urlVal ? urlVal : videoUrl,
        leaders: speaker,
        aoc_id: aocId,
      };

      addNewVideoService(dataPayload)
        .then((responseData) => {
          setLoading(false);

          if (responseData) {
            if (responseData.code === 200 || responseData.code === 201) {
              addToast('Video has been created successfully.', {
                appearance: 'success',
                autoDismiss: true,
              });

              history.push('/videos', { page_current: currentPage });
            }
          }
        })
        .catch((err) => {
          setLoading(false);

          const errorData = err.response?.data?.errors;

          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              for (let e of errorData[key]) {
                addToast(e, {
                  appearance: 'error',
                  autoDismiss: true,
                });

                setLoading(false);
              }
            }
          } else if (err.response?.data?.code === 404) {
            addToast('No Api.', {
              appearance: 'error',
              autoDismiss: true,
            });
            history.push('/videos');
          } else {
            setLoading(false);

            addToast(err.response?.data?.message, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='createVideos-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Videos' />

        <div className='createVideos-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='videoTitle'
                  value={videoTitle}
                  placeholder='Title'
                  handleChange={handleFieldUpdate}
                  label='Title'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='videoUrl'
                  value={videoUrl}
                  placeholder='Video URL / Link'
                  handleChange={handleFieldUpdate}
                  label='Video URL'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  options={aocOptions}
                  label={'AOC'}
                  placeholder='Choose AOC'
                  handleChange={handleAocSelection}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  name='videoSpeaker'
                  value={speaker}
                  placeholder='Speakers'
                  handleChange={handleFieldUpdate}
                  label='Speaker'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton value='Save' handleClick={handleCreateVideos} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateVideos;
