import React from 'react';

import { Provider } from 'react-redux';

import { store, globalPersistedStore } from './store';
import { PersistGate } from 'redux-persist/integration/react';

import AppRouter from './navigator/AppRouter';

import 'react-datetime/css/react-datetime.css';

import './assets/styles/styles.scss';

const App = (props) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={globalPersistedStore}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
};

export default App;
