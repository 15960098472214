import {
  faAmericanSignLanguageInterpreting,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import {
  faEye,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import "./styles.scss";

const Table = (props) => {
  //useStates
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pageNumberValue, setPageNumberValue] = useState(1);
  const { addToast } = useToasts();
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // useEffect(() => {
  //   props.handlePageNumber(pageNumberValue);
  // }, [pageNumberValue]);

  function changePage(pageNumber) {
    props.handlePageNumber(pageNumber);
    setPageNumberValue(pageNumber);
  }

  function copyToClipboard(item, base, slug) {
    let text =
      "https://" +
      "www." +
      base +
      "." +
      process.env.REACT_APP_WEBSITE_URL +
      "/" +
      "blogs" +
      "/" +
      item.topic +
      "/" +
      slug;
    var dummy = document.createElement("textarea");

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    addToast(`Link Copied!`, {
      appearance: "success",
      autoDismiss: true,
    });
  }

  const renderContent = () => {
    return props.columnContent.map((outerItem, outerIndex) => {
      let x = { ...outerItem };

      return (
        <tr key={outerIndex}>
          {Object.keys(x).map((innerItem, innerIndex) => {
            if (
              innerItem !== "id" &&
              innerItem !== "code" &&
              innerItem !== "doctor_id"
            ) {
              return (
                <td
                  key={innerIndex}
                  className={
                    innerItem === "status" || innerItem === "appointment_status"
                      ? x[`${innerItem}`] === "PUBLISHED" ||
                        x[`${innerItem}`] === "COMPLETED" ||
                        x[`${innerItem}`] === "SUPPORT PROGRAMS" ||
                        x[`${innerItem}`] === "Active" ||
                        x[`${innerItem}`] === "ACTIVE"
                        ? "text-success"
                        : x[`${innerItem}`] === "UPCOMING"
                          ? "text-info"
                          : x[`${innerItem}`] === "PREVIOUS"
                            ? "text-warning"
                            : "text-danger"
                      : ""
                  }
                >
                  {x[`${innerItem}`]}
                </td>
              );
            }
          })}

          {/* {props.sendLink && (
            <td>
              <div className='flexible-table-actions'>
                <button  disabled-link
                  type='button'
                  className={outerItem.status==="Inactive"?'flexible-table-button':'flexible-table-actions-disabled flexible-table-button'}
                  // onClick={() => props.handleAction(outerItem)}
                  onClick={()=>alert('hello the link is send')}
                  disabled={outerItem.status==="Active"? true:false}
                >
                  Send Link &nbsp;<FontAwesomeIcon icon={faLink} />
                </button>
              </div>
            </td>
          )} */}
          {/* {props.usersAvailable && (
            <td>
              <div className='flexible-table-actions'>
                <button
                  type='button'
                  className='flexible-table-button'
                  onClick={() => props.handleAction(outerItem)}
                >
                  View Users &nbsp;<FontAwesomeIcon icon={faEye} />
                </button>
              </div>
            </td>
          )} */}

          {props.actionAvailable && (
            <td>
              <div className="flexible-table-actions d-flex flex-row justify-content-between">
                <button
                  type="button"
                  className="flexible-table-button flexible-table-button-width"
                  variant="primary"
                  onClick={() => props.handleAction(outerItem)}
                  title="Edit Details"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                {props.sendLink ? (
                  <button
                    disabled-link
                    type="button"
                    className={
                      outerItem.status === "ACTIVE"
                        ? "flexible-table-button"
                        : "flexible-table-actions-disabled flexible-table-button"
                    }
                    onClick={() => props.handleSendLinkAction(outerItem)}
                    title="Send Link"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </button>
                ) : (
                  ""
                )}

                {props.usersAvailable ? (
                  <button
                    type="button"
                    className="flexible-table-button flexible-table-button-width"
                    variant="primary"
                    onClick={() => props.handleViewAction(outerItem)}
                    title="View Users"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </td>
          )}
          {props.showLinkCopy && (
            <td>
              {
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <Dropdown.Toggle className="drop" caret></Dropdown.Toggle>
                  <Dropdown.Menu className="drop-menu">
                    {props &&
                      props?.companyOptions &&
                      props.companyOptions.map((e, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              copyToClipboard(
                                outerItem,
                                e.subdomain,
                                props.slugs[outerIndex]
                              );
                            }}
                          >
                            {e.name}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              }
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <div className="flexible-table-wrapper">
      <table className="flexible-table">
        {props.columnHeaders && (
          <thead>
            <tr>
              {props.columnHeaders.map((item, index) => {
                let sortColumn = props.sortColumns && props.sortColumns[item];
                return (
                  <th
                    key={index}
                    onClick={() => {
                      if (!sortColumn) {
                        return;
                      }
                      let newValue;
                      if (sortColumn.value === "asc") {
                        newValue = "desc";
                      } else if (sortColumn.value === "desc") {
                        newValue = "asc";
                      } else {
                        newValue = "asc";
                      }

                      props.setSortColumns({
                        ...props.initialSortColumns,
                        [item]: {
                          ...sortColumn,
                          value: newValue,
                        },
                      });
                    }}
                    className={`${sortColumn ? "pointer" : ""}`}
                  >
                    {item}
                    {sortColumn?.value ? (
                      <FontAwesomeIcon
                        icon={
                          sortColumn.value === "asc" ? faArrowUp : faArrowDown
                        }
                        className="sort"
                      />
                    ) : null}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}

        {props.columnContent && <tbody>{renderContent()}</tbody>}
      </table>

      {props.pagination && !props.hidePagination && (
        <div className="flexible-paginator d-flex align-items-center justify-content-center">
          <button
            type="button"
            onClick={() => {
              // props.prevPage();
              changePage(Number(pageNumberValue) - 1);
            }}
            disabled={props.paginationPage <= 1}
            className="flexible-paginator-button"
          >
            Prev
          </button>

          <span className="paginationText">
            <input
              className="pageNumberInput"
              type="number"
              value={pageNumberValue}
              min="1"
              max={`${props.paginationTotalPage}`}
              onChange={(e) => {
                setPageNumberValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  if (
                    Number(e.target.value) >= 1 &&
                    Number(e.target.value) <= props.paginationTotalPage
                  ) {
                    changePage(Number(e.target.value));
                  }
                }
              }}
            ></input>
            <span className="paginationSplash">/</span>{" "}
            {props.paginationTotalPage}
          </span>
          <button
            type="button"
            className="flexible-paginator-button"
            onClick={() => {
              // props.nextPage();
              changePage(Number(pageNumberValue) + 1);
            }}
            disabled={props.paginationPage >= props.paginationTotalPage}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Table;
