import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllDoctorAction = async (params) => {
    return await axiosInstance.get(BackendConstants.doctors,{params:params});
};

export const addNewDoctorService = (data) => {
    return axiosInstance.post(BackendConstants.doctors, data);
};
export const getSingleDoctorDetailsService = (doctorIdentifier,params) => {
    return axiosInstance.get(BackendConstants.doctorById.replace(':id', doctorIdentifier),{params:params});
};

export const updatePhysicianDetailsService = (doctorIdentifier, data) => {
    return axiosInstance.patch(BackendConstants.doctorById.replace(':id', doctorIdentifier), data);
  };

export const deletePhysicianService = (physicianId) => {
    return axiosInstance.delete(BackendConstants.deletePhysicianById.replace(':id', physicianId));
};