import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllSymptomsService = async (params) => {
  return await axiosInstance.get(BackendConstants.symptoms, { params: params });
};

export const addNewSymptomsService = (data) => {
  return axiosInstance.post(BackendConstants.symptoms, data);
};

export const getSingleSymptomDetailsService = (symptomIdentifier, params) => {
  return axiosInstance.get(
    BackendConstants.symptomById.replace(':id', symptomIdentifier),
    { params: params }
  );
};

export const updateSymptomDetailsService = (symptomIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.symptomById.replace(':id', symptomIdentifier),
    data
  );
};

export const updateSymptomStatusService = (symptomIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.changeSymptomStatusById.replace(':id', symptomIdentifier),
    data
  );
};
