import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllClinicsService = (params) => {
  return axiosInstance.get(BackendConstants.clinics, { params: params });
};

export const addNewClinicService = (data) => {
  return axiosInstance.patch(
    BackendConstants.addOrDeleteClinicById.replace(':id', data?.aoc_id),
    {
      is_clinic: true,
    }
  );
};

export const getSingleClinicDetailsService = (clinicIdentifier, params) => {
  return axiosInstance.get(
    BackendConstants.clinicById.replace(':id', clinicIdentifier),
    { params: params }
  );
};

export const updateClinicDetailsService = (clinicIdentifier, data) => {
  return axiosInstance.patch(
    BackendConstants.clinicById.replace(':id', clinicIdentifier),
    data
  );
};

export const deleteClinicService = (clinicId) => {
  return axiosInstance.patch(
    BackendConstants.addOrDeleteClinicById.replace(':id', clinicId),
    {
      is_clinic: false,
    }
  );
};
