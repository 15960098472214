import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
//import { getAllTopicsService } from "../../services/topicsService";
import { getMembership } from '../../services/membershipService';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import { useToasts } from 'react-toast-notifications';
import EmptyState from '../../components/EmptyState';
import moment from 'moment';

import './styles.scss';

const MembershipListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [membership, setMembership] = useState([]);
  const [loading, setLoading] = useState(false);

  const columnHeaders = [
    'Duration (Days)',
    'Fee (Rs.)',
    'Percentage Off (%)',
    // "Auto Membership Amount",
    'Max Appointments per user',
    'Updated At',
    'Action',
  ];

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = () => {
    history.push(`/membership/edit`);
  };

  const getAllMembership = (params) => {
    setLoading(true);

    getMembership(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          response.data.updatedAt = moment(response.data.updatedAt).format(
            'DD/MM/YYYY hh:mm A'
          );
          const tempObject = response.data;
          delete tempObject['activateAutoMembershipAmount'];
          setMembership([tempObject]);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    //const params = { page: 1 };
    const params = {};
    getAllMembership(params);
  }, []); // eslint-disable-line

  return (
    <div className='membership-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Membership' />

        <div className='membership-wrapper-content'>
          {membership.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={membership}
              columnHeaders={columnHeaders}
              hidePagination={true}
              handleAction={handleActionTrigger}
            />
          ) : (
            <EmptyState title='No Membership found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default MembershipListing;
