import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import {
  deleteSpecializationService,
  getSpecializationByIdService,
  updateSpecializationService,
  updateSpecializationStatusService,
} from '../../services/specializationsService';
import { useToasts } from 'react-toast-notifications';
import Spinner from '../../components/Spinner';
import { uploadImageService } from '../../services/uploadService';
import Placeholder from '../../assets/images/placeholder.webp';
import { Editor } from 'react-draft-wysiwyg';

import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import './styles.scss';

const EditSpecialization = (props) => {
  let history = useHistory();
  const { addToast } = useToasts();
  const [specializationName, setSpecializationName] = useState('');
  const [specializationColor, setSpecializationColor] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [specializationStatus, setSpecializationStatus] = useState('');

  const [description, setDescriptionEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const handleDescriptionEditorChanges = (updatedEditorState) => {
    setDescriptionEditorState(updatedEditorState);
  };

  const editorToolbarConfig = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji'],
  };

  const styleMap = {
    UNDERLINE: {
      textDecoration: 'underline !important',
      borderBottom: '1px solid black',
    },
    LINK: {
      color: '#007bff !important',
      // textDecoration: 'underline !important',
      // borderBottom: '1px solid black',
    },
  };

  //paging
  useEffect(() => {
    setCurrentPage(props.location?.state?.page_number);
  }, []);

  useEffect(() => {
    const specializationId = props?.match?.params?.specializationIdentifier;
    setLoading(true);
    getSpecializationByIdService(specializationId).then((res) => {
      if (res.code === 200) {
        setLoading(false);
        setSpecializationName(res?.data?.name);
        setSpecializationStatus(res?.data?.status);
        setImagePreviewUrl(res?.data?.image?.url);
        setImage(res?.data?.image);
        setSpecializationColor(res?.data?.color);

        const descriptionContentBlock = htmlToDraft(
          res?.data?.description || ''
        );
        if (descriptionContentBlock) {
          const contentState = ContentState.createFromBlockArray(
            descriptionContentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setDescriptionEditorState(editorState);
        }
      }
    });
  }, []); // eslint-disable-line

  const uploadImage = () => {
    setLoading(true);
    return uploadImageService(file, 256, 256, 80)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);
          return res;
        }
      })
      .catch((err) => {
        // addToast('some error occurred', {
        //   appearance: 'error',
        //   autoDismiss: true,
        // });
        throw err;
      });
  };

  const onImageChange = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const updateSpecialization = async (specializationId, data) => {
    return updateSpecializationService(specializationId, data)
      .then((res) => {
        if (res.code === 200) {
          addToast('Specialization updated successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });
          history.push('/specializations', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
        }
      });
  };

  const handleDeleteSpecialization = (status, identifier) => {
    let data = { status: '' };
    if (status === 'ACTIVE') {
      data.status = 'INACTIVE';
      // setSymptomStatus('INACTIVE')
    } else if (status === 'INACTIVE') {
      data.status = 'ACTIVE';
      // setSymptomStatus('ACTIVE')
    }
    const specializationIdIdentifier = identifier;
    if (specializationIdIdentifier) {
      updateSpecializationStatusService(specializationIdIdentifier, data)
        .then((response) => {
          if (response.code === 200) {
            setLoading(false);

            addToast('Specializations status has been changed successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            // TODO: Handle Response Using Response Code
            history.push('/specializations');
          }
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  const handleEditSpecialization = async () => {
    setLoading(true);
    const data = {
      name: specializationName,
      description: draftToHtml(convertToRaw(description.getCurrentContent())),
      color: specializationColor,
    };
    const specializationId = props?.match?.params?.specializationIdentifier;
    if (image && image.id) {
      data.image_id = image.id;
    }
    //check if user want to change the image
    if (file) {
      uploadImage()
        .then(async (res) => {
          setLoading(false);
          if (res && res.data) {
            data.image_id = res.data.id;
          }
          await updateSpecialization(specializationId, data);
        })
        .catch((err) => {
          setLoading(false);
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else if (image && image.id) {
      data.image_id = image.id;
      await updateSpecialization(specializationId, data);
    }
  };

  return (
    <div className='editpost-wrapper'>
      <Header />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit Specialization' />
        {loading && <Spinner />}

        <div className='editpost-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={imagePreviewUrl ? imagePreviewUrl : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  handleChange={(name, targetValue, event) => {
                    onImageChange(name, targetValue, event);
                  }}
                  type='file'
                  name='physicianPhoto'
                  label='Upload Photo (1:1 Circular Corners)'
                  required='yes'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
              <div className='col-12 col-sm-6'>
                <div className='col-12 editor-class'>
                  <div className='col-12 editor-label'>
                    Description
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      *{' '}
                    </span>
                    (Shown on clinic page)
                  </div>
                  <Editor
                    editorState={description}
                    toolbar={editorToolbarConfig}
                    wrapperClassName='input-editor'
                    editorClassName='input-editor-field'
                    onEditorStateChange={handleDescriptionEditorChanges}
                    customStyleMap={styleMap}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={specializationName}
                  name='editSpecializationName'
                  label='Specialization Name'
                  required='yes'
                  onChange={(e) => {
                    setSpecializationName(e.target.value);
                  }}
                  placeholder='Enter Text'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='color'
                  className='color-input'
                  value={specializationColor}
                  name='specializationColor'
                  label='Specialization Color'
                  required='yes'
                  onChange={(e) => {
                    setSpecializationColor(e.target.value);
                  }}
                  placeholder='Enter Text'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={async () => {
                    await handleEditSpecialization(
                      props?.match?.params?.specializationIdentifier
                    );
                  }}
                />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value={
                    specializationStatus === 'ACTIVE'
                      ? 'MAKE INACTIVE'
                      : 'MAKE ACTIVE'
                  }
                  className={
                    specializationStatus === 'ACTIVE'
                      ? 'danger-button'
                      : 'success-button'
                  }
                  handleClick={() =>
                    handleDeleteSpecialization(
                      specializationStatus,
                      props?.match?.params?.specializationIdentifier
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSpecialization;
