import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  getSingleTestimonialDetailsService,
  updateTestimonialDetailsService,
  updateTestimonialStatusService,
} from '../../services/testimonialsService';
import { uploadImageService } from '../../services/uploadService';
import { cloneDeep } from 'lodash';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import TextArea from '../../components/TextArea';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import MultiDropdownSelect from '../../components/MultiDropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';
import './styles.scss';
import SecondaryButton from '../../components/SecondaryButton';
import { getAllClinicsService } from '../../services/clinicsService';
import DropdownSelect from '../../components/DropdownSelect';
import { getAllDoctorAction } from '../../services/doctorsService';

const EditTestimonials = (props) => {
  const history = useHistory();
  let hasDetailsFetched = false;

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [testimonialName, setTestimonialName] = useState('');
  const [testimonialDoctors, setTestimonialDoctors] = useState([]);
  const [testimonialStatus, setTestimonialStatus] = useState('');
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedClinicOptions, updateSelectedClinicOptions] = useState([]);
  const [selectedDoctorsOptions, updateSelectedDoctorOptions] = useState([]);
  const [testimonialIdentifier, setTestimonialIdentifier] = useState('');
  const [hasFileModified, setFileModified] = useState(false);
  const [testimonialImageIdentifier, setTestimonialImageIdentifier] =
    useState('');
  const [currentPage, setCurrentPage] = useState(null);
  const [description, setDescription] = useState('');
  const [showOnHomePage, setShowOnHomePage] = useState();
  const [showOnPcosPage, setShowOnPcosPage] = useState();
  const [showOnVaginismusPage, setShowOnVaginismusPage] = useState();
  const [showOnHealthCoachingPage, setShowOnHealthCoachingPage] = useState();

  const userData = {
    userName: 'Admin',
  };

  //paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  useEffect(() => {
    const testimonialID = props?.match?.params?.editTestimonialIdentifier;
    setTestimonialIdentifier(testimonialID);
  }, []);

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (
      testimonialIdentifier &&
      testimonialIdentifier !== null &&
      !hasDetailsFetched
    ) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchPrefillData();
    }
  }, [testimonialIdentifier]);

  const fetchPrefillData = () => {
    setLoading(true);

    getSingleTestimonialDetailsService(testimonialIdentifier, {
      include: 'doctors',
    })
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const { data } = response;
          let tempArr = [];
          if (data?.clinics) {
            data?.clinics.map((item) => {
              tempArr.push(item.id);
            });
          }
          setImage(response.data?.image?.url);
          setTestimonialImageIdentifier(response.data?.image?.id);
          setTestimonialName(data?.name);
          setDescription(data?.description);
          setTestimonialStatus(data?.status);
          setShowOnHomePage(data?.showOnHomePage ? 'true' : false);
          setShowOnPcosPage(data?.showOnPcosPage ? 'true' : false);
          setShowOnVaginismusPage(
            data?.showOnVaginismusProgramPage ? 'true' : false
          );
          setShowOnHealthCoachingPage(
            data?.showOnHealthCoachingPage ? 'true' : false
          );
          updateSelectedClinicOptions(tempArr);

          tempArr = [];
          if (data?.doctors) {
            data?.doctors.map((item) => {
              tempArr.push(item.id);
            });
          }
          updateSelectedDoctorOptions(tempArr);
        }
      })
      .catch((err) => {
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });

        setLoading(false);
      });
  };

  const fetchClinicData = (params) => {
    setLoading(true);

    getAllClinicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          setClinicOptions(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const fetchDoctorsData = (params) => {
    setLoading(true);

    getAllDoctorAction(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          setTestimonialDoctors(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const clinicParams = { page: 1, limit: 100 };
    const doctorParams = {
      page: 1,
      limit: 100,
      role: 'doctor',
      sortBy: 'NAME',
      status: 'ACTIVE',
    };

    fetchClinicData(clinicParams);
    fetchDoctorsData(doctorParams);
  }, []); // eslint-disable-line

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'testimonialName') {
      setTestimonialName(fieldValue);
    }
    if (fieldName === 'description') {
      setDescription(fieldValue);
    }
  };

  const handleClinicSelection = (obj) => {
    const index = selectedClinicOptions.findIndex((el) => el === obj.id);
    const tempArr = cloneDeep(selectedClinicOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedClinicOptions(tempArr);
  };

  const handleDoctorsSelection = (obj) => {
    const index = selectedDoctorsOptions.findIndex((el) => el === obj.id);
    const tempArr = cloneDeep(selectedDoctorsOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedDoctorOptions(tempArr);
  };

  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));

      setFileModified(true);
    }
  };

  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file, 256, 256, 80)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const validateFormSubmission = () => {
    let isFormValid = true;

    if ((!file || !image) && hasFileModified) {
      addToast(
        'Testimonial photo is a required field. Please upload a photo.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    } else if (!testimonialName) {
      addToast(
        'Testimonial Name is a required field. Please provide a value.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    }
    return isFormValid;
  };

  //Submit Information
  const submitTestimonialDetails = (dataPayload) => {
    updateTestimonialDetailsService(testimonialIdentifier, dataPayload)
      .then((responseData) => {
        if (responseData.code === 200 || responseData.code === 204) {
          addToast('Testimonial Updated successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // history.push('/testimonials');
        }
        history.push('/testimonials', { page_current: currentPage });
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Update Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/testimonials');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

          setLoading(false);
        }
      });
  };

  const handleShowOnHomePageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnHomePage(selectedValue);
    }
  };
  const handleShowOnPcosPageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnPcosPage(selectedValue);
    }
  };
  const handleShowOnVaginismusPageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnVaginismusPage(selectedValue);
    }
  };
  const handleShowOnHealthCoachingPageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnHealthCoachingPage(selectedValue);
    }
  };

  const handleUpdateTestimonialDetails = () => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);
      if (hasFileModified) {
        uploadImage()
          .then((imageResponseData) => {
            if (imageResponseData) {
              if (imageResponseData.code === 200) {
                const dataPayload = {
                  image_id: imageResponseData.data?.id,
                  name: testimonialName,
                  description: description,
                  status: testimonialStatus,
                  clinics: selectedClinicOptions,
                  doctors: selectedDoctorsOptions,
                  show_on_home_page: showOnHomePage === 'true' ? true : false,
                  show_on_pcos_page: showOnPcosPage === 'true' ? true : false,
                  show_on_vaginismus_program_page:
                    showOnVaginismusPage === 'true' ? true : false,
                  show_on_health_coaching_page:
                    showOnHealthCoachingPage === 'true' ? true : false,
                };
                submitTestimonialDetails(dataPayload);
              }
            }
          })
          .catch((uploadErrorData) => {
            setLoading(false);
            addToast('Could not Upload Image', {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      } else {
        const dataPayload = {
          image_id: testimonialImageIdentifier,
          name: testimonialName,
          status: testimonialStatus,
          description: description,
          clinics: selectedClinicOptions,
          doctors: selectedDoctorsOptions,
          show_on_home_page: showOnHomePage === 'true' ? true : false,
          show_on_pcos_page: showOnPcosPage === 'true' ? true : false,
          show_on_vaginismus_program_page:
            showOnVaginismusPage === 'true' ? true : false,
          show_on_health_coaching_page:
            showOnHealthCoachingPage === 'true' ? true : false,
        };

        submitTestimonialDetails(dataPayload);
      }
    } else {
      setLoading(false);
    }
  };

  const handleDeleteTestimonial = (status, identifier) => {
    let data = { status: '' };
    if (status === 'ACTIVE') {
      data.status = 'INACTIVE';
      // setTestimonialStatus('INACTIVE')
    } else if (status === 'INACTIVE') {
      data.status = 'ACTIVE';
      // setTestimonialStatus('ACTIVE')
    }
    const testimonialIdIdentifier = identifier;
    if (testimonialIdIdentifier) {
      updateTestimonialStatusService(testimonialIdIdentifier, data)
        .then((response) => {
          if (response.code === 200) {
            setLoading(false);

            addToast('Testimonial status has been changed successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });

            // TODO: Handle Response Using Response Code
            history.push('/testimonials');
          }
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  return (
    <div className='testimonial-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader
          title='Edit Testimonial Details'
          // subtitle={ (testimonialName && `Name: ${testimonialName}`) || 'Name: Untitled Testimonial'}
        />

        <div className='testimonial-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='testimonialName'
                  value={testimonialName}
                  label={'Testimonial Name'}
                  placeholder='Title of the post'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='description'
                  value={description}
                  placeholder='Description'
                  handleChange={handleFieldUpdate}
                  label='Description'
                  required='yes'
                  style={{
                    height: '14em',
                  }}
                />
              </div>
              <div className='col-12 col-sm-4'>
                <DropdownSelect
                  value={showOnHomePage}
                  name='showOnHomePage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Home Page'}
                  placeholder='Show on Home Page'
                  handleChange={handleShowOnHomePageSelector}
                  required='yes'
                />
                <DropdownSelect
                  value={showOnHealthCoachingPage}
                  name='showOnHealthCoachingPage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Health Coaching Page'}
                  placeholder='Show on Health Coaching Page'
                  handleChange={handleShowOnHealthCoachingPageSelector}
                  required='yes'
                />
                <DropdownSelect
                  value={showOnPcosPage}
                  name='showOnPcosPage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on PCOS Page'}
                  placeholder='Show on PCOS Page'
                  handleChange={handleShowOnPcosPageSelector}
                  required='yes'
                />
                <DropdownSelect
                  value={showOnVaginismusPage}
                  name='showOnVaginismusPage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Vaginismus Page'}
                  placeholder='Show on Vaginismus Page'
                  handleChange={handleShowOnVaginismusPageSelector}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={clinicOptions}
                  label={'Choose Clinics'}
                  placeholder='Choose contents'
                  handleChange={handleClinicSelection}
                  // required='yes'
                  selectedList={selectedClinicOptions}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={testimonialDoctors}
                  label={'Choose Doctors'}
                  placeholder='Choose contents'
                  handleChange={handleDoctorsSelection}
                  // required='yes'
                  selectedList={selectedDoctorsOptions}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleUpdateTestimonialDetails}
                />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value={
                    testimonialStatus === 'ACTIVE'
                      ? 'MAKE INACTIVE'
                      : 'MAKE ACTIVE'
                  }
                  className={
                    testimonialStatus === 'ACTIVE'
                      ? 'danger-button'
                      : 'success-button'
                  }
                  handleClick={() =>
                    handleDeleteTestimonial(
                      testimonialStatus,
                      testimonialIdentifier
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditTestimonials;
