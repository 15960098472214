import moment from 'moment';

export const parseTableData = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {

      let renderingObject = {};
      let index = (((current_page - 1 ) * 20) + (arrayIndex + 1));
      
      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.title = arrayItem.title;
      renderingObject.schedule = moment(arrayItem.date).format('DD/MM/YYYY');
      renderingObject.duration = arrayItem.duration;
      renderingObject.featured = arrayItem.isFeatured ? `YES` : `NO`;
      if(arrayItem.status==="UPCOMING"){
        renderingObject.status = "SUPPORT PROGRAMS";
      }else {
        renderingObject.status = "WORKSHOP";
      }
      renderingObject.Aoc = arrayItem.aoc?.name || 'N/A'
      // renderingObject.status = `${arrayItem.status}`.toUpperCase();

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
