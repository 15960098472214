export const parseDropdownData = (inputData) => {
  let resultsArr = [];

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};

      renderingObject.name = arrayItem.name;
      renderingObject.value = arrayItem.id;

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};

export const parseTableData = (inputData) => {
  let resultsArr = [];

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};

      // renderingObject.sno = `${arrayIndex + 1}.`;
      renderingObject.value = arrayItem.id;
      renderingObject.name = arrayItem.name;

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
