import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { uploadImageService } from '../../services/uploadService';
import { cloneDeep } from 'lodash';
import TextArea from '../../components/TextArea';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import MultiDropdownSelect from '../../components/MultiDropdownSelect';

import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';
import { getAllClinicsService } from '../../services/clinicsService';
import { addNewTestimonialsService } from '../../services/testimonialsService';
import DropdownSelect from '../../components/DropdownSelect';
import { getAllDoctorAction } from '../../services/doctorsService';

const userData = {
  userName: 'Admin',
};

const CreateTestimonial = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [testimonialName, setTestimonialName] = useState('');
  const [testimonialDoctors, setTestimonialDoctors] = useState([]);
  const [selectedDoctorsOptions, updateSelectedDoctorOptions] = useState([]);
  const [description, setDescription] = useState('');
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedClinicOptions, updateSelectedClinicOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [showOnHomePage, setShowOnHomePage] = useState('false');
  const [showOnPcosPage, setShowOnPcosPage] = useState('false');
  const [showOnVaginismusPage, setShowOnVaginismusPage] = useState('false');
  const [showOnHealthCoachingPage, setShowOnHealthCoachingPage] =
    useState('false');

  //paging
  useEffect(() => {
    if (props.location.state.page_number) {
      setCurrentPage(props.location.state?.page_number);
    }
  }, []);

  const fetchClinicData = (params) => {
    setLoading(true);

    getAllClinicsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          // const parsedDropdownData = parseDropdownData(response?.data?.results);
          // setClinicOptions(parsedDropdownData);
          setClinicOptions(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const fetchDoctorsData = (params) => {
    setLoading(true);

    getAllDoctorAction(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          setTestimonialDoctors(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const clinicParams = { page: 1, limit: 100 };
    const doctorParams = {
      page: 1,
      limit: 100,
      role: 'doctor',
      sortBy: 'NAME',
      status: 'ACTIVE',
    };

    fetchClinicData(clinicParams);
    fetchDoctorsData(doctorParams);
  }, []); // eslint-disable-line

  //Image
  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);

      setImage(URL.createObjectURL(file));
    }
  };

  //Upload Image
  const uploadImage = () => {
    setLoading(true);

    return uploadImageService(file, 256, 256, 80)
      .then((response) => {
        setLoading(false);

        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to upload file.', {
          appearance: 'error',
          autoDismiss: true,
        });

        throw errorData;
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'testimonialName') {
      setTestimonialName(fieldValue);
    }
    if (fieldName === 'description') {
      setDescription(fieldValue);
    }
  };

  const handleClinicSelection = (obj) => {
    const index = selectedClinicOptions.findIndex((el) => el === obj.id);

    const tempArr = cloneDeep(selectedClinicOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedClinicOptions(tempArr);
  };

  const handleDoctorsSelection = (obj) => {
    const index = selectedDoctorsOptions.findIndex((el) => el === obj.id);
    const tempArr = cloneDeep(selectedDoctorsOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedDoctorOptions(tempArr);
  };

  const validateFormSubmission = () => {
    let isFormValid = true;

    if (!file || !image) {
      addToast(
        'Testimonial photo is a required field. Please upload a photo.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    } else if (!testimonialName) {
      addToast(
        'Testimonial Name is a required field. Please provide a value.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleSubmitTestimonialData = (dataPayload) => {
    addNewTestimonialsService(dataPayload)
      .then((responseData) => {
        if (responseData.code === 200 || responseData.code === 201) {
          addToast('Testimonial created successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          history.push('/testimonials', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/testimonials');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

          setLoading(false);
        }
      });
  };

  const handleCreateNewTestimonial = () => {
    const isFormValid = validateFormSubmission();

    if (isFormValid) {
      setLoading(true);

      uploadImage()
        .then((imageResponseData) => {
          if (imageResponseData) {
            if (imageResponseData.code === 200) {
              const dataPayload = {
                image_id: imageResponseData.data?.id,
                name: testimonialName,
                description: description,
                clinics: selectedClinicOptions,
                doctors: selectedDoctorsOptions,
                show_on_home_page: showOnHomePage === 'true' ? true : false,
                show_on_pcos_page: showOnPcosPage === 'true' ? true : false,
                show_on_vaginismus_program_page:
                  showOnVaginismusPage === 'true' ? true : false,
                show_on_health_coaching_page:
                  showOnHealthCoachingPage === 'true' ? true : false,
              };
              handleSubmitTestimonialData(dataPayload);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          addToast('Could not Upload Image', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else {
      setLoading(false);
    }
  };

  const handleShowOnHomePageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnHomePage(selectedValue);
    }
  };

  const handleShowOnPcosPageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnPcosPage(selectedValue);
    }
  };

  const handleShowOnVaginismusPageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnVaginismusPage(selectedValue);
    }
  };

  const handleShowOnHealthCoachingPageSelector = (selectedValue) => {
    if (selectedValue) {
      setShowOnHealthCoachingPage(selectedValue);
    }
  };

  return (
    <div className='testimonial-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Create Testimonial' />

        <div className='testimonial-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='testimonialPhoto'
                  handleChange={handleImageSelection}
                  required='yes'
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <Input
                  name='testimonialName'
                  value={testimonialName}
                  label={'Testimonial Name'}
                  placeholder='Testimonial Name'
                  handleChange={handleFieldUpdate}
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <TextArea
                  name='description'
                  value={description}
                  placeholder='Description'
                  handleChange={handleFieldUpdate}
                  label='Description'
                  required='yes'
                  style={{
                    height: '14em',
                  }}
                />
              </div>
              <div className='col-12 col-sm-4'>
                <DropdownSelect
                  value={showOnHomePage}
                  name='showOnHomePage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Home Page'}
                  placeholder='Show on Home Page'
                  handleChange={handleShowOnHomePageSelector}
                  required='yes'
                />
                <DropdownSelect
                  value={showOnHealthCoachingPage}
                  name='showOnHealthCoachingPage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Health Coaching Page'}
                  placeholder='Show on Health Coaching Page'
                  handleChange={handleShowOnHealthCoachingPageSelector}
                  required='yes'
                />
                <DropdownSelect
                  value={showOnPcosPage}
                  name='showOnPcosPage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Pcos Page'}
                  placeholder='Show on Pcos Page'
                  handleChange={handleShowOnPcosPageSelector}
                  required='yes'
                />
                <DropdownSelect
                  value={showOnVaginismusPage}
                  name='showOnVaginismusPage'
                  options={[
                    {
                      name: 'True',
                      value: true,
                    },
                    {
                      name: 'False',
                      value: false,
                    },
                  ]}
                  label={'Show on Vaginismus Page'}
                  placeholder='Show on Vaginismus Page'
                  handleChange={handleShowOnVaginismusPageSelector}
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={clinicOptions}
                  label={'Choose Clinics'}
                  placeholder='Choose contents'
                  handleChange={handleClinicSelection}
                  // required='yes'
                  selectedList={selectedClinicOptions}
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={testimonialDoctors}
                  label={'Choose Doctors'}
                  placeholder='Choose contents'
                  handleChange={handleDoctorsSelection}
                  // required='yes'
                  selectedList={selectedDoctorsOptions}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={handleCreateNewTestimonial}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CreateTestimonial;
