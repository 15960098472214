import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from '../containers/Login';
import EditTopic from '../containers/EditTopic';
import EditAuthor from '../containers/EditAuthor';
import ProtectedRoute from '../hocs/ProtectedRoute';
import CreateTopic from '../containers/CreateTopic';
import BlogListing from '../containers/BlogListing';
import EditBlogPost from '../containers/EditBlogPost';
import CreateAuthor from '../containers/CreateAuthor';
import TopicsListing from '../containers/TopicsListing';
import EditPhysician from '../containers/EditPhysician';
import CreateBlogPost from '../containers/CreateBlogPost';
import AuthorsListing from '../containers/AuthorsListing';
import CreatePhysician from '../containers/CreatePhysician';
import PhysicianListing from '../containers/PhysicianListing';
import EditSpecialization from '../containers/EditSpecialization';
import CreateSpecialization from '../containers/CreateSpecialization';
import SpecializationListing from '../containers/SpecializationListing';
import WebinarsListing from '../containers/WebinarsListing';
import CreateWebinar from '../containers/CreateWebinar';
import EditWebinar from '../containers/EditWebinar';
import SupportGroupsListing from '../containers/SupportGroupsListing';
import CreateSupportGroup from '../containers/CreateSupportGroup';
import EditSupportGroup from '../containers/EditSupportGroup';
import CompaniesListing from '../containers/CompaniesListing';
import AreaOfContentListing from '../containers/AreaOfContentListing';
import CreateAreaOfContent from '../containers/CreateAreaOfContent';
import EditAreaOfContent from '../containers/EditAreaOfContent';
import UsersListing from '../containers/UsersListing';
import CreateUser from '../containers/CreateUsers';
import EditUser from '../containers/EditUsers';
import CreateCompanies from '../containers/CreateCompanies';
import EditCompanies from '../containers/EditCompanies';
import QuestionListing from '../containers/QuestionListing';
import CreateQuestions from '../containers/CreateQuestion';
import EditQuestion from '../containers/EditQuestion';
import VideosListing from '../containers/VideoListing';
import CreateVideos from '../containers/CreateVideo';
import EditVideo from '../containers/EditVideo';
import MembershipListing from '../containers/MembershipListing';
import EditMembership from '../containers/EditMembership';
import ClinicsListing from '../containers/ClinicsListing';
import CreateClinics from '../containers/CreateClinic';
import EditClinic from '../containers/EditClinic';
import SymptomsListing from '../containers/SymptomsListing';
import CreateSymptom from '../containers/CreateSymptom';
import EditSymptoms from '../containers/EditSymptom';
import EditTestimonials from '../containers/EditTestimonial';
import TestimonialsListing from '../containers/TestimonialsListing';
import CreateTestimonial from '../containers/CreateTestimonial';
import CouponCodes from '../containers/CouponCodes';
import EditCouponCode from '../containers/CouponCodes/EditCouponCode';
import Payments from '../containers/Payments';
import Messages from '../containers/Messages';
import Programs from '../containers/Programs';
import AppointmentsListing from '../containers/AppointmentsListing';
import EditAppointment from '../containers/EditAppointment';
import EmptyState from '../components/EmptyState';

import { renderRoutes } from '../common/utils'

const AppRouter = (props) => {
  const routesArray = [
    {
      routePath: '/blogs',
      Component: BlogListing,
    },
    {
      routePath: '/blogs/create',
      Component: CreateBlogPost,
    },
    {
      routePath: '/blogs/edit/:blogIdentifier',
      Component: EditBlogPost,
    },
    {
      routePath: '/specializations',
      Component: SpecializationListing,
    },
    {
      routePath: '/specializations/create',
      Component: CreateSpecialization,
    },
    {
      routePath: '/specializations/edit/:specializationIdentifier',
      Component: EditSpecialization,
    },
    {
      routePath: '/topics',
      Component: TopicsListing,
    },
    {
      routePath: '/topics/create',
      Component: CreateTopic,
    },
    {
      routePath: '/topics/edit/:topicIdentifier',
      Component: EditTopic,
    },
    {
      routePath: '/physicians',
      Component: PhysicianListing,
    },
    {
      routePath: '/physicians/create',
      Component: CreatePhysician,
    },
    {
      routePath: '/physicians/edit/:physicianIdentifier',
      Component: EditPhysician,
    },
    {
      routePath: '/authors',
      Component: AuthorsListing,
    },
    {
      routePath: '/authors/create',
      Component: CreateAuthor,
    },
    {
      routePath: '/authors/edit/:authorIdentifier',
      Component: EditAuthor,
    },
    {
      routePath: '/webinars',
      Component: WebinarsListing,
    },
    {
      routePath: '/webinars/create',
      Component: CreateWebinar,
    },
    {
      routePath: '/webinars/edit/:webinarIdentifier',
      Component: EditWebinar,
    },
    {
      routePath: '/support-groups',
      Component: SupportGroupsListing,
    },
    {
      routePath: '/support-groups/create',
      Component: CreateSupportGroup,
    },
    {
      routePath: '/support-groups/edit/:supportGroupIdentifier',
      Component: EditSupportGroup,
    },
    {
      routePath: '/companies',
      Component: CompaniesListing,
    },

    {
      routePath: '/companies/create',
      Component: CreateCompanies,
    },
    {
      routePath: '/companies/edit/:editCompanyIdentifier',
      Component: EditCompanies,
    },
    {
      routePath: '/area-of-content',
      Component: AreaOfContentListing,
    },
    {
      routePath: '/area-of-content/create',
      Component: CreateAreaOfContent,
    },
    {
      routePath: '/area-of-content/edit/:areaOfContentIdentifier',
      Component: EditAreaOfContent,
    },

    {
      routePath: '/customers/:companyId',
      Component: UsersListing,
    },
    {
      routePath: '/customers/create',
      Component: CreateUser,
    },
    {
      routePath: '/customers/edit/:userIdentifier',
      Component: EditUser,
    },

    {
      routePath: '/questions',
      Component: QuestionListing,
    },
    {
      routePath: '/questions/create',
      Component: CreateQuestions,
    },
    {
      routePath: '/questions/edit/:questionIdentifer',
      Component: EditQuestion,
    },

    {
      routePath: '/videos',
      Component: VideosListing,
    },
    {
      routePath: '/clinics',
      Component: ClinicsListing,
    },
    {
      routePath: '/videos/create',
      Component: CreateVideos,
    },
    {
      routePath: '/clinics/create',
      Component: CreateClinics,
    },
    {
      routePath: '/clinics/edit/:clinicIdentifier',
      Component: EditClinic,
    },
    {
      routePath: '/videos/edit/:videoIdentifier',
      Component: EditVideo,
    },
    {
      routePath: '/membership',
      Component: MembershipListing,
    },
    {
      routePath: '/membership/edit',
      Component: EditMembership,
    },

    {
      routePath: '/symptoms/create',
      Component: CreateSymptom,
    },
    {
      routePath: '/symptoms',
      Component: SymptomsListing,
    },
    {
      routePath: '/symptoms/edit/:editSymptomIdentifier',
      Component: EditSymptoms,
    },
    {
      routePath: '/testimonials/create',
      Component: CreateTestimonial,
    },
    {
      routePath: '/testimonials',
      Component: TestimonialsListing,
    },
    {
      routePath: '/testimonials/edit/:editTestimonialIdentifier',
      Component: EditTestimonials,
    },
    {
      routePath: '/coupon-codes',
      Component: CouponCodes,
    },
    {
      routePath: '/coupon-codes/create',
      Component: EditCouponCode,
    },
    {
      routePath: '/coupon-codes/edit/:editCouponIdentifier',
      Component: EditCouponCode,
    },
    {
      routePath: '/payments',
      Component: Payments,
    },
    {
      routePath: '/messages',
      Component: Messages,
    },
    {
      routePath: '/programs',
      Component: Programs,
    },
    {
      routePath: '/appointments',
      Component: AppointmentsListing,
    },
    {
      routePath: '/appointments/edit/:appointmentIdentifier',
      Component: EditAppointment,
    },
  ];

  const auth = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({
    userName: 'Admin',
    value: 1,
  });

  useEffect(() => {
    const role = auth?.authData?.role ?? '';
    const name = auth?.authData?.name ?? '';
    /** Super Admin role */
    if (!['admin', 'contentwriter'].includes(role)) {
      let user = {
        userName: 'Super Admin',
        value: 0,
      };
      setUserData({ ...user });
      /** Role other than Super Admin */
    } else if (role !== '') {
      let user = {
        userName: name,
        value: role,
      };
      setUserData({ ...user });
    }
  }, [auth]);

  return (
    <Router>
      <Switch>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="bottom-right"
        >
          {/* Begin Unprotected Routes */}
          <Route exact path={'/'} component={Login} />

          {/* Begin Protected Routes */}
          {auth &&
            routesArray.map(({ routePath, Component }) =>
              renderRoutes(auth?.authData?.role ?? -1, routePath) ? (
                <ProtectedRoute
                  key={routePath}
                  path={routePath}
                  component={Component}
                  {...props}
                />
              ) : (
                <Route
                  exact
                  path={routePath}
                  render={() => 
                    <EmptyState
                      topTitle="403"
                      title="Unauthorized"
                    />
                  }
                />
              ),
            )}
        </ToastProvider>
      </Switch>
    </Router>
  );
};

export default AppRouter;
