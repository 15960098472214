import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import SubHeader from '../../../components/SubHeader';
import Spinner from '../../../components/Spinner';
import Input from '../../../components/Input';
import DropdownSelect from '../../../components/DropdownSelect';
import './style.scss';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import {
  addNewCoupon,
  getSingleCouponDetails,
  updateCouponService,
} from '../../../services/promoCodeServices';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import MultiDropdownSelect from '../../../components/MultiDropdownSelect';
import { getAllDoctorAction } from '../../../services/doctorsService';
import { cloneDeep } from 'lodash';

const EditCouponCode = (props) => {
  const {
    location: { state: { item = false } = {} } = {},
    match: { params: { editCouponIdentifier } = {} } = {},
  } = props || {};

  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [selectedDoctorsOptions, updateSelectedDoctorOptions] = useState([]);
  const [couponCodesDoctors, setCouponCodesDoctors] = useState([]);

  const [data, setData] = useState({
    code: '',
    discount_type: '',
    min_required_amount: null,
    max_discount_amount: null,
    discount: null,
    status: 'ACTIVE',
    doctors: [],
  });

  useEffect(() => {
    if (editCouponIdentifier) {
      setLoading(true);

      getSingleCouponDetails(editCouponIdentifier, { include: 'doctors' })
        .then((res) => {
          setLoading(false);

          const {
            data: {
              code,
              discountType,
              minRequiredAmount,
              maxDiscountAmount,
              discount,
              status,
            } = {},
          } = res || {};
          let tempArr = [];
          if (res?.data?.doctors) {
            res?.data?.doctors.map((item) => {
              tempArr.push(item.id);
            });
          }
          updateSelectedDoctorOptions(tempArr);

          setData({
            code,
            discount_type: discountType,
            discount,
            status,
            min_required_amount: minRequiredAmount,
            max_discount_amount: maxDiscountAmount,
            doctors: tempArr,
          });
        })
        .catch((err) => {
          const { response: { data: { message } = {} } = {} } = err || {};

          if (err.response)
            addToast(message, {
              appearance: 'error',
              autoDismiss: true,
            });

          setLoading(false);
        });
    }
  }, []);

  const handleOnChange = ({ name, value }) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const createCoupon = () => {
    setLoading(true);

    addNewCoupon(data)
      .then((res) => {
        setLoading(false);

        if (res?.code === 200 || res?.code === 201) {
          addToast('Coupon Code Created', {
            appearance: 'success',
            autoDismiss: true,
          });

          history.push('/coupon-codes');
        }
      })
      .catch((err) => {
        setLoading(false);

        const { response: { data: { errors = {}, code, message } = {} } = {} } =
          err || {};

        if (errors && Object.keys(errors).length > 0) {
          for (let key in errors) {
            for (let e of errors[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });

              setLoading(false);
            }
          }
        } else if (code === 404) {
          addToast('No Api.', {
            appearance: 'error',
            autoDismiss: true,
          });

          history.push('/coupon-codes');
        } else {
          setLoading(false);

          addToast(message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const updateCoupon = () => {
    setLoading(true);

    updateCouponService({ id: editCouponIdentifier, data })
      .then((res) => {
        setLoading(false);

        history.push('/coupon-codes');

        addToast('Coupon Updated Successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch((err) => {
        if (err.response)
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

        setLoading(false);
      });
  };

  const handleToggle = () => {
    setLoading(true);

    updateCouponService({
      id: editCouponIdentifier,
      data: {
        ...data,
        status: data?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      },
    })
      .then((res) => {
        setLoading(false);

        addToast('Coupon Updated Successfully', {
          appearance: 'success',
          autoDismiss: true,
        });

        // setData({
        //   ...data,
        //   status: data?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        // });

        history.push('/coupon-codes');
      })
      .catch((err) => {
        if (err.response)
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });

        setLoading(false);
      });
  };

  const fetchDoctorsData = (params) => {
    setLoading(true);

    getAllDoctorAction(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          setCouponCodesDoctors(response?.data?.results);
        }
      })
      .catch((errorData) => {
        setLoading(false);
        addToast('An error occurred while trying to Get Options.', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const doctorParams = {
      page: 1,
      limit: 100,
      role: 'doctor',
      sortBy: 'NAME',
      status: 'ACTIVE',
    };

    fetchDoctorsData(doctorParams);
  }, []); // eslint-disable-line

  const handleDoctorsSelection = (obj) => {
    const index = selectedDoctorsOptions.findIndex((el) => el === obj.id);
    const tempArr = cloneDeep(selectedDoctorsOptions);
    if (index === -1) {
      tempArr.push(obj.id);
    } else {
      tempArr.splice(index, 1);
    }
    updateSelectedDoctorOptions(tempArr);
    setData({
      ...data,
      doctors: tempArr,
    });
  };

  return (
    <div className='edit-coupon-wrapper'>
      <Header />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader
          title={`${editCouponIdentifier ? 'Edit' : 'Create'} Coupon`}
        />
        {loading && <Spinner />}

        <div className='edit-coupon-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={data?.code}
                  name='code'
                  label='Coupon Code'
                  onChange={({ target }) => {
                    handleOnChange(target);
                  }}
                  placeholder='Enter Code'
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <DropdownSelect
                  value={data?.discount_type}
                  options={[
                    {
                      value: 'FLAT',
                      name: 'Flat',
                    },
                    {
                      value: 'PERCENT',
                      name: 'Percent',
                    },
                  ]}
                  label={'Choose Type'}
                  handleChange={(value) => {
                    handleOnChange({ name: 'discount_type', value });
                  }}
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={data?.discount}
                  name='discount'
                  label='Amount / Percentage'
                  onChange={({ target }) => {
                    handleOnChange(target);
                  }}
                  placeholder='Enter Value'
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={data?.min_required_amount}
                  name='min_required_amount'
                  label='Minimum Amount'
                  onChange={({ target }) => {
                    handleOnChange(target);
                  }}
                  placeholder='Enter Amount'
                  required='yes'
                />
              </div>

              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={data?.max_discount_amount}
                  name='max_discount_amount'
                  label='Maximum Discount (in Rs.)'
                  onChange={({ target }) => {
                    handleOnChange(target);
                  }}
                  placeholder='Enter Amount'
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <MultiDropdownSelect
                  options={couponCodesDoctors}
                  label={'Choose Doctors'}
                  placeholder='Choose contents'
                  handleChange={handleDoctorsSelection}
                  // required='yes'
                  selectedList={selectedDoctorsOptions}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={() => {
                    if (editCouponIdentifier) {
                      updateCoupon();
                      return;
                    }

                    createCoupon();
                  }}
                />
              </div>

              {editCouponIdentifier && (
                <div className='col-12 col-sm-6 text-right'>
                  <SecondaryButton
                    value={
                      data?.status === 'ACTIVE'
                        ? 'MAKE INACTIVE'
                        : 'MAKE ACTIVE'
                    }
                    className={
                      data?.status === 'ACTIVE'
                        ? 'danger-button'
                        : 'success-button'
                    }
                    handleClick={handleToggle}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCouponCode;
