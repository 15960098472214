import React from 'react';
import { useHistory } from 'react-router-dom';

import NoData from '../../assets/images/no-data.svg';

import './styles.scss';

export default function EmptyState(props) {
  const history = useHistory();
  const { title, topTitle } = props;

  return (
    <div className="emptystate-container">
      <img src={NoData} alt="" className="img-icon" />

      {topTitle ? <h4 className="title4">{topTitle}</h4> : null}

      <h4 className="title4">{title}</h4>
      {topTitle === '403' && (
        <button className="back-button" onClick={() => history.push('/')}>
          Back to Dashboard
        </button>
      )}
    </div>
  );
}
