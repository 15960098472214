import './styles.scss';

const PrimaryButton = (props) => {
  return (
    <button
      type={props.type}
      name={props.name}
      onClick={() => props.handleClick()}
      className={props.classname ? `button-primary ${props.classname}` : `button-primary`}
      disabled={props.disableValue}
    >
      {props.value}
    </button>
  );
};

export default PrimaryButton;
