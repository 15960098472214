import moment from 'moment';

export const parseTableData = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let clinicsArray = [];
      arrayItem?.clinics?.map((item) => {
        clinicsArray.push(item.name);
      });
      let clinicsValue = clinicsArray.join(',');
      let renderingObject = {};
      let index = (current_page - 1) * 20 + (arrayIndex + 1);
      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.name = arrayItem.name || 'N/A';
      renderingObject.clinics = clinicsValue || 'N/A';
      renderingObject.status = arrayItem.status || 'N/A';
      renderingObject.created =
        moment(arrayItem.createdAt).format('DD/MM/YYYY hh:mm A') || 'N/A';
      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
