import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TimeRange from 'react-time-range';
import { useToasts } from 'react-toast-notifications';
import {
  addMultipleNewAvailableSlotService,
  getMultipleNewAvailableSlotService,
} from '../../services/availableSlotService';
import TimeDayRangeSelector from './selector';
const daysArr = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const TimeRangeSelector = ({ physician, doctorDuration, minuteIncrement }) => {
  const [edited, setEdited] = useState(false);
  const [availablity, setAvailability] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const { addToast } = useToasts();

  const handeleChange = (i, schedule = []) => {
    const state = availablity;
    state[daysArr[i]] = [...schedule];
    setAvailability({ ...state });
    setEdited(true);
    // postMultipleSLots();
  };

  const postMultipleSLots = async () => {
    const res = await addMultipleNewAvailableSlotService({
      doctorId: physician,
      schedule: availablity,
      duration: doctorDuration ? +doctorDuration : 60,
    });
    if (res.success) {
      addToast('Time Range saved', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    setEdited(false);
  };

  const getMutipleSlots = async () => {
    const res = await getMultipleNewAvailableSlotService({
      doctorId: physician,
    });
    const schedules = res.data;
    const state = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };
    for (var i = 0; i < schedules.length; i++) {
      const schedule = schedules[i];
      const day = schedule.day;
      state[day].push({
        startTime: schedule.startTime,
        endTime: schedule.endTime,
        edit: false,
      });
    }
    setAvailability({ ...state });
  };
  useEffect(() => {
    if (physician) {
      getMutipleSlots();
    }
  }, [physician]);
  return (
    <div className="row">
      <div className="col-12">
        <div className="input-replicator">
          <span className="input-replicator-label d-block">
            Availabilities <span className="text-danger">*</span>
          </span>

          {daysArr.map((daysItem, index) => {
            return (
              <div
                className="input-replicator-fieldset d-flex justify-content-start"
                key={index}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50,
                    width: 150,
                    backgroundColor: '#e7bfad',
                  }}
                >
                  <h6>{`${daysItem}`}</h6>
                </div>
                <TimeDayRangeSelector
                  schedule={availablity[daysArr[index]]}
                  onChange={(e) => handeleChange(index, e)}
                  minuteIncrement={minuteIncrement}
                />
              </div>
            );
          })}
          <button disabled={!edited} onClick={postMultipleSLots}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSelector;
