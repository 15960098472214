import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import {
  deleteAreaOfContentService,
  updateAreaOfContentService,
  getAreaOfContentServiceByIdService,
} from '../../services/areaOfContentService';
import { useToasts } from 'react-toast-notifications';
import Spinner from '../../components/Spinner';
import { uploadImageService } from '../../services/uploadService';
import Placeholder from '../../assets/images/placeholder.webp';

import './styles.scss';

const EditAreaOfContent = (props) => {
  let history = useHistory();
  let hasDetailsFetched = false;
  const { addToast } = useToasts();

  const [areaOfContentName, setAreaOfContentName] = useState('');
  const [areaOfContentColor, setAreaOfContentColor] = useState('');
  const [areaOfContentIdentifier, setAreaOfContentIdentifier] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [hasFileModified, setFileModified] = useState(false);
  const [aocImageIdentifier, setAocImageIdentifier] = useState('');
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  const fetchAocDetails = () => {
    setLoading(true);
    getAreaOfContentServiceByIdService(areaOfContentIdentifier)
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            setLoading(false);
            setAreaOfContentName(response.data.name);
            setAreaOfContentColor(response.data.color);
            setImage(response.data?.image?.url);
            setAocImageIdentifier(response.data?.image?.id);
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);

        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const identifier = props?.match?.params?.areaOfContentIdentifier;
    setAreaOfContentIdentifier(identifier);
  }, []); // eslint-disable-line

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (
      areaOfContentIdentifier &&
      areaOfContentIdentifier !== null &&
      !hasDetailsFetched
    ) {
      hasDetailsFetched = true; // eslint-disable-line

      fetchAocDetails();
    }
  }, [areaOfContentIdentifier]); // eslint-disable-line

  const uploadImage = () => {
    setLoading(true);
    return uploadImageService(file)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);
          return res;
        }
      })
      .catch((err) => {
        // addToast('some error occurred', {
        //   appearance: 'error',
        //   autoDismiss: true,
        // });
        throw err;
      });
  };

  const handleImageSelection = (name, targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFile(file);
      setImage(URL.createObjectURL(file));
      setFileModified(true);
    }
  };

  const updateAreaOfContent = async (data) => {
    return updateAreaOfContentService(areaOfContentIdentifier, data)
      .then((res) => {
        if (res.code === 200) {
          addToast('Area of Content updated successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });
          history.push('/area-of-content', { page_current: currentPage });
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorData = err.response?.data?.errors;
        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            for (let e of errorData[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoading(false);
            }
          }
        } else if (err.response?.data?.code === 404) {
          addToast('Cannot post the data', {
            appearance: 'error',
            autoDismiss: true,
          });
          history.push('/area-of-content');
        } else {
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
        }
      });
  };

  const handleDeleteAreaOfContent = async (id) => {
    const areaOfContentId = id;
    setLoading(true);

    if (areaOfContentId) {
      deleteAreaOfContentService(areaOfContentId)
        .then((response) => {
          addToast('Area of Content has been deleted successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });

          // TODO: Handle Response Using Response Code
          history.push('/area-of-content', { page_current: currentPage });
        })
        .catch((err) => {
          setLoading(false);

          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  // validate
  const validateFormData = () => {
    let isFormValid = true;

    if ((!file || !image) && hasFileModified) {
      addToast(
        'Area of Content photo is a required field. Please upload a photo.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );

      isFormValid = false;
    } else if (!areaOfContentName) {
      addToast('Area of Content name is a required field.', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }

    return isFormValid;
  };

  const handleEditSpecialization = async () => {
    const isValid = validateFormData();

    if (isValid) {
      setLoading(true);
      if (hasFileModified) {
        uploadImage()
          .then((responseDataImage) => {
            if (responseDataImage) {
              if (responseDataImage.code === 200) {
                const dataPayload = {
                  name: areaOfContentName,
                  image_id: responseDataImage.data?.id,
                  color: areaOfContentColor,
                };

                updateAreaOfContent(dataPayload);
              }
            }
          })
          .catch((uploadErrorData) => {
            setLoading(false);
            addToast('Could not Upload Image', {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      } else {
        const dataPayload = {
          name: areaOfContentName,
          image_id: aocImageIdentifier,
          color: areaOfContentColor,
        };

        updateAreaOfContent(dataPayload);
      }
    }
  };

  return (
    <div className='editpost-wrapper'>
      <Header />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit Area of Content' />
        {loading && <Spinner />}

        <div className='editpost-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='input-wrapper-display'>
                  <img
                    src={image ? image : Placeholder}
                    className='display-image'
                    alt='Uploaded File'
                  />
                </div>

                <Input
                  type='file'
                  name='authorPhoto'
                  required='yes'
                  handleChange={handleImageSelection}
                  label='Upload Photo (1:1 Circular Corners)'
                  accept='.jpg, .png, .jpeg, .webp'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={areaOfContentName}
                  name='editAreaOfContent'
                  label='Area Of Content Name'
                  required='yes'
                  onChange={(e) => {
                    setAreaOfContentName(e.target.value);
                  }}
                  placeholder='Enter Text'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  type='color'
                  className='color-input'
                  value={areaOfContentColor}
                  name='areaOfContentColor'
                  label='Area Of Content Color'
                  placeholder='Enter Text'
                  required='yes'
                  onChange={(e) => {
                    setAreaOfContentColor(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={async () => {
                    await handleEditSpecialization(
                      props?.match?.params?.areaOfContentIdentifier
                    );
                  }}
                />
              </div>

              <div className='col-12 col-sm-6 text-right'>
                <SecondaryButton
                  value='Delete Area Of Content'
                  className='danger-button'
                  handleClick={() =>
                    handleDeleteAreaOfContent(
                      props?.match?.params?.areaOfContentIdentifier
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAreaOfContent;
