import './styles.scss';
import { useEffect, useState } from 'react';

const Checkbox = (props) => {
  const [check, setChecked] = useState(false);

  useEffect(() => {
    if (props.availabilityValues && props.availabilityValues.length) {
      const index = props.availabilityValues.findIndex((obj) => obj.hasOwnProperty(props.name));
      if (index !== -1) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [props.availabilityValues]); // eslint-disable-line

  return (
    <div className='input-wrapper'>
      <label className='input-label' htmlFor={props.label}>
        {props.availabilityValues ? (
          <input
            type='checkbox'
            name={props.name}
            onChange={(event) => props.handleChange(props.name, event.target.checked)}
            className={props.className ? `input-checkbox ${props.className}` : `input-checkbox`}
            checked={check}
            key={props.availabilityValues.length}
            {...props}
          />
        ) : (
          <input
            type='checkbox'
            name={props.name}
            onChange={(event) => props.handleChange(props.name, event.target.checked)}
            className={props.className ? `input-checkbox ${props.className}` : `input-checkbox`}
            {...props}
          />
        )}

        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
