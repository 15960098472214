import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllMessagesLogs = (params) => {
  return axiosInstance.get(BackendConstants.messagesLogs, {
    params: { ...params, limit: 100 },
  });
};

export const optinUsers = (data) => {
  return axiosInstance.post(BackendConstants.optinUsers, data);
};
