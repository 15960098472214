import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllQuestionsService = (params) => {
  return axiosInstance.get(BackendConstants.questions, { params: params });
};

export const addNewQuestionService = (data, params) => {
  return axiosInstance.post(BackendConstants.questions, data, {params: params});
};

export const getQuestionByIdService = (questionId, params) => {
  return axiosInstance.get(BackendConstants.questionById.replace(':id', questionId),{params: params});
};

export const updateQuestionService = (questionId, data) => {

  return axiosInstance.patch(BackendConstants.questionById.replace(':id', questionId), data);
};

export const deleteQuestionService = (questionId) => {
  return axiosInstance.delete(BackendConstants.deleteQuestionById.replace(':id', questionId));
};