import ReducerConstants from '../common/reducerConstants';

const initalstate = {
  authData: null,
  authToken: null,
};

function authReducer(state = initalstate, action) {
  switch (action.type) {
    case ReducerConstants.authLogin:
      return {
        ...state,
        authData: action.payload.data,
        authToken: action.payload.meta.token,
      };

    case ReducerConstants.authLogout:
      return {
        ...state,
        authData: null,
        authToken: null,
      }

    default:
      return state;
  }
}

export default authReducer;
