import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import {
  updateMembership,
  getMembership,
} from '../../services/membershipService';
import { useToasts } from 'react-toast-notifications';
import Spinner from '../../components/Spinner';
import './styles.scss';

const EditTopic = (props) => {
  let history = useHistory();
  const { addToast } = useToasts();

  const [autoMembershipAmount, setAutoMembershipAmount] = useState(0);

  const [duration, setDuration] = useState(0);
  const [fee, setFee] = useState(0);
  const [persentageOff, setPercentageOff] = useState(0);
  const [defaultMaxAppointments, setDefaultMaxAppointments] = useState(0);

  //create input field

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMembership()
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);
          setAutoMembershipAmount(res.data.activateAutoMembershipAmount);
          setDuration(res.data.membershipDuration);
          setFee(res.data.membershipFees);
          setPercentageOff(res.data.membershipPercentageOff);
          setDefaultMaxAppointments(res.data.defaultMaxAppointments);
        }
      })
      .catch((err) => {
        addToast(err.repsonse, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  }, []); // eslint-disable-line

  const validateFormSubmission = () => {
    let isFormValid = true;
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    const numberRegex = /^\d+$/;
    // if (autoMembershipAmount === "") {
    //   addToast("Auto Membership cannot be empty", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });

    //   isFormValid = false;
    // } else
    if (!numberRegex.test(autoMembershipAmount)) {
      addToast('Auto membership Amount must be and number', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (fee === '') {
      addToast('Membership Fee cannot be empty', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!floatRegex.test(fee)) {
      addToast('Membership Fee should be a number or a decimal', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (duration === '') {
      addToast('Duration  cannot be empty', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!numberRegex.test(duration)) {
      addToast('Membership duration should be a number', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (persentageOff === '') {
      addToast('perentage off cannot be empty', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!floatRegex.test(persentageOff)) {
      addToast('Percentage off should be a number or a decimal', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (defaultMaxAppointments === '') {
      addToast('Default max appointments cannot be empty', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (!numberRegex.test(defaultMaxAppointments)) {
      addToast('Default max appointments should be a number', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (persentageOff > 90) {
      addToast('Percentage off should not be greater than 90', {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    } else if (duration < 0 || autoMembershipAmount < 0) {
      addToast("Values can't be negative, Enter Correct values", {
        appearance: 'error',
        autoDismiss: true,
      });

      isFormValid = false;
    }
    return isFormValid;
  };
  const handleEditMembership = () => {
    setLoading(true);

    const isValidate = validateFormSubmission();

    if (isValidate) {
      const dataPayload = {
        activate_auto_membership_amount: 1000,
        membership_duration: duration,
        membership_fees: fee,
        membership_percentage_off: persentageOff,
        max_cc_appointments_per_user: defaultMaxAppointments,
      };
      updateMembership(dataPayload)
        .then((res) => {
          if (res.code === 200) {
            addToast('Membership updated successfully.', {
              appearance: 'success',
              autoDismiss: true,
            });
            history.push('/membership');
          }
          setLoading(false);
        })
        .catch((err) => {
          const errorData = err.response?.data?.errors;
          if (errorData && Object.keys(errorData).length > 0) {
            for (let key in errorData) {
              for (let e of errorData[key]) {
                addToast(e, {
                  appearance: 'error',
                  autoDismiss: true,
                });
                setLoading(false);
              }
            }
          } else {
            addToast(err.response?.data?.message, {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='edittopic-wrapper'>
      <Header />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Edit Membership' />
        {loading && <Spinner />}

        <div className='edittopic-wrapper-content'>
          <div className='form-section'>
            <div className='row'>
              {/* <div className="col-12 col-sm-6 col-md-4">
                <Input
                  value={autoMembershipAmount}
                  name="editAutoMembershipAmount"
                  label="Auto Membership Amount"
                  onChange={(e) => {
                    setAutoMembershipAmount(e.target.value);
                  }}
                  placeholder="Enter Number"
                  required="yes"
                />
              </div> */}
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={fee}
                  name='editFee'
                  label='Membership Fee'
                  onChange={(e) => {
                    setFee(e.target.value);
                  }}
                  placeholder='Enter Number'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={persentageOff}
                  name='editPercentageOff'
                  label='Membership Discount / Percentage Off'
                  onChange={(e) => {
                    setPercentageOff(e.target.value);
                    console.log(persentageOff);
                  }}
                  placeholder='Enter Number'
                  required='yes'
                />
              </div>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={defaultMaxAppointments}
                  name='editDefaultMaxAppointments'
                  label='Default Max Appointments per user'
                  onChange={(e) => {
                    setDefaultMaxAppointments(e.target.value);
                  }}
                  placeholder='Enter Number'
                  required='yes'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <Input
                  value={duration}
                  name='editDuration'
                  label='Membership Duration'
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  placeholder='Enter Number'
                  required='yes'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-sm-6 col-md-4'>
                <PrimaryButton
                  value='Save'
                  className='spaced-button'
                  handleClick={() => {
                    handleEditMembership();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTopic;
