import {useState} from'react'
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MultiDropdownSelect = (props) => {
		const {handleChange,selectedList}=props
		const [showList, updateShowList]= useState(false);
    return(
        <div className={props.wrapperClass ? `input-Multidropdown ${props.wrapperClass}` : 'input-Multidropdown'}>
            {props.label && <label className='input-Multidropdown-Multilabel'>{props.label}
            {props.required ==='yes' ? <span className="text-danger">*</span>:''}
            </label>}
            <div 
            className='d-flex flex-row align-items-center justify-content-between input-Multidropdown-field-custom'
            onClick={()=>updateShowList(!showList)}
            >
            <lable className=''>{props.placeholder || 'Select a value'}</lable>
            <FontAwesomeIcon 
                icon={faChevronDown} 
                className='ml-3 input-Multidropdown-Multiicon'                 
            />
			</div>
						
            <ul 
                id="scrollbar-style-multiselect"
                className={props.fieldClass ?
                `scrollbar-width input-Multidropdown-Multifield-Custom ${props.fieldClass} ${!showList && 'no-show-list-items'}` : 
            	`scrollbar-width input-Multidropdown-Multifield-Custom ${!showList && 'no-show-list-items'}`}
            >
                {props.options.map((item, ind)=>{
                    // const val = item.id || item.value;
                    // const index= selectedList.findIndex(el=>el=== val);
                    const index= selectedList.findIndex(el=>el=== item.id);
                    return (
                        <li key={ind} value={item.value} className='input-Multidropdown-Multilist'>
                            <input type='checkbox'   
                                name={item.name}
                                checked={index===-1? false: true}
                                onChange={()=>handleChange(item)}
                                // onChange={(event) => props.handleChange(props.name, event.target.checked)}                 
                        />
                        {item.name}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default MultiDropdownSelect;