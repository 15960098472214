import { useEffect, useState } from 'react';

import './styles.scss';

const Input = (props) => {
  const [dayValue, setDayValue] = useState('');

  useEffect(() => {
    if (props.availabilityValues?.length) {
      props.availabilityValues.forEach((day) => {
        const name = Object.keys(day)[0].charAt(0).toUpperCase() + Object.keys(day)[0].slice(1);
        if (name === props.name) {
          setDayValue(Object.values(day)[0]);
        }
      });
    }
  }, [props.availabilityValues]); // eslint-disable-line

  return (
    <div className='input-wrapper'>
      {props.label && (
        <label className='input-label' htmlFor={props.name}>
          {props.label}
          {props.required === 'yes' ? <span className='text-danger'>*</span> : ''}
        </label>
      )}

      {props.availabilityValues ? (
        <input
          name={props.name}
          defaultValue={dayValue}
          type={props.type || 'text'}
          placeholder={props.placeholder || 'Input text here'}
          onChange={(event) => props.handleChange(props.name, event.target.value, event)}
          className={props.className ? `input-field  ${props.className}` : `input-field`}
          {...props}
        />
      ) : (
        <input
          name={props.name}
          value={props.value}
          type={props.type || 'text'}
          placeholder={props.placeholder || 'Input text here'}
          onChange={(event) => props.handleChange(props.name, event.target.value, event)}
          className={props.className ? `input-field ${props.className}` : `input-field`}
          {...props}
        />
      )}
    </div>
  );
};

export default Input;
