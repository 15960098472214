import { parseTableData } from './parser';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getAllBlogsService } from '../../services/blogsService';
import { getAllCompaniesService } from '../../services/companyService';
import { getAllUsersService } from '../../services/userService';
import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import { useToasts } from 'react-toast-notifications';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const BlogListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sendSearchData, setSendSearchData] = useState('');
  const [companyOptions, setCompanyOptions] = useState([]);
  const [slugs, setSlugs] = useState([]);

  const columnHeaders = [
    'S. No.',
    'Title',
    'Topic',
    'Author',
    'Status',
    'Aoc',
    'Company',
    'Created',
    'Action',
    'Domain Link',
  ];

  const getAllCompanies = () => {
    setLoading(true);

    getAllCompaniesService()
      .then((response) => {
        if (response) {
          setLoading(false);

          if (response.code === 200) {
            let data = response.data.results;
            let l = [];

            data.map((ele) => {
              l.push({
                name: ele.name,
                subdomain: ele.subDomain,
              });
              return null;
            });

            setCompanyOptions([...l]);
          }
        }
      })
      .catch((errorData) => {
        setLoading(false);

        addToast(errorData.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  useEffect(() => {
    getAllCompanies();
    // const params = { limit: 1 };
    // checkAuthStateWithUsersApi(params);
  }, []);

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selectedBlogData) => {
    const selectedBlogIdentifier = selectedBlogData.id;
    history.push(`/blogs/edit/${selectedBlogIdentifier}`, {
      page_number: currentPage,
    });
  };

  const checkAuthStateWithUsersApi = (params) => {
    getAllUsersService(params)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getAllBlogs = (params) => {
    setLoading(true);

    getAllBlogsService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          let l = [];
          response.data.results.map((e) => {
            l.push(e.slug);
          });

          setSlugs([...l]);

          setBlogs(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };
  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllBlogs({ page: pageNumber, include: 'topic,author' });
  };
  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props && props.location?.state?.page_current,
        include: 'topic,author',
      };
      getAllBlogs(params);
      props.location.state.page_current = null;
    } else {
      const params = {
        page: props && props.location?.state?.page_current,
        include: 'topic,author',
      };
      getAllBlogs(params);
    }
  }, []); // eslint-disable-line

  const changeSearchContent = (e) => {
    setSearch(e.target.value);
  };
  const searchKeyPress = (e) => {
    if (e.which === 13) {
      if (search !== '') {
        setSendSearchData(search);
        getAllBlogs({ keyword: search });
      }
    }
  };

  const handleSearchOnClick = () => {
    getAllBlogs({ keyword: search });
  };

  useEffect(() => {
    if (search === '') {
      getAllBlogs();
    }
  }, [search]);

  const handleAddBlogsCta = () => {
    history.push('/blogs/create', { page_number: currentPage });
  };

  return (
    <div className='blogs-wrapper' id='testScroll'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Blogs' />

        <div className='blogs-wrapper-content'>
          <div className='blogs-wrapper-header'>
            <div className='search'>
              <input
                type='text'
                placeholder='Search'
                className='blog-search'
                onChange={changeSearchContent}
                value={search}
                onKeyPress={searchKeyPress}
              ></input>
              <button
                onClick={() => (search !== '' ? handleSearchOnClick() : null)}
                className='search-btn'
              >
                Search
              </button>
            </div>
            <div className='create-cta' onClick={handleAddBlogsCta}>
              Create New Blog
            </div>
          </div>

          {blogs.length ? (
            <Table
              pagination={true}
              columnContent={blogs}
              actionAvailable={true}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              showLinkCopy={true}
              companyOptions={companyOptions}
              slugs={slugs}
              nextPage={() => {
                setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
                getAllBlogs({ page: currentPage + 1, include: 'topic,author' });
              }}
              prevPage={() => {
                setCurrentPage((oldCurrentPage) => oldCurrentPage - 1);
                getAllBlogs({ page: currentPage - 1, include: 'topic,author' });
              }}
            />
          ) : (
            <EmptyState title='No blogs found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default BlogListing;
