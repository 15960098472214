import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllSpecializationsService = (params) => {
  return axiosInstance.get(BackendConstants.specializations, {
    params: params,
  });
};

export const addNewSpecializationService = (data) => {
  return axiosInstance.post(BackendConstants.specializations, data);
};

export const getSpecializationByIdService = (specializationId) => {
  return axiosInstance.get(
    BackendConstants.specializationById.replace(':id', specializationId)
  );
};

export const updateSpecializationService = (specializationId, data) => {
  return axiosInstance.patch(
    BackendConstants.specializationById.replace(':id', specializationId),
    data
  );
};

export const deleteSpecializationService = (specializationId) => {
  return axiosInstance.delete(
    BackendConstants.deleteSpecializationById.replace(':id', specializationId)
  );
};

export const updateSpecializationStatusService = (
  specializationIdentifier,
  data
) => {
  return axiosInstance.patch(
    BackendConstants.changeSpecializationStatusById.replace(
      ':id',
      specializationIdentifier
    ),
    data
  );
};
