import './styles.scss';

const DropdownSelect = (props) => {
  return (
    <div
      className={
        props.wrapperClass
          ? `input-dropdown ${props.wrapperClass}`
          : 'input-dropdown'
      }
    >
      {props.label && (
        <label className='input-dropdown-label'>
          {props.label}
          {props.required === 'yes' ? (
            <span className='text-danger'>*</span>
          ) : (
            ''
          )}
        </label>
      )}

      <select
        onChange={(event) => props.handleChange(event.target.value)}
        className={
          props.fieldClass
            ? `input-dropdown-field ${props.fieldClass}`
            : 'input-dropdown-field'
        }
        {...props}
        disabled={props.disabled}
      >
        <option
          value=''
          key={props.options && props.options.length + 1}
          className='input-dropdown-option'
        >
          Select a value
        </option>
        {props.options &&
          props.options.map((item, index) => {
            return (
              <option
                key={index}
                value={item.value}
                className='input-dropdown-option'
              >
                {item.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default DropdownSelect;
