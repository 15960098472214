import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { loginService } from '../../services/authService';
import { loginAction } from '../../actions/authActions';

import ReCAPTCHA from 'react-google-recaptcha';

import Input from '../../components/Input';
import Header from '../../components/Header';
import PrimaryButton from '../../components/PrimaryButton';

import './styles.scss';

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState('admin@pfh.com');
  const [userPassWord, setPassWord] = useState('');
  const [userRecaptcha, setRecaptcha] = useState(false);

  const authToken = useSelector((state) => state.auth.authToken);

  const { addToast } = useToasts();

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'userName') {
      setUserName(fieldValue);
    } else {
      setPassWord(fieldValue);
    }
  };

  const handleCaptchaUpdate = (value) => {
    if (value) {
      setRecaptcha(true);
    } else {
      setRecaptcha(false);
    }
  };

  const validateLoginSubmit = () => {
    let isFormValid = true;

    if (userName === null || userName === '') {
      isFormValid = false;

      addToast('Username is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    if (userPassWord === null || userPassWord === '') {
      isFormValid = false;

      addToast('Password is a required field. Please provide a value.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    return isFormValid;
  };

  useEffect(() => {
    if (authToken) {
      history.push('/blogs');
    }
  }, [authToken]);// eslint-disable-line

  const handleLoginSubmit = () => {
    if (userRecaptcha) {
      const isFormValid = validateLoginSubmit();

      if (isFormValid) {
        const dataPayload = {
          email: userName,
          password: userPassWord,
        };

        loginService(dataPayload)
          .then((responseData) => {
            if (responseData) {
              if (responseData.data) {
                dispatch(loginAction(responseData));
              }
            }
          })
          .catch((err) => {
            const errorData = err.response?.data?.errors
              if (errorData && Object.keys(errorData).length > 0) {
                  for (let key in errorData) {
                  for (let e of errorData[key]) {
                   addToast(e, {
                      appearance: 'error',
                      autoDismiss: true,
                    });
                }
              }
            } else{
              let message = err.response?.data?.message;
              addToast(message, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          });
      }
    } else {
      addToast('Recaptcha must be solved before submitting.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <div className='login-wrapper'>
      <Header />

      <div className='flexible-container flexible-container--centered'>
        <div className='login-wrapper-content'>
          <div className='wrapper-content-header'>
            <h1 className='wrapper-content-title'>Administrator Panel</h1>
          </div>

          <div className='wrapper-content-body'>
            <Input name='userName' placeholder='Username' value={userName} handleChange={handleFieldUpdate} />

            <Input
              name={'passWord'}
              type={'password'}
              value={userPassWord}
              placeholder={'Password'}
              handleChange={handleFieldUpdate}
            />

            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_V2_KEY} onChange={handleCaptchaUpdate} />

            <PrimaryButton name='loginSubmit' className='button-submit' value='Login' handleClick={handleLoginSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
