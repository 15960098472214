import { axiosInstance } from './index';

import BackendConstants from '../common/backendConstants';

export const getAllAreaOfContentService = (params) => {
  return axiosInstance.get(BackendConstants.areaOfContent, { params: params });
};

export const addNewAreaOfContentService = (data) => {
  return axiosInstance.post(BackendConstants.areaOfContent, data);
};

export const getAreaOfContentServiceByIdService = (contentId) => {
  return axiosInstance.get(BackendConstants.areaOfContentById.replace(':id', contentId));
};

export const updateAreaOfContentService = (contentId, data) => {
  return axiosInstance.patch(BackendConstants.areaOfContentById.replace(':id', contentId), data);
};

export const deleteAreaOfContentService = (contentId) => {
  return axiosInstance.delete(BackendConstants.deleteAreaOfContentById.replace(':id', contentId));
};