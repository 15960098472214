import moment from 'moment';

export const parsePaymentsTable = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination?.current_page;

  if (inputData) {
    inputData?.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 10 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.name = arrayItem?.customerFullName;
      renderingObject.email = arrayItem?.customerEmail;
      renderingObject.phone = arrayItem?.customerPhone;
      renderingObject.coupon_code = arrayItem?.couponCode;
      renderingObject.amount = arrayItem?.amount;
      renderingObject.doctorName = arrayItem?.doctorFullName;
      renderingObject.type = arrayItem?.type;
      renderingObject.date =
        moment(arrayItem.createdAt).format('DD/MM/YYYY') || 'N/A';
      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
