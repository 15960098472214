import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  getSingleAppointmentDetailsService,
  updateAppointmentsStatus,
  deleteAppointment,
  getAvailableDate,
} from '../../services/appointmentServices';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import './styles.scss';
import SecondaryButton from '../../components/SecondaryButton';
import Schedule from './Schedule';
// import DropdownSelect from '../../components/DropdownSelect';
import AppointmentStatusDropdownSelect from '../../components/AppointmentStatusDropdownSelect';
import { Modal } from 'react-bootstrap';
import * as moment from 'moment';
import OrderPaymentsTable from '../../components/OrderPaymentsTable';
const EditAppointment = (props) => {
  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [selectedTab]);

  const getCurrentDate = () => {
    const today = new Date();

    const dd = String(today.getDate() + 0).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  };

  const getDoctorAvailableDate = async () => {
    const today = moment().utc(true).format('YYYY-MM-DD');
    const res = await getAvailableDate(props?.location?.state?.doctor_id);
    return res?.data || today;
  };

  useEffect(async () => {
    const availableDate = await getDoctorAvailableDate();
    handleOnChange({ name: 'date', value: availableDate });
  }, [props?.location?.state?.doctor_id]);

  const [data, setData] = useState({
    date: getDoctorAvailableDate(),
    time: {},
  });

  const handleOnChange = ({ name, value }) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const history = useHistory();
  let hasDetailsFetched = false;

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [userCity, setUserCity] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [appointmentStatus, setAppointmentStatus] = useState('ACTIVE');
  const [appointmentIdentifier, setAppointmentIdentifier] = useState('');
  const [consultationStatus, setConsultationStatus] = useState();
  const [appointmentDate, setAppointmentDate] = useState('');
  const [bookingDate, setBookingDate] = useState('');
  const [logs, setLogs] = useState([]);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentID, setPaymentID] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [appointmentData, setAppointmentData] = useState(null);
  const [historyForm, setHistoryForm] = useState(null);
  const [feedbackForm, setFeedbackForm] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [reschedule, setReschedule] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [reasonForCancellation, setReasonForCancellation] = useState('');
  const [showInactiveConfirm, setShowInactiveConfirm] = useState(false);
  // console.count("papa hai papa hai papa hai")
  const userData = {
    userName: 'Admin',
  };

  //paging
  useEffect(() => {
    setCurrentPage(props.location.state.page_number);
  }, []);

  useEffect(() => {
    const testimonialID = props?.match?.params?.appointmentIdentifier;
    setAppointmentIdentifier(testimonialID);
  }, []);

  // Acting ComponentDidUpdate
  useEffect(() => {
    if (
      appointmentIdentifier &&
      appointmentIdentifier !== null &&
      !hasDetailsFetched
    ) {
      hasDetailsFetched = true; // eslint-disable-line
      fetchPrefillData();
    }
  }, [appointmentIdentifier]);

  const fetchPrefillData = () => {
    setLoading(true);

    getSingleAppointmentDetailsService(appointmentIdentifier)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const { data } = response;
          setAppointmentData(data);
          setDoctorName(data?.doctor[0]?.name || data?.doctor.name);
          setUserName(data?.clientDetails?.name);
          setUserCity(data?.clientDetails?.address);
          setUserEmail(data?.clientDetails.email);
          setUserPhone(data?.clientDetails?.mobile);
          setPaymentID(data?.order?.id);
          setPaymentAmount(data?.order?.totalAmount);
          setCouponCode(data?.order?.promoCode ? data.order.promoCode : 'NA');

          const startTime = moment.utc(data?.startTime).format('HH:mm');
          const endTime = moment.utc(data?.endTime).format('HH:mm');
          const time = `${startTime} - ${endTime}`;
          setAppointmentTime(time);
          setAppointmentDate(moment(data?.startTime).format('DD/MM/YYYY'));
          setReasonForCancellation(data?.rescheduleReason);
          setAppointmentStatus(data?.status);
          setConsultationStatus({
            isUpcoming: data?.isUpcoming,
            consultationStatus: data?.consultationStatus,
          });
          setHistoryForm(data?.historyFormData);
          setFeedbackForm(data?.feedbackFormData);
          setBookingDate(data?.bookingDate);
          console.log("line 166", userName)

          setLogs(data?.logs);

        }
      })
      .catch((err) => {
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });

        setLoading(false);
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'reasonForCancellation') {
      setReasonForCancellation(fieldValue);
    }
  };

  const handleDeleteAppointment = (status, identifier) => {
    const appointmentIdIdentifier = identifier;
    if (appointmentIdIdentifier) {
      deleteAppointment(appointmentIdIdentifier, reasonForCancellation);
      setAppointmentStatus('INACTIVE');
      addToast('Appointments status has been changed successfully.', {
        appearance: 'success',
        autoDismiss: true,
      });

      history.push('/appointments');
    }
  };

  const handleAppointmentStatus = (value) => {
    console.log('===', value, 'returning 0');
    if (value === 'Completed') {
      return 1;
    } else if (value === 'No Show') {
      return 2;
    }
    return 0;
  };

  const handleconsultationStatus = (eventValue) => {
    let payload;
    if (eventValue == 1) {
      payload = {
        id: appointmentIdentifier,
        status: 1,
      };
    } else {
      //reverse the status
      payload = {
        id: appointmentIdentifier,
        status: 2,
      };
    }

    updateAppointmentsStatus(payload);
    setConsultationStatus((prev) => ({
      ...prev,
      consultationStatus: consultationStatusValue[+eventValue],
    }));
  };
  const consultationVisibleStatusValue = {
    'Completed': 1,
    'No Show': 2,
  };
  const consultationStatusValue = {
    1: 'Completed',
    2: 'No Show',
  };

  const consultationVisibleStatus = [
    {
      name: 'COMPLETED',
      value: 1,
    },
    {
      name: 'NO SHOW',
      value: 2,
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <Modal
        show={showInactiveConfirm}
        onHide={() => showInactiveConfirm(false)}
        size="lg"
        className="inactive-confirm-modal"
      >
        <Modal.Body>
          <div className="inactive-confirm-modal-body">
            <p>Are you sure you like to make the appointment inactive?</p>
            <button
              onClick={() => {
                setShowInactiveConfirm(false);
              }}
            >
              No
            </button>
            <button
              onClick={() => {
                handleDeleteAppointment(
                  appointmentStatus,
                  appointmentIdentifier,
                );
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="flexible-container">
        <SubHeader title="Edit Appointment Details" />

        <div className="testimonial-wrapper-content">
          <div className="form-section">
            <div className="row">
              <div className="col-12 col-sm-6">
                <Input
                  name="userName"
                  value={userName}
                  label={'Customer Name'}
                  placeholder="Customer Name"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="doctorName"
                  value={doctorName}
                  label={'Doctor Name'}
                  placeholder="Doctor Name"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              {consultationStatus?.isUpcoming ||
              appointmentStatus === 'INACTIVE' ? (
                <></>
              ) : (
                <div className="col-12 col-sm-6">
                  <AppointmentStatusDropdownSelect
                    label={'Appointment Status'}
                    appointmentIdentifier={appointmentIdentifier}
                    consultationStatus={handleAppointmentStatus(
                      consultationStatus?.consultationStatus,
                    )}
                  />
                </div>
              )}
              <div className="col-12 col-sm-6">
                <Input
                  name="appointmentDate"
                  value={appointmentDate}
                  label={'Appointment Date'}
                  placeholder="Appointment Date"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="appointmentID"
                  value={props?.match?.params?.appointmentIdentifier}
                  label={'Appointment ID'}
                  placeholder="Appointment ID"
                  handleChange={() => {}}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="bookingDate"
                  value={bookingDate}
                  label={'Booking Date'}
                  placeholder="Booking Date"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="appointmentTime"
                  value={appointmentTime}
                  label={'Appointment Time'}
                  placeholder="Appointment Time"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="paymentAmount"
                  value={paymentAmount}
                  label={'Payment Amount'}
                  placeholder="Payment Amoun"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="paymentID"
                  value={paymentID}
                  label={'Payment ID'}
                  placeholder="Payment ID"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="couponCode"
                  value={couponCode}
                  label={'Coupon Code'}
                  placeholder="Coupon Code"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>

              <div className="col-12 col-sm-6">
                <Input
                  name="userEmail"
                  value={userEmail}
                  label={'User Email'}
                  placeholder="User Email"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="userPhone"
                  value={userPhone}
                  label={'User Phone'}
                  placeholder="User Phone"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  name="city"
                  value={userCity}
                  label={'Customer City'}
                  placeholder="Customer City"
                  handleChange={handleFieldUpdate}
                  disabled={true}
                  required="yes"
                />
              </div>

              {
                logs.map((log) => {
                  return (
                    <div className="col-12 col-sm-6">
                      <Input
                        name={logs.actionType}
                        value={log.actionMessage}
                        label={log.actionType === 'reschedule' ? 
                        log.actionBy === 'superAdmin' ? 'Rescheduled by Admin' : 'Rescheduled by User' : 
                        log.actionBy === 'superAdmin' ? 'Cancelled By Admin' : 'Cancelled By User'}
                        placeholder="Reschedule"
                        handleChange={handleFieldUpdate}
                        disabled={true}
                        required="yes"
                      />
                    </div>
                  )
                })
              }

              <div className="col-12 ">
                <OrderPaymentsTable
                  status={appointmentData?.status}
                  payments={appointmentData?.order?.payments}
                  orderId={appointmentData?.order?.id}
                />
              </div>
            </div>
            <div className="row">
              {historyForm && historyForm.type ? (
                <div className="col-12 col-sm-6">
                  <Input
                    name="historyFormSubmitted"
                    value={
                      historyForm !== null ||
                      historyForm !== undefined ||
                      historyForm !== {} ||
                      historyForm !== ''
                        ? 'YES'
                        : 'NO'
                    }
                    label={'History Form Submitted?'}
                    placeholder=""
                    disabled={true}
                    required="no"
                  />
                </div>
              ) : null}
              {feedbackForm && feedbackForm?.type ? (
                <div className="col-12 col-sm-6">
                  <Input
                    name="feedbackFormSubmitted"
                    value={
                      feedbackForm !== null ||
                      feedbackForm !== undefined ||
                      feedbackForm !== {} ||
                      feedbackForm !== ''
                        ? 'YES'
                        : 'NO'
                    }
                    label={'Feedback Form Submitted?'}
                    placeholder=""
                    disabled={true}
                    required="no"
                  />
                </div>
              ) : null}
            </div>

            <div className="row">
              {consultationStatus?.isUpcoming && !reschedule && (
                <div className="col-6">
                  <SecondaryButton
                    value="Reschedule"
                    handleClick={() => setReschedule(true)}
                    className={
                      appointmentStatus !== 'INACTIVE'
                        ? cancel
                          ? 'disabled'
                          : 'button-primary'
                        : 'd-none'
                    }
                    disabled={cancel}
                  />
                </div>
              )}
              {consultationStatus?.isUpcoming && !cancel && !reschedule && (
                <div className="col-6">
                  <SecondaryButton
                    value="Make Inactive"
                    handleClick={() => setCancel(true)}
                    className={
                      appointmentStatus !== 'INACTIVE'
                        ? reschedule
                          ? 'disabled'
                          : 'danger-button'
                        : 'd-none'
                    }
                    disabled={reschedule}
                  />
                </div>
              )}
            </div>

            {reschedule && (
              <div className="row mb-4">
                <div className="col-12 col-sm-6">
                  <Schedule
                    setReschedule={setReschedule}
                    appointmentId={appointmentIdentifier}
                    data={data}
                    handleOnChange={handleOnChange}
                    setSelectedTab={setSelectedTab}
                    getCurrentDate={getCurrentDate}
                    doctorId={props.location.state.doctor_id}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  {!cancel && (
                    <SecondaryButton
                      value="Make Inactive"
                      handleClick={() => setCancel(true)}
                      className={
                        appointmentStatus !== 'INACTIVE'
                          ? `danger-button ${!reschedule ? 'ml-5' : 'disabled'}`
                          : 'd-none'
                      }
                      disabled={reschedule}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4">
              {appointmentStatus !== 'INACTIVE' ? (
                <>
                  {cancel && (
                    <>
                      <Input
                        name="reasonForCancellation"
                        value={reasonForCancellation}
                        label={'Reason for making inactive'}
                        placeholder="Reason for making inactive"
                        handleChange={handleFieldUpdate}
                        required="yes"
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <SecondaryButton
                          value="Cancel"
                          handleClick={() => setCancel(false)}
                          className={'button-primary'}
                        />
                        <SecondaryButton
                          value={
                            appointmentStatus !== 'INACTIVE'
                              ? 'MAKE INACTIVE'
                              : 'MAKE ACTIVE'
                          }
                          className={
                            appointmentStatus !== 'INACTIVE'
                              ? 'danger-button'
                              : 'd-none'
                          }
                          disabled={
                            appointmentStatus === 'INACTIVE' ||
                            !reasonForCancellation
                              ? true
                              : false
                          }
                          handleClick={() => setShowInactiveConfirm(true)}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditAppointment;
