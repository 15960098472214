import moment from 'moment';

export const parseTableData = (inputData,pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let aocsArray = [];
      arrayItem?.aocs?.map((item)=>{
        aocsArray.push(item.name);
      })
      let aocsValue = aocsArray.join(',')
      let renderingObject = {};
      let index = (((current_page - 1 ) * 20) + (arrayIndex + 1));
      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.code = arrayItem.membershipCode;
      renderingObject.name = arrayItem.name || 'N/A';
      renderingObject.subdomain = arrayItem.subDomain || 'N/A';
      renderingObject.aocs = aocsValue || 'N/A';
      renderingObject.status = arrayItem.status || 'N/A';
      renderingObject.created = moment(arrayItem.createdAt).format('DD/MM/YYYY hh:mm A') || 'N/A';
      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
