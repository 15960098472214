import moment from 'moment';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const appointmentStatus = {
  'ACTIVE': {
    true: 'UPCOMING',
    false: 'PREVIOUS',
  },
  'RESCHEDULED': {
    true: 'UPCOMING',
    false: 'PREVIOUS',
  },
  'INACTIVE': {
    true: 'CANCELLED',
    false: 'CANCELLED',
  },
};
export const parseTableData = (inputData, pagination, show = () => {}) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;
  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 20 + (arrayIndex + 1);

      renderingObject.sno = index;
      //uuid
      renderingObject.id = arrayItem.id;
      renderingObject.customer_name = arrayItem.clientDetails.name;
      renderingObject.customer_email = arrayItem.clientDetails.email;
      renderingObject.customer_city = arrayItem.clientDetails.address;
      renderingObject.doctor_name =
        arrayItem.doctor[0]?.name || arrayItem.doctor.name;
      renderingObject.specialization =
        arrayItem.doctorDetails?.specialization?.name;
      const startTime = moment.utc(arrayItem.startTime).format('HH:mm');
      const endTime = moment.utc(arrayItem.endTime).format('HH:mm');
      const time = `${startTime} - ${endTime}`;
      renderingObject.date =
        moment(arrayItem.startTime).format('DD/MM/YYYY') + '\n' + time;
      // renderingObject.time = time;
      // renderingObject.status = arrayItem.status;
      renderingObject.doctor_id =
        arrayItem.doctor[0]?.id || arrayItem.doctor.id;

      renderingObject.appointment_status =
        appointmentStatus[arrayItem.status][arrayItem.isUpcoming];
      if (
        !arrayItem.isUpcoming &&
        (arrayItem.status === 'ACTIVE' || arrayItem.status === 'RESCHEDULED')
      ) {
        renderingObject.consultation_status = arrayItem.consultationStatus;
      }
      renderingObject.coupon_code = arrayItem.order?.promoCode;
      renderingObject.final_price = arrayItem.order?.payableAmount;
      renderingObject.feedback = arrayItem.feedbackFormData?.type ? (
        <button
          type="button"
          className="flexible-table-button flexible-table-button-width"
          variant="primary"
          onClick={() => show(arrayItem)}
          title="View Users"
        >
          <FontAwesomeIcon icon={faEye} color="#4CA746" />
        </button>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon icon={faTimes} color="#DC3545" />
        </div>
      );

      renderingObject.history = arrayItem.historyFormData?.type ? 'Yes' : 'No';

      renderingObject.booking_date = moment(
        arrayItem.bookingDate,
        'DD-MM-YYYY',
      ).format('DD/MM/YYYY');

      resultsArr.push(renderingObject);
    });
  }
  return resultsArr;
};

const agreeData = [
  {
    label: 'Strongly Agree',
    value: 'strongly-agree',
  },
  {
    label: 'Agree',
    value: 'agree',
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
  {
    label: 'Disagree',
    value: 'disagree',
  },
  {
    label: 'Strongly Disagree',
    value: 'strongly-disagree',
  },
];

const radioData = [
  {
    label: 'Very dissatisfied',
    value: 'very-dissatisfied',
    score: 1,
  },
  {
    label: 'Dissatisfied',
    value: 'dissatisfied',
    score: 2,
  },
  {
    label: 'It was okay',
    value: 'it-was-okay',
    score: 3,
  },
  {
    label: 'Satisfied',
    value: 'satisfied',
    score: 4,
  },
  {
    label: 'Very Satisfied',
    value: 'very-satisfied',
    score: 5,
  },
];

export const serivcesData = [
  {
    label: 'Mental Health',
    value: 'mental-health',
  },
  {
    label: 'Nutrition and Fitness',
    value: 'nutrition-and-fitness',
  },
  {
    label: 'Hair and Skincare',
    value: 'hair-and-skincare',
  },
  {
    label: 'Sexual Health Concerns',
    value: 'sexual-health-concerns',
  },
  {
    label: 'Fertility Realted Concerns',
    value: 'fertility-realted-concerns',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const titleMap = [
  {
    label: 'I feel the expert was non-judgemental and created a safe space',
    value: 'agreeAnswers-0',
  },
  {
    label: 'I feel the treatment advised will help me address my concerns',
    value: 'agreeAnswers-1',
  },
  {
    label: `I found it easy and smooth to book the consultation`,
    value: 'agreeAnswers-2',
  },
  {
    label: 'How likely are you to recommend us to your friends and family? *',
    value: 'rating',
  },
  {
    label: 'Anything else you would like to share?',
    value: 'other',
  },
];

const getTitle = (str) => {
  let title = '';
  for (let i = 0; i < titleMap.length; i++) {
    if (titleMap[i].value === str) {
      title = titleMap[i].label;
    }
  }
  return title;
};

const getBody = (data) => {
  let body = '';
  if (typeof data === 'object') {
    const value = [];
    for (let j = 0; j < data.length; j++) {
      value.push(data[j].item);
    }
    body = value.join(', ');
  } else {
    for (let i = 0; i < radioData.length; i++) {
      if (radioData[i].value === data) {
        body = radioData[i].label;
      }
    }
  }
  return body;
};

export const parseTableDataFeedback = (inputData) => {
  let resultsArr = [];
  if (inputData) {
    const arrayItem = inputData;
    // inputData.forEach((arrayItem, arrayIndex) => {
    if (arrayItem.feedbackFormData?.type) {
      const keys = [];
      Object.keys(arrayItem.feedbackFormData).map((i) => {
        keys.push(i);
      });

      for (let j = 0; j < keys.length; j++) {
        if (keys[j] === 'agreeAnswers') {
          for (
            let k = 0;
            k < Object.keys(arrayItem.feedbackFormData[keys[j]]).length;
            k++
          ) {
            resultsArr.push({
              title: titleMap[k].label,
              feedback: agreeData.find(
                (val) => val.value === arrayItem.feedbackFormData[keys[j]][k],
              ).label,
            });
          }
        } else if (keys[j] === 'rating' || keys[j] === 'other') {
          resultsArr.push({
            title: titleMap.find((val) => val.value === keys[j]).label,
            feedback: arrayItem.feedbackFormData[keys[j]],
          });
        } else {
          resultsArr.push({
            title: getTitle(keys[j]),
            feedback: getBody(arrayItem.feedbackFormData[keys[j]]),
          });
        }
      }
    }
    // });
  }
  const returnArr = resultsArr.filter((i) => {
    return i.title != '';
  });
  return returnArr;
};
