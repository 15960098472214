import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import Table from '../../components/Table';
import EmptyState from '../../components/EmptyState';
import Spinner from '../../components/Spinner';
import './style.scss';
import { getAllPromoCodes } from '../../services/promoCodeServices';
import { parseCouponCodeTable } from './parser';
import { useToasts } from 'react-toast-notifications';

const userData = {
  userName: 'Admin',
};

const columnHeaders = [
  'S. No.',
  'Coupon Code',
  'Type',
  'Amount / Percentage',
  'Status',
  'Action',
];

const CouponCodes = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(2);
  const [search, setSearch] = useState('');
  const [sendSearchData, setSendSearchData] = useState('');

  const fetchPromoData = async (params) => {
    setLoading(true);

    getAllPromoCodes(params)
      .then((res) => {
        if (res?.code === 200) {
          const {
            data: { results = [], meta: { pagination = {} } = {} } = {},
          } = res || {};

          const { total_pages } = pagination;

          const parsedTableData = parseCouponCodeTable(
            results,
            res.data?.meta?.pagination
          );

          setCouponData(parsedTableData);

          setTotalPage(total_pages);
        }

        setLoading(false);
      })
      .catch((err) => {
        const { response: { data: { errors = {}, message } = {} } = {} } = err;

        if (errors && Object.keys(errors).length > 0) {
          for (let key in errors) {
            for (let e of errors[key]) {
              addToast(e, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          }
        } else {
          addToast(message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        setLoading(false);
      });
  };

  const changeSearchContent = (e) => {
    setSearch(e.target.value);
  };
  const searchKeyPress = (e) => {
    if (e.which === 13) {
      if (search !== '') {
        setSendSearchData(search);
      }
    }
  };

  const handleSearchOnClick = () => {
    setSendSearchData(search);
  };

  useEffect(() => {
    if (search === '') {
      setSendSearchData('');
    }
  }, [search]);

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchPromoData({ page: pageNumber, search: sendSearchData });
  };

  useEffect(() => {
    if (props && props.location?.state?.page_current) {
      setCurrentPage(props.location?.state?.page_current);
      const params = {
        page: props.location?.state?.page_current,
        search: sendSearchData
      };
      fetchPromoData(params);
      props.location.state.page_current = null;

    } else {
      const params = { page: currentPage, search: sendSearchData };
      fetchPromoData(params);
    }
  }, [sendSearchData]);

  const handleActionTrigger = (selectedItem) => {
    const couponId = selectedItem?.id;
    history.push(`/coupon-codes/edit/${couponId}`, {
      page_number: currentPage,
      item: selectedItem,
    });
  };

  return (
    <div className='coupon-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Coupon Codes' />

        <div className='coupon-wrapper-content'>
          <div className='coupon-wrapper-header'>
            <div className='search'>
              <input
                type='text'
                placeholder='Search'
                className='blog-search'
                onChange={changeSearchContent}
                value={search}
                onKeyPress={searchKeyPress}
              ></input>
              <button
                onClick={() => handleSearchOnClick()}
                className='search-btn'
              >
                Search
              </button>
            </div>
            <div
              className='create-cta'
              onClick={() => {
                history.push('/coupon-codes/create', {
                  page_number: currentPage,
                });
              }}
            >
              Add New Coupon
            </div>
          </div>

          {couponData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={couponData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchPromoData({ page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchPromoData({ page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title='No Coupon found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default CouponCodes;
