import moment from "moment";

export const parseTableData = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 20 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.name =
        arrayItem.firstName !== null && arrayItem.lastName !== null
          ? arrayItem.firstName !== ""
            ? arrayItem.firstName + " " + arrayItem.lastName
            : "N/A"
          : "N/A";
      renderingObject.email =
        arrayItem.email !== null ? arrayItem.email : "N/A";
      renderingObject.phoneno =
        arrayItem.mobile !== null
          ? "+" + arrayItem.countryCode + arrayItem.mobile
          : "N/A";
      renderingObject.status = arrayItem.status;
      renderingObject.isPremiumMember = arrayItem.isPremiumMember
        ? "YES"
        : "NO";
      renderingObject.created = moment(arrayItem.createdAt).format(
        "DD/MM/YYYY hh:mm A"
      );
      renderingObject.lastActive = arrayItem.lastActive
        ? moment(arrayItem.lastActive).format("DD/MM/YYYY hh:mm A")
        : "N/A";
      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
