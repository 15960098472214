import { parseTableData } from "./parser";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getAllWebinarsService } from "../../services/webinarsService";

import Table from "../../components/Table";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import SubHeader from "../../components/SubHeader";
import EmptyState from "../../components/EmptyState";

import "./styles.scss";

const WebinarsListing = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [webinarsData, setWebinarsData] = useState([]);

  const columnHeaders = [
    "S. No.",
    "Title",
    "Schedule",
    "Duration",
    "Featured",
    "Status",
    "AOC",
    "Company",
    "Action",
  ];

  const userData = {
    userName: "Admin",
  };

  const handleActionTrigger = (selectedItem) => {
    history.push(`/webinars/edit/${selectedItem.id}`,{page_number : currentPage});
  };

  const handleAddWebinarCta = () =>{
    history.push("/webinars/create",{page_number : currentPage})
  }

  const fetchWebinarData = (params) => {
    setLoading(true);

    getAllWebinarsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(
            response?.data?.results,
            response.data?.meta?.pagination
          );

          setWebinarsData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);

        addToast(err.response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchWebinarData({ page: pageNumber });
  };

  useEffect(() => {
    
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current};
      fetchWebinarData(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage};
      fetchWebinarData(params);
    }
  }, []); // eslint-disable-line

  return (
    <div className="authors-wrapper">
      <Header userData={userData} />

      <Sidebar />

      <div className="flexible-container">
        <SubHeader title="Webinars" />

        <div className="authors-wrapper-content">
          <div className="authors-wrapper-header d-flex align-items-center justify-content-end">
            <div className="create-cta" onClick={handleAddWebinarCta}>
              Add New Webinar
            </div>
          </div>

          {webinarsData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={webinarsData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchWebinarData({ page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchWebinarData({ page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title="No webinars found" />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default WebinarsListing;
