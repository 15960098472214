import Datetime from 'react-datetime';

import './styles.scss';

const DateTimeInput = (props) => {
  
  return (
    <div className='input-wrapper'>
      {props.label && (
        <label className='input-label' htmlFor={props.name}>
          {props.label}
          {props.required === 'yes' ? <span className='text-danger'>*</span> : ''}
        </label>
      )}

      <Datetime
        className={!props.disabled ? 'input-field input-datetime':'input-disabled input-field input-datetime'}
        // disabled={props.disabled}
        value={props.value}
        {...props}
        inputProps={{disabled:props.disabled}}
      />
    </div>
  );
};

export default DateTimeInput;
