import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getAllAuthorsService } from '../../services/authorsService';
import { useToasts } from 'react-toast-notifications';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SubHeader from '../../components/SubHeader';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const AuthorsListing = (props) => {
  // console.log(props, 'list')
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [authorsData, setAuthorsData] = useState([]);

  const columnHeaders = ['S. No.','Name', 'Created', 'Action'];

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selectedItem) => {
    // const state = {page_number : currentPage};
    // props.location.state = state;
    history.push(`/authors/edit/${selectedItem.id}`,{page_number : currentPage});
  };

  const handleAddAuthorCta = () =>{
    history.push('/authors/create',{page_number : currentPage})
  }

  const fetchAuthorsData = (params) => {
    
    setLoading(true);
    getAllAuthorsService(params)
      .then((response) => {
        setLoading(false);

        if (response.code === 200) {
          const parsedTableData = parseTableData(response?.data?.results, response.data?.meta?.pagination);

          setAuthorsData(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorData = err.response?.data?.errors
                  if (errorData && Object.keys(errorData).length > 0) {
                    for (let key in errorData) {
                    for (let e of errorData[key]) {
                      addToast(e, {
                          appearance: 'error',
                          autoDismiss: true,
                        });
                        setLoading(false);
                    }
                  }
        }else {
          setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        }
      });
  };
  const handlePageNumber=(pageNumber)=>{
    setCurrentPage(pageNumber);
    fetchAuthorsData({ role: 'author', page: pageNumber, sortBy: 'DATE'});
  }

  useEffect(()=>{
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current, role: 'author', sortBy: 'DATE' };
      fetchAuthorsData(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage, role: 'author', sortBy: 'DATE' };
      fetchAuthorsData(params);
    }
  },[]) // eslint-disable-line  

  return (
    <div className='authors-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Authors' />

        <div className='authors-wrapper-content'>
          <div className='authors-wrapper-header d-flex align-items-center justify-content-end'>
            <div  className='create-cta' onClick={handleAddAuthorCta}>
              Add New Author
            </div>
          </div>
          {authorsData.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              columnContent={authorsData}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                fetchAuthorsData({ role: 'author', page: currentPage + 1, sortBy: 'DATE' });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                fetchAuthorsData({ role: 'author', page: currentPage - 1, sortBy: 'DATE' });
              }}
            />
          ) : (
            <EmptyState title='No author found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default AuthorsListing;
