import moment from 'moment';

export const parseTableData = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination && pagination.current_page;

  if (inputData) {
    inputData.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 20 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.id = arrayItem.id;
      renderingObject.title = arrayItem.title || 'N/A';
      renderingObject.topic = arrayItem.topic?.name || 'N/A';
      renderingObject.author = arrayItem.author?.name || 'N/A';
      renderingObject.status = arrayItem.status || 'N/A';
      renderingObject.Aoc = arrayItem.aoc?.name || 'N/A';
      renderingObject.company = arrayItem.company?.name || 'N/A';
      renderingObject.created =
        moment(arrayItem.createdAt).format('DD/MM/YYYY hh:mm A') || 'N/A';

      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
