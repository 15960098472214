import moment from 'moment';

export const parseMessagesTable = (inputData, pagination) => {
  let resultsArr = [];
  const current_page = pagination?.current_page;

  if (inputData) {
    inputData?.forEach((arrayItem, arrayIndex) => {
      let renderingObject = {};
      let index = (current_page - 1) * 10 + (arrayIndex + 1);

      renderingObject.sno = index;
      renderingObject.name = arrayItem?.customerName;
      renderingObject.phone = arrayItem?.customerPhone;
      renderingObject.message = arrayItem?.message;
      renderingObject.date =
        moment(arrayItem.createdAt).format('DD/MM/YYYY hh:mm:ss') || 'N/A';
      resultsArr.push(renderingObject);
    });
  }

  return resultsArr;
};
