import { parseTableData } from './parser';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getAllSpecializationsService } from '../../services/specializationsService';

import Table from '../../components/Table';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import SubHeader from '../../components/SubHeader';
import Spinner from '../../components/Spinner';
import { useToasts } from 'react-toast-notifications';
import EmptyState from '../../components/EmptyState';

import './styles.scss';

const SpecializationListing = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [specializations, setSpecializations] = useState([]);

  const columnHeaders = ['S. No.','Name', 'Created', 'Action'];

  const userData = {
    userName: 'Admin',
  };

  const handleActionTrigger = (selectedItem) => {
    //parse the id of selected Item
    const specializationId = selectedItem.id;
    history.push(`/specializations/edit/${specializationId}`);
  };

  const handleAddSpecializationCta = () =>{
    history.push('/specializations/create',{page_number : currentPage})
  }

  const getAllSpecializations = (params) => {
    setLoading(true);
    getAllSpecializationsService(params)
      .then((response) => {
        setLoading(false);
        if (response.code === 200) {
          const parsedTableData = parseTableData(response?.data?.results,response.data?.meta?.pagination);

          setSpecializations(parsedTableData);

          setTotalPage(response?.data?.meta?.pagination?.total_pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast(err.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handlePageNumber = (pageNumber) =>{
    setCurrentPage(pageNumber);
    getAllSpecializations({ page: pageNumber});
  }

  useEffect(() => {
    const params = { page: 1 };
    getAllSpecializations(params);
    
    if(props && props.location?.state?.page_current){
      setCurrentPage(props.location?.state?.page_current);
      const params = { page: props.location?.state?.page_current};
      getAllSpecializations(params);
      props.location.state.page_current = null;
    }else{
      const params = { page: currentPage};
      getAllSpecializations(params);
    }
  }, []);// eslint-disable-line

  const updatedSpecializations = specializations.map((specialization) => {
    delete specialization.image;
    return specialization;
  });

  return (
    <div className='specialization-wrapper'>
      <Header userData={userData} />

      <Sidebar />

      <div className='flexible-container'>
        <SubHeader title='Specializations' />

        <div className='specialization-wrapper-content'>
          <div className='specialization-wrapper-header d-flex align-items-center justify-content-end'>
            <div className='create-cta' onClick={handleAddSpecializationCta}>
              Add New Specialization
            </div>
          </div>

          {updatedSpecializations.length ? (
            <Table
              pagination={true}
              actionAvailable={true}
              paginationPage={currentPage}
              columnHeaders={columnHeaders}
              paginationTotalPage={totalPage}
              columnContent={updatedSpecializations}
              handleAction={handleActionTrigger}
              handlePageNumber={handlePageNumber}
              nextPage={() => {
                setCurrentPage(currentPage + 1);
                getAllSpecializations({ page: currentPage + 1 });
              }}
              prevPage={() => {
                setCurrentPage(currentPage - 1);
                getAllSpecializations({ page: currentPage - 1 });
              }}
            />
          ) : (
            <EmptyState title='No specialization found' />
          )}
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default SpecializationListing;
